import React from "react";
import { styled } from "@mui/material/styles";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

const Tooltip280 = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		classes={{ popper: className, tooltip: "tooltipMain" }}
		arrow
	/>
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 280,
		background: "#222222",
		textAlign: "center",
	},
});

const TooltipO = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		classes={{ popper: className, tooltip: "tooltipMain" }}
		arrow
	/>
))({
	[`& .${tooltipClasses.tooltip}`]: {
		maxWidth: 440,
		background: "#222222",
		textAlign: "center",
	},
});

const Tooltip_ = styled(({ className, ...props }) => (
	<Tooltip
		{...props}
		classes={{ popper: className, tooltip: "tooltipMain" }}
		arrow
	/>
))({
	[`& .${tooltipClasses.tooltip}`]: {
		background: "#222222",
		textAlign: "center",
	},
});
export { Tooltip280, TooltipO, Tooltip_ };

import React, { useState, useEffect } from "react";
import mobil1 from "../img/mobil1.jpg";
import mobil2 from "../img/mobil2.jpg";
import { AnimateOnChange } from "react-animation";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import { Discord, GitBook, Crosshair } from "./Icons";
import { TooltipO } from "./Tooltip";
import DialogTOS from "./DialogTOS";
//import styles from "../LandingPage.css";

let img_ = 1;
const LandingPage = ({ setOpenDapp }) => {
	const [img, setImg] = useState(1);
	const [openTOS, setOpenTOS] = useState(false);

	useEffect(() => {
		const interval = setInterval(() => {
			if (img_ == 1) {
				img_ = 2;
				setImg(2);
			} else {
				img_ = 1;
				setImg(1);
			}
		}, 10000);
		return () => clearInterval(interval);
	}, []);

	const redirectLink = (link) => {
		window.open(link, "_blank");
	};
	const handleTerms = () => {
		setOpenTOS(true);
	};
	const openApp = () => {
		localStorage.setItem("appopen", true);
		setOpenDapp(true);
	};
	return (
		<div>
			<DialogTOS open={openTOS} setOpen={setOpenTOS} />
			<div className="body-wrap">
				<header className="site-header">
					<div className="container">
						<div className="site-header-inner"></div>
					</div>
				</header>

				<main>
					<section className="hero">
						<div className="container">
							<div className="hero-inner">
								<div className="hero-copy">
									<h1 className="hero-title mt-0">
										Welcome to ReadySwap
									</h1>
									<h4 className="hero-subtitle mt-0">
										“Built by traders for Traders”
									</h4>
									<p className="hero-paragraph mt-16">
										Snipe, trade manually, program on-chain
										Take Profit / Stop Loss and layered
										Market Orders with unlimited suborders.
									</p>
									<p className="hero-paragraph">
										Available for use at 0.5% fee charged
										per transaction.
									</p>
									<div className="hero-cta">
										<a
											className="button button-primary"
											onClick={() => openApp()}
										>
											ReadySwap Web
										</a>
										<a
											className="button button-primary"
											href="https://t.me/ReadySwap_bot"
											target="_blank"
										>
											ReadySwap Telegram
										</a>
										{/* <a className="button" href="#">
										Get in touch
    </a> */}
									</div>
								</div>
								<div className="hero-figure ">
									<div className="headerImg">
										{img == 1 ? (
											<AnimateOnChange durationOut="1000">
												<img
													className="mobileimg1"
													src={mobil1}
												/>
											</AnimateOnChange>
										) : (
											<AnimateOnChange durationOut="1000">
												<img
													className="mobileimg2"
													src={mobil2}
												/>
											</AnimateOnChange>
										)}
									</div>
								</div>
							</div>
						</div>
					</section>
					<section className="rssn section">
						<div className="container rssn-container">
							<div className="rs-con">
								<div className="rs-col left">
									<div className="rs-box ">
										<div className="feature-inner">
											<h4 className="hero-subtitle">
												Platform
											</h4>
											<p className="text-sm mb-0">
												<span>
													Trade on Web or on Telegram
												</span>
												- and all
												data/positions/pre-launched snip
												are fully synced between the
												two, allowing you transition
												from managing your trading from
												your big desktop screens, or
												mobile platform on the go.
											</p>
											<p className="text-sm mb-0">
												Allows you to operate unlimited
												wallets.
											</p>
											<p className="text-sm mb-0">
												Perfect for
												adding/removing/rotating
												wallets. Incorporates innovative
												AutoBuy platform - TUF
												SuperSignals,Metadrop launches,
												Volume Signals, or forward a
												message within Telegram to
												autobuy.
											</p>
										</div>
									</div>
									<div className="rs-box rs-bottom">
										<div className="feature-inner">
											<h4 className="hero-subtitle">
												Analyze
											</h4>
											<p className="text-sm mb-0">
												Power your trades with TUFExt,
												our in-house and AI powered
												blockchain analytics tool
												boasting diverse data points
												including the Extended
												Simulator, Wallet Analyser,
												Creator Contracts, Checksum,
												Origin of Funds and Mempool
												Scanner.
											</p>
											<p className="text-sm mb-0">
												$TUF token holdings grant tiered
												access to traders in the TUF
												Ecosystem
											</p>
										</div>
									</div>
								</div>

								<div className="rs-col right">
									<div className="rs-box ">
										<div className="feature-inner">
											<h4 className="hero-subtitle rs-box-title-r">
												Trade
											</h4>
											<p className="text-sm mb-0">
												Trade quicker with a near CEX
												experience - but on chain.
											</p>
											<p className="text-sm mb-0">
												Use ReadySwap Trading Terminal
												on the Web or via Telegram
												(completely synced inter-se).
											</p>
											<p className="text-sm mb-0">
												Buy Manually or Snipe with
												extreme customization in your
												trade settings, including by
												autotrading your favorite
												Signals channels – at a tiny
												0.5% per transaction fee!
											</p>
										</div>
									</div>
									<div className="rs-box rs-bottom">
										<div className="feature-inner">
											<h4 className="hero-subtitle rs-box-title-r">
												Profit
											</h4>
											<p className="text-sm mb-0">
												Set and Forget – our industry
												best on-chain limit orders suite
												allows you to configure detailed
												Take Profit/Stop Loss and Limit
												Orders that execute even when
												you’re away from ReadySwap and
												unable to monitor an
												ever-changing environment.
											</p>
											<p className="text-sm mb-0">
												Secure your profits and minimize
												losses automatically – never get
												caught off-guard by your
												favourite shitcoin, again.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>

					<section className="pricing section">
						<div className="container">
							<div className="pricing-inner section-inner">
								<div className="pricing-header text-center">
									<h2 className="section-title mt-0 hero-title">
										TUF Token
									</h2>
									<p className="section-paragraph mb-0 ca">
										Contract:
										0x6f8b23296394d20ec048fbdec8ebc0ca90f5c8f1
									</p>
									<a
										className="button"
										href="https://app.uniswap.org/swap?outputCurrency=0x6f8b23296394d20eC048FbDec8eBc0CA90f5c8f1"
										target="_blank"
									>
										Buy
									</a>
								</div>

								<div className="pricing-tables-wrap">
									<div className="pricing-table">
										<div className="pricing-table-inner is-revealing">
											<div className="pricing-table-main">
												<div className="pricing-table-header pb-24">
													<div className="pricing-table-price">
														<span className="pricing-table-price-currency h4 hero-subtitle">
															5% Tax
														</span>
													</div>
												</div>
												<ul className="pricing-table-features list-reset text-xs">
													<li>
														<span>
															ERC-20 token with a
															max supply of
															1,000,000,000
														</span>
													</li>
													<li>
														<span>
															Fees are at 5%
															(Buys/Sells/Transfers),
															which are
															distributed towards
															Project Development
															(4%) and Liquidity
															(1%).
														</span>
													</li>
													<li>
														<span>
															Holders are granted
															tiered access to the
															utility-web of
															TUFExt blockchain
															analytics suite.
														</span>
													</li>
													<li>
														<span>
															Holders are also
															stakeholders in the
															growth and adoption
															of Readyswap (25% of
															the trading revenue)
														</span>
													</li>
												</ul>
												<div
													className="pricing-table-features-title text-xs pt-24 pb-24"
													style={{ border: "none" }}
												>
													For more information please
													click below.
												</div>
											</div>
											<div className="pricing-table-cta mb-8">
												<a
													className="button button-primary button-shadow button-block"
													href="https://tufext.com/"
													target="_blank"
												>
													TUFExt
												</a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</section>
				</main>

				<footer className="site-footer">
					<div className="container">
						<div className="site-footer-inner">
							<div className="footerMenu">
								<a
									className="button"
									href="https://tufdocs.gitbook.io/readyswap-powered-by-tuf/overview/welcome-to-readyswap"
									target="_blank"
								>
									<GitBook color="#ffffff" width={35} />{" "}
									Manual
								</a>
								<a className="button" onClick={handleTerms}>
									Terms & Conditions
								</a>
							</div>

							<div className="footer-social-links list-reset">
								<TooltipO title={<span>Telegram</span>}>
									<TelegramIcon
										style={{
											width: 40,
											color: "#ffffff",
											cursor: "pointer",
										}}
										onClick={() =>
											redirectLink(
												"https://t.me/Readyswappers"
											)
										}
									/>
								</TooltipO>

								<TooltipO title={<span>Twitter</span>}>
									<TwitterIcon
										style={{
											width: 40,
											marginRight: "5px",
											color: "#ffffff",
											cursor: "pointer",
											marginLeft: "5px",
										}}
										onClick={() =>
											redirectLink(
												"https://twitter.com/thetufteam"
											)
										}
									/>
								</TooltipO>
								<TooltipO title={<span>Youtube</span>}>
									<YouTubeIcon
										style={{
											width: 40,
											color: "#ffffff",
											cursor: "pointer",
											marginLeft: "5px",
											marginRight: "-5px",
										}}
										onClick={() =>
											redirectLink(
												"https://www.youtube.com/channel/UClRGS8QZgevMvd_WmWQd25w/videos"
											)
										}
									/>
								</TooltipO>
							</div>
							<div className="footer-copyright">
								&copy; 2023 ReadySwap, all rights reserved
							</div>
						</div>
					</div>
				</footer>
			</div>
		</div>
	);
};

export default LandingPage;

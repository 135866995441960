const axios = require("axios");
const https = require("https");
//import https from "https";
const CryptoJS = require("crypto-js");
const SERVER = "https://app.readyswap.app:40888";
const axiosI = axios.create({
	baseURL: SERVER,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
	credentials: "include",
	mode: "cors",
	httpsAgent: new https.Agent({
		rejectUnauthorized: false,
	}),
	withCredentials: true,
});

// Request interceptor for API calls
axiosI.interceptors.request.use(
	async (config) => {
		if (!!!sessionStorage.getItem("token")) return config;
		const token = sessionStorage.getItem("token");

		config.headers = {
			Authorization: `Bearer ${token}`,
			Accept: "application/json",
			"x-auth": sessionStorage.getItem("atoken"),
		};
		return config;
	},
	(error) => {
		Promise.reject(error);
	}
);

// Response interceptor for API calls
/* axiosI.interceptors.response.use(
	(response) => {
		try {
			const bytes = CryptoJS.Rabbit.decrypt(
				response.data.data,
				sessionStorage.getItem("token")
			);
			const decryptedData = isJsonString(
				bytes.toString(CryptoJS.enc.Utf8)
			);

			response.data.data = decryptedData;
			return response;
		} catch (error) {
			return response;
		}
	},
	async function (error) {
		if (error.response.data.error.code === 5) {
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("atoken");
			window.location = "/";
			//window.location.reload(true);
		}
		if (error.response.data.error.code === 9) {
			sessionStorage.removeItem("token");
			sessionStorage.removeItem("atoken");
			//window.location = "/";
			//window.location.reload(true);
		}

		return Promise.reject(error);
	}
); */
const isJsonString = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};
//module.exports = axiosI;
export default axiosI;

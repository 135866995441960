import React from "react";

const EtherScan = (props) => {
	return (
		<span style={{ cursor: "pointer" }}>
			<svg
				width="1.25rem"
				height="1.25rem"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 293.775 293.667"
				{...props}
			>
				<path
					className="ethicon_p2"
					d="M61.055 139.819a12.45 12.45 0 0 1 12.508-12.452l20.737.068a12.467 12.467 0 0 1 12.467 12.467v78.414c2.336-.692 5.332-1.43 8.614-2.2a10.389 10.389 0 0 0 8.009-10.11V108.74a12.469 12.469 0 0 1 12.467-12.47h20.779a12.47 12.47 0 0 1 12.467 12.47v90.276s5.2-2.106 10.269-4.245a10.408 10.408 0 0 0 6.353-9.577V77.567A12.466 12.466 0 0 1 198.19 65.1h20.779a12.468 12.468 0 0 1 12.468 12.467v88.625c18.014-13.055 36.271-28.758 50.759-47.639a20.926 20.926 0 0 0 3.185-19.537A146.6 146.6 0 0 0 148.737.01C67.298-1.084-.007 65.395.001 146.844a146.371 146.371 0 0 0 19.5 73.45 18.56 18.56 0 0 0 17.707 9.173 508.86 508.86 0 0 0 14.643-1.518 10.383 10.383 0 0 0 9.209-10.306v-77.824"
				></path>
				<path
					className="ethicon_p1"
					d="M60.603 265.577a146.808 146.808 0 0 0 233.172-118.741c0-3.381-.157-6.724-.383-10.049-53.642 80-152.686 117.405-232.79 128.793"
				></path>
			</svg>
		</span>
	);
};
const BscScan = (props) => {
	return (
		<span style={{ cursor: "pointer" }}>
			<svg
				width="1.25rem"
				height="1.25rem"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 293.775 293.667"
				{...props}
			>
				<path
					className="bscicon_p2"
					d="M61.055 139.819a12.45 12.45 0 0 1 12.508-12.452l20.737.068a12.467 12.467 0 0 1 12.467 12.467v78.414c2.336-.692 5.332-1.43 8.614-2.2a10.389 10.389 0 0 0 8.009-10.11V108.74a12.469 12.469 0 0 1 12.467-12.47h20.779a12.47 12.47 0 0 1 12.467 12.47v90.276s5.2-2.106 10.269-4.245a10.408 10.408 0 0 0 6.353-9.577V77.567A12.466 12.466 0 0 1 198.19 65.1h20.779a12.468 12.468 0 0 1 12.468 12.467v88.625c18.014-13.055 36.271-28.758 50.759-47.639a20.926 20.926 0 0 0 3.185-19.537A146.6 146.6 0 0 0 148.737.01C67.298-1.084-.007 65.395.001 146.844a146.371 146.371 0 0 0 19.5 73.45 18.56 18.56 0 0 0 17.707 9.173 508.86 508.86 0 0 0 14.643-1.518 10.383 10.383 0 0 0 9.209-10.306v-77.824"
				></path>
				<path
					className="bscicon_p1"
					d="M60.603 265.577a146.808 146.808 0 0 0 233.172-118.741c0-3.381-.157-6.724-.383-10.049-53.642 80-152.686 117.405-232.79 128.793"
				></path>
			</svg>
		</span>
	);
};
const DexScreener = ({ width, color }) => {
	return (
		<span style={{ cursor: "pointer" }}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="100%"
				height="100%"
				fill="#fff"
				viewBox="0 0 252 300"
				focusable="false"
				style={{ color, width }}
			>
				<path d="M151.818 106.866c9.177-4.576 20.854-11.312 32.545-20.541 2.465 5.119 2.735 9.586 1.465 13.193-.9 2.542-2.596 4.753-4.826 6.512-2.415 1.901-5.431 3.285-8.765 4.033-6.326 1.425-13.712.593-20.419-3.197m1.591 46.886l12.148 7.017c-24.804 13.902-31.547 39.716-39.557 64.859-8.009-25.143-14.753-50.957-39.556-64.859l12.148-7.017a5.95 5.95 0 003.84-5.845c-1.113-23.547 5.245-33.96 13.821-40.498 3.076-2.342 6.434-3.518 9.747-3.518s6.671 1.176 9.748 3.518c8.576 6.538 14.934 16.951 13.821 40.498a5.95 5.95 0 003.84 5.845zM126 0c14.042.377 28.119 3.103 40.336 8.406 8.46 3.677 16.354 8.534 23.502 14.342 3.228 2.622 5.886 5.155 8.814 8.071 7.897.273 19.438-8.5 24.796-16.709-9.221 30.23-51.299 65.929-80.43 79.589-.012-.005-.02-.012-.029-.018-5.228-3.992-11.108-5.988-16.989-5.988s-11.76 1.996-16.988 5.988c-.009.005-.017.014-.029.018-29.132-13.66-71.209-49.359-80.43-79.589 5.357 8.209 16.898 16.982 24.795 16.709 2.929-2.915 5.587-5.449 8.814-8.071C69.31 16.94 77.204 12.083 85.664 8.406 97.882 3.103 111.959.377 126 0m-25.818 106.866c-9.176-4.576-20.854-11.312-32.544-20.541-2.465 5.119-2.735 9.586-1.466 13.193.901 2.542 2.597 4.753 4.826 6.512 2.416 1.901 5.432 3.285 8.766 4.033 6.326 1.425 13.711.593 20.418-3.197"></path>
				<path d="M197.167 75.016c6.436-6.495 12.107-13.684 16.667-20.099l2.316 4.359c7.456 14.917 11.33 29.774 11.33 46.494l-.016 26.532.14 13.754c.54 33.766 7.846 67.929 24.396 99.193l-34.627-27.922-24.501 39.759-25.74-24.231L126 299.604l-41.132-66.748-25.739 24.231-24.501-39.759L0 245.25c16.55-31.264 23.856-65.427 24.397-99.193l.14-13.754-.016-26.532c0-16.721 3.873-31.578 11.331-46.494l2.315-4.359c4.56 6.415 10.23 13.603 16.667 20.099l-2.01 4.175c-3.905 8.109-5.198 17.176-2.156 25.799 1.961 5.554 5.54 10.317 10.154 13.953 4.48 3.531 9.782 5.911 15.333 7.161 3.616.814 7.3 1.149 10.96 1.035-.854 4.841-1.227 9.862-1.251 14.978L53.2 160.984l25.206 14.129a41.926 41.926 0 015.734 3.869c20.781 18.658 33.275 73.855 41.861 100.816 8.587-26.961 21.08-82.158 41.862-100.816a41.865 41.865 0 015.734-3.869l25.206-14.129-32.665-18.866c-.024-5.116-.397-10.137-1.251-14.978 3.66.114 7.344-.221 10.96-1.035 5.551-1.25 10.854-3.63 15.333-7.161 4.613-3.636 8.193-8.399 10.153-13.953 3.043-8.623 1.749-17.689-2.155-25.799l-2.01-4.175z"></path>
			</svg>
		</span>
	);
};

const Discord = ({ width, color }) => {
	return (
		<span style={{ cursor: "pointer" }}>
			<svg
				style={{ color, width }}
				viewBox="0 -28.5 256 256"
				xmlns="http://www.w3.org/2000/svg"
			>
				<g>
					<path
						d="M216.856339,16.5966031 C200.285002,8.84328665 182.566144,3.2084988 164.041564,0 C161.766523,4.11318106 159.108624,9.64549908 157.276099,14.0464379 C137.583995,11.0849896 118.072967,11.0849896 98.7430163,14.0464379 C96.9108417,9.64549908 94.1925838,4.11318106 91.8971895,0 C73.3526068,3.2084988 55.6133949,8.86399117 39.0420583,16.6376612 C5.61752293,67.146514 -3.4433191,116.400813 1.08711069,164.955721 C23.2560196,181.510915 44.7403634,191.567697 65.8621325,198.148576 C71.0772151,190.971126 75.7283628,183.341335 79.7352139,175.300261 C72.104019,172.400575 64.7949724,168.822202 57.8887866,164.667963 C59.7209612,163.310589 61.5131304,161.891452 63.2445898,160.431257 C105.36741,180.133187 151.134928,180.133187 192.754523,160.431257 C194.506336,161.891452 196.298154,163.310589 198.110326,164.667963 C191.183787,168.842556 183.854737,172.420929 176.223542,175.320965 C180.230393,183.341335 184.861538,190.991831 190.096624,198.16893 C211.238746,191.588051 232.743023,181.531619 254.911949,164.955721 C260.227747,108.668201 245.831087,59.8662432 216.856339,16.5966031 Z M85.4738752,135.09489 C72.8290281,135.09489 62.4592217,123.290155 62.4592217,108.914901 C62.4592217,94.5396472 72.607595,82.7145587 85.4738752,82.7145587 C98.3405064,82.7145587 108.709962,94.5189427 108.488529,108.914901 C108.508531,123.290155 98.3405064,135.09489 85.4738752,135.09489 Z M170.525237,135.09489 C157.88039,135.09489 147.510584,123.290155 147.510584,108.914901 C147.510584,94.5396472 157.658606,82.7145587 170.525237,82.7145587 C183.391518,82.7145587 193.761324,94.5189427 193.539891,108.914901 C193.539891,123.290155 183.391518,135.09489 170.525237,135.09489 Z"
						fill={color}
					></path>
				</g>
			</svg>
		</span>
	);
};
const Lock = ({ width, color }) => {
	return (
		<svg
			version="1.1"
			id="Layer_1"
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			style={{ color, width }}
			viewBox="0 0 96.108 122.88"
		>
			<g>
				<path
					fill={color}
					fillRule="evenodd"
					d="M2.892,56.036h8.959v-1.075V37.117c0-10.205,4.177-19.484,10.898-26.207v-0.009 C29.473,4.177,38.754,0,48.966,0C59.17,0,68.449,4.177,75.173,10.901l0.01,0.009c6.721,6.723,10.898,16.002,10.898,26.207v17.844 v1.075h7.136c1.59,0,2.892,1.302,2.892,2.891v61.062c0,1.589-1.302,2.891-2.892,2.891H2.892c-1.59,0-2.892-1.302-2.892-2.891 V58.927C0,57.338,1.302,56.036,2.892,56.036L2.892,56.036z M26.271,56.036h45.387v-1.075V36.911c0-6.24-2.554-11.917-6.662-16.03 l-0.005,0.004c-4.111-4.114-9.787-6.669-16.025-6.669c-6.241,0-11.917,2.554-16.033,6.665c-4.109,4.113-6.662,9.79-6.662,16.03 v18.051V56.036L26.271,56.036z M49.149,89.448l4.581,21.139l-12.557,0.053l3.685-21.423c-3.431-1.1-5.918-4.315-5.918-8.111 c0-4.701,3.81-8.511,8.513-8.511c4.698,0,8.511,3.81,8.511,8.511C55.964,85.226,53.036,88.663,49.149,89.448L49.149,89.448z"
				/>
			</g>
		</svg>
	);
};
const Crosshair = ({ width, color }) => {
	return (
		<span style={{ cursor: "pointer" }}>
			<svg
				version="1.1"
				xmlns="http://www.w3.org/2000/svg"
				x="0px"
				y="0px"
				viewBox="0 0 27.147 27.147"
				style={{ color, width }}
			>
				<g>
					<path
						fill="currentColor"
						d="M26.492,12.033h-1.646c-0.689-5.053-4.695-9.053-9.75-9.732l-0.004-1.648C15.091,0.293,14.896,0,14.652,0L12.49,0.002
		   c-0.242,0-0.439,0.293-0.439,0.656l0.002,1.643c-5.051,0.68-9.058,4.682-9.748,9.732h-1.65C0.293,12.033,0,12.229,0,12.473v2.162
		   c0,0.242,0.293,0.439,0.655,0.439h1.644c0.674,5.07,4.688,9.09,9.753,9.771l0.004,1.646c0,0.363,0.195,0.656,0.439,0.656
		   l2.16-0.004c0.244,0,0.439-0.293,0.439-0.654v-1.645c5.066-0.68,9.084-4.701,9.756-9.771h1.641c0.363,0,0.656-0.197,0.656-0.439
		   v-2.162C27.148,12.229,26.855,12.033,26.492,12.033z M18.115,15.074h2.629c-0.289,1.391-0.973,2.654-1.992,3.676
		   c-1.02,1.02-2.283,1.701-3.678,1.99v-2.629c0-0.201-0.164-0.363-0.363-0.363h-2.273c-0.201,0-0.363,0.162-0.363,0.363v2.629
		   c-1.393-0.289-2.658-0.971-3.676-1.99c-1.019-1.021-1.701-2.285-1.989-3.676h2.63c0.199,0,0.363-0.164,0.363-0.365v-2.271
		   c0-0.201-0.164-0.365-0.363-0.365H6.41c0.288-1.389,0.971-2.654,1.989-3.674c1.02-1.021,2.284-1.705,3.676-1.992v2.631
		   c0,0.199,0.162,0.361,0.363,0.361h2.273c0.199,0,0.363-0.162,0.363-0.361V6.406c1.393,0.287,2.658,0.971,3.678,1.992
		   c1.02,1.02,1.703,2.285,1.992,3.674h-2.629c-0.201,0-0.363,0.164-0.363,0.365v2.271C17.751,14.91,17.914,15.074,18.115,15.074z"
					/>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
					<g></g>
				</g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
				<g></g>
			</svg>
		</span>
	);
};

const AddLP = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 129 107"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinejoin: "round",
				strokeMiterlimit: "2",
				color,
				width,
			}}
		>
			<g>
				<g transform="matrix(0.971247,0,0,1,-323.402,-3.63798e-12)">
					<text
						x="327.072px"
						y="106.688px"
						style={{
							fontFamily: "'Poppins-SemiBold', 'Poppins'",
							fontWeight: "600",
							fontSize: "85.565px",
							fill: color,
						}}
					>
						L
					</text>
				</g>
				<g transform="matrix(-0.968924,0,0,1,24238.6,0)">
					<text
						x="24877.3px"
						y="59.744px"
						style={{
							fontFamily: "'Poppins-SemiBold', 'Poppins'",
							fontWeight: "600",
							fontSize: "85.593px",
							fill: color,
						}}
					>
						P
					</text>
				</g>
				<text
					x="10.967px"
					y="114.033px"
					style={{
						fontFamily: "'Poppins-Light', 'Poppins'",
						fontWeight: "300",
						fontSize: "164.216px",
						fill: color,
					}}
				>
					+
				</text>
			</g>
		</svg>
	);
};
const RemoveLP = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 125 104"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinejoin: "round",
				strokeMiterlimit: "2",
				width,
			}}
		>
			<g>
				<text
					x="-5.734px"
					y="103.332px"
					style={{
						fontFamily: "'Poppins-SemiBold', 'Poppins'",
						fontWeight: "600",
						fontSize: "83.105px",
						fill: color,
					}}
				>
					L
				</text>
				<g transform="matrix(-1,0,0,1,24613.6,0)">
					<text
						x="24483px"
						y="57.887px"
						style={{
							fontFamily: "'Poppins-SemiBold', 'Poppins'",
							fontWeight: "600",
							fontSize: "82.933px",
							fill: color,
						}}
					>
						P
					</text>
				</g>
				<g transform="matrix(0.773537,0,0,1,-5364.94,0)">
					<text
						x="6949.39px"
						y="129.05px"
						style={{
							fontFamily: "'Poppins-Light', 'Poppins'",
							fontWeight: "300",
							fontSize: "208.688px",
							fill: color,
						}}
					>
						–
					</text>
				</g>
			</g>
		</svg>
	);
};
const Key = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 107 107"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinejoin: "round",
				strokeMiterlimit: "2",
				color,
				width,
			}}
		>
			<path
				d="M12.054,80.717c-2.999,2.995 -2.999,7.871 0,10.868c2.997,2.998 7.872,2.998 10.849,-0l2.166,-2.146l9.033,9.031c1.545,1.548 4.021,1.548 5.568,0l13.462,-13.462c1.547,-1.544 1.547,-4.021 -0,-5.568l-9.032,-9.031l16.09,-16.109c3.443,1.778 7.367,2.783 11.507,2.783c13.886,-0 25.141,-11.254 25.141,-25.141c0,-13.886 -11.255,-25.141 -25.141,-25.141c-13.887,-0 -25.141,11.255 -25.141,25.141c-0,4.14 1.005,8.064 2.783,11.507l-37.285,37.268Zm49.973,-48.775c0,-5.338 4.333,-9.67 9.67,-9.67c5.337,-0 9.669,4.332 9.669,9.67c0,5.337 -4.332,9.669 -9.669,9.669c-5.337,0 -9.67,-4.332 -9.67,-9.669Z"
				style={{
					fill: color,
				}}
			/>
		</svg>
	);
};
const Live = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 100 73"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinejoin: "round",
				strokeMiterlimit: "2",
				color,
				width,
			}}
		>
			<path
				d="M19.948,1.098c1.464,1.465 1.464,3.839 -0,5.304c-16.597,16.597 -16.597,43.507 -0,60.104c1.464,1.464 1.464,3.839 -0,5.303c-1.465,1.465 -3.839,1.465 -5.303,0c-19.527,-19.526 -19.527,-51.184 -0,-70.711c1.464,-1.464 3.838,-1.464 5.303,0Zm65.408,0c19.526,19.527 19.526,51.185 -0,70.711c-1.465,1.465 -3.839,1.465 -5.304,0c-1.464,-1.464 -1.464,-3.839 0,-5.303c16.598,-16.597 16.598,-43.507 0,-60.104c-1.464,-1.465 -1.464,-3.839 0,-5.304c1.465,-1.464 3.839,-1.464 5.304,0Zm-51.266,14.142c1.465,1.465 1.465,3.839 0,5.304c-8.787,8.787 -8.787,23.033 0,31.82c1.465,1.464 1.465,3.839 0,5.303c-1.464,1.465 -3.839,1.465 -5.303,0c-11.716,-11.716 -11.716,-30.711 -0,-42.427c1.464,-1.464 3.839,-1.464 5.303,0Zm37.123,0c11.716,11.716 11.716,30.711 0,42.427c-1.464,1.465 -3.838,1.465 -5.303,0c-1.464,-1.464 -1.464,-3.839 0,-5.303c8.787,-8.787 8.787,-23.033 0,-31.82c-1.464,-1.465 -1.464,-3.839 0,-5.304c1.465,-1.464 3.839,-1.464 5.303,0Zm-21.213,13.714c4.142,-0 7.5,3.358 7.5,7.5c0,4.142 -3.358,7.5 -7.5,7.5c-4.142,-0 -7.5,-3.358 -7.5,-7.5c0,-4.142 3.358,-7.5 7.5,-7.5Z"
				style={{
					fill: color,
					fillRule: "nonzero",
				}}
			/>
		</svg>
	);
};
const LockIcon = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 104 104"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinejoin: "round",
				strokeMiterlimit: "2",
				color,
				width,
			}}
		>
			<path
				d="M20.617,41.233l-0,-10.308c-0,-16.965 13.96,-30.925 30.925,-30.925c16.965,-0 30.925,13.96 30.925,30.925l-0,10.308l5.154,0c5.655,0 10.308,4.654 10.308,10.309l0,41.233c0,5.655 -4.653,10.309 -10.308,10.309l-72.158,-0c-5.655,-0 -10.309,-4.654 -10.309,-10.309l0,-41.233c0,-5.67 4.639,-10.309 10.309,-10.309l5.154,0Zm25.771,34.688l-0,11.7l10.308,0l0,-11.7c3.185,-1.839 5.154,-5.249 5.154,-8.927c0,-5.655 -4.653,-10.309 -10.308,-10.309c-5.655,0 -10.309,4.654 -10.309,10.309c0,3.678 1.969,7.088 5.155,8.927Zm-10.309,-44.996l0,10.308l30.925,0l0,-10.308c0,-8.482 -6.98,-15.462 -15.462,-15.462c-8.483,-0 -15.463,6.98 -15.463,15.462Z"
				style={{
					fill: color,
					fillRule: "nonzero",
				}}
			/>
		</svg>
	);
};
const Mint = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			viewBox="0 0 140 102"
			version="1.1"
			xmlns="http://www.w3.org/2000/svg"
			style={{
				fillRule: "evenodd",
				clipRule: "evenodd",
				strokeLinejoin: "round",
				strokeMiterlimit: "2",
				width,
			}}
		>
			<g>
				<path
					d="M69.855,-0l69.854,101.945l-139.709,-0l69.855,-101.945Z"
					style={{ fill: "#ffc300" }}
				/>
				<text
					x="54.401px"
					y="93.018px"
					style={{
						fontFamily: "'Arial-BoldMT', 'Arial', sans-serif",
						fontWeight: "700",
						fontSize: "94.192px",
					}}
				>
					!
				</text>
			</g>
		</svg>
	);
};
const Web = ({ color, width }) => {
	return (
		<svg
			width="100%"
			height="100%"
			version="1.1"
			id="_x32_"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 512 512"
			style={{ fill: color, width }}
		>
			<g>
				<path
					d="M511.878,247.973l-21.488,0.672l21.488-0.68c-2.168-69.219-31.732-131.348-77.876-175.949
		C387.882,27.377,324.93-0.004,256.13,0.004c-2.718,0-5.443,0.046-8.176,0.13h0.016C178.743,2.294,116.622,31.858,72.013,78.002
		C27.382,124.122,0,187.074,0,255.874c0,2.709,0.046,5.435,0.13,8.16c2.168,69.22,31.732,131.347,77.875,175.949
		c46.113,44.632,109.065,72.02,177.865,72.013c2.694,0,5.42-0.038,8.16-0.13c69.227-2.16,131.355-31.724,175.957-77.876
		c44.64-46.121,72.021-109.065,72.013-177.857C512,253.416,511.962,250.699,511.878,247.973z M102.921,107.894
		c19.64-20.29,43.274-36.647,69.67-47.891c-10.961,15.77-20.336,34.426-28.045,55.304H96.089
		C98.303,112.779,100.585,110.306,102.921,107.894z M75.692,142.664h60.112c-8.282,30.212-13.327,63.906-14.365,99.661H43.472
		C45.77,205.914,57.243,171.938,75.692,142.664z M75.647,369.344c-18.351-29.121-29.808-63.044-32.144-99.661h78.028
		c1.046,35.732,5.954,69.471,14.236,99.661H75.647z M107.898,409.083c-4.091-3.962-7.984-8.114-11.748-12.381h48.442
		c4.099,11.091,8.579,21.67,13.594,31.396c4.405,8.518,9.16,16.487,14.252,23.847C148.37,441.654,126.523,427.082,107.898,409.083z
		 M242.329,468.524c-5.931-0.374-11.809-0.946-17.603-1.801c-2.404-1.573-4.809-3.282-7.213-5.236
		c-16.381-13.313-31.778-35.831-43.694-64.784h68.51V468.524z M242.329,369.344h-78.15c-8.794-29.411-14.267-63.365-15.381-99.661
		h93.531V369.344z M242.329,242.325h-93.448c1.115-36.327,6.618-70.235,15.42-99.661h78.028V242.325z M242.329,115.306h-68.433
		c2.71-6.588,5.542-12.954,8.595-18.84c10.298-19.969,22.404-35.647,35.022-45.945c2.366-1.924,4.732-3.604,7.099-5.16
		c5.832-0.862,11.74-1.48,17.717-1.863V115.306z M436.369,142.664c18.351,29.128,29.8,63.044,32.136,99.661h-78.02
		c-1.046-35.732-5.954-69.471-14.236-99.661H436.369z M404.11,102.917c4.092,3.961,7.985,8.114,11.756,12.389h-48.441
		c-4.099-11.092-8.58-21.672-13.596-31.396c-4.412-8.527-9.167-16.496-14.267-23.847C363.63,70.353,385.484,84.918,404.11,102.917z
		 M269.687,43.476c5.924,0.374,11.801,0.954,17.587,1.802c2.412,1.58,4.824,3.282,7.229,5.244
		c16.381,13.32,31.778,35.823,43.686,64.784h-68.502V43.476z M269.687,142.664h78.15c8.794,29.411,14.267,63.364,15.381,99.661
		h-93.531V142.664z M269.687,269.683h93.448c-1.114,36.327-6.618,70.242-15.42,99.661h-78.028V269.683z M329.525,415.534
		c-10.297,19.976-22.412,35.655-35.022,45.953c-2.359,1.924-4.725,3.595-7.091,5.152c-5.832,0.863-11.74,1.481-17.725,1.862v-71.799
		h68.426C335.402,403.289,332.57,409.648,329.525,415.534z M409.087,404.106c-19.64,20.29-43.266,36.64-69.655,47.884
		c10.962-15.771,20.328-34.419,28.037-55.288h48.449C413.698,399.22,411.423,401.694,409.087,404.106z M436.308,369.344h-60.105
		c8.29-30.206,13.336-63.906,14.374-99.661h77.952C466.23,306.086,454.757,340.07,436.308,369.344z"
				/>
			</g>
		</svg>
	);
};

const GitBook = ({ color, width }) => {
	return (
		<svg
			style={{ fill: color, width }}
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M10.802 17.77a.703.703 0 1 1-.002 1.406.703.703 0 0 1 .002-1.406m11.024-4.347a.703.703 0 1 1 .001-1.406.703.703 0 0 1-.001 1.406m0-2.876a2.176 2.176 0 0 0-2.174 2.174c0 .233.039.465.115.691l-7.181 3.823a2.165 2.165 0 0 0-1.784-.937c-.829 0-1.584.475-1.95 1.216l-6.451-3.402c-.682-.358-1.192-1.48-1.138-2.502.028-.533.212-.947.493-1.107.178-.1.392-.092.62.027l.042.023c1.71.9 7.304 3.847 7.54 3.956.363.169.565.237 1.185-.057l11.564-6.014c.17-.064.368-.227.368-.474 0-.342-.354-.477-.355-.477-.658-.315-1.669-.788-2.655-1.25-2.108-.987-4.497-2.105-5.546-2.655-.906-.474-1.635-.074-1.765.006l-.252.125C7.78 6.048 1.46 9.178 1.1 9.397.457 9.789.058 10.57.006 11.539c-.08 1.537.703 3.14 1.824 3.727l6.822 3.518a2.175 2.175 0 0 0 2.15 1.862 2.177 2.177 0 0 0 2.173-2.14l7.514-4.073c.38.298.853.461 1.337.461A2.176 2.176 0 0 0 24 12.72a2.176 2.176 0 0 0-2.174-2.174" />
		</svg>
	);
};
const Crosshair2 = ({ color, width }) => {
	return (
		<svg
			style={{ fill: color, width }}
			xmlns="http://www.w3.org/2000/svg"
			width="100%"
			height="100%"
			viewBox="0 0 24 24"
		>
			<g>
				<g>
					<g>
						<path
							d="M32.833,56.454c-13.509,0-24.5-10.99-24.5-24.5c0-13.51,10.991-24.5,24.5-24.5c13.51,0,24.501,10.99,24.501,24.5
				C57.334,45.463,46.343,56.454,32.833,56.454z M32.833,11.453c-11.304,0-20.5,9.196-20.5,20.5c0,11.303,9.196,20.5,20.5,20.5
				c11.306,0,20.501-9.197,20.501-20.5C53.334,20.649,44.139,11.453,32.833,11.453z"
						/>
					</g>
					<g>
						<g>
							<path
								d="M21.066,21.346c-0.269,0-0.536-0.107-0.733-0.319c-0.376-0.405-0.352-1.038,0.053-1.414
					c1.991-1.848,4.423-3.198,7.032-3.906c0.534-0.148,1.082,0.171,1.227,0.703c0.145,0.533-0.17,1.082-0.703,1.227
					c-2.298,0.624-4.44,1.814-6.195,3.443C21.554,21.258,21.31,21.346,21.066,21.346z"
							/>
						</g>
						<g>
							<path
								d="M16.912,27.646c-0.111,0-0.225-0.019-0.335-0.058c-0.521-0.186-0.793-0.757-0.607-1.277
					c0.284-0.8,0.633-1.585,1.036-2.333c0.262-0.485,0.869-0.67,1.354-0.405c0.486,0.262,0.668,0.868,0.406,1.354
					c-0.355,0.658-0.662,1.35-0.912,2.054C17.709,27.391,17.323,27.646,16.912,27.646z"
							/>
						</g>
					</g>
					<g>
						<path d="M33.381,18c-1.104,0-2-0.896-2-2V2c0-1.104,0.896-2,2-2c1.104,0,2,0.896,2,2v14C35.381,17.104,34.484,18,33.381,18z" />
					</g>
					<g>
						<path d="M33.381,65c-1.104,0-2-0.896-2-2V49c0-1.105,0.896-2,2-2c1.104,0,2,0.895,2,2v14C35.381,64.104,34.484,65,33.381,65z" />
					</g>
					<g>
						<path d="M63,35H49c-1.104,0-2-0.896-2-2c0-1.104,0.896-2,2-2h14c1.104,0,2,0.896,2,2C65,34.104,64.104,35,63,35z" />
					</g>
					<g>
						<path d="M16,35H2c-1.104,0-2-0.896-2-2c0-1.104,0.896-2,2-2h14c1.104,0,2,0.896,2,2C18,34.104,17.104,35,16,35z" />
					</g>
				</g>
			</g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
			<g></g>
		</svg>
	);
};

export {
	EtherScan,
	BscScan,
	Crosshair,
	DexScreener,
	Discord,
	Lock,
	AddLP,
	Key,
	Live,
	LockIcon,
	Mint,
	RemoveLP,
	Web,
	GitBook,
	Crosshair2,
};

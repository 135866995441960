import React, { useState } from "react";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { Tooltip_ } from "./Tooltip";
import { styled } from "@mui/material/styles";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Paper from "@mui/material/Paper";
import Switch from "@mui/material/Switch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogBuyPanel from "./DialogBuyPanel";
import InfoIcon from "@mui/icons-material/Info";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RingLoader from "react-spinners/RingLoader";
import LoopIcon from "@mui/icons-material/Loop";
import { EtherScan, BscScan, DexScreener } from "./Icons";
import Autocomplete from "@mui/material/Autocomplete";
import Checkbox from "@mui/material/Checkbox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import TornadoIcon from "@mui/icons-material/Tornado";
import { callLink, callNewSite } from "../utils/functions";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});

const _pairs = [
	{
		value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		name: "WETH",
	},
	{
		value: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
		name: "DAI",
	},
	{
		value: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
		name: "USDT",
	},
	{
		value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		name: "USDC",
	},
];

const UniswapV2 = "0x7a250d5630b4cf539739df2c5dacb4c659f2488d";
const ReadySwapRouter = "0x2C57f6DFe219bE08d92Ea55F985311ABaEce89A5";
const BuyPanel = ({
	fileds,
	stopBuy,
	buy,
	balance,
	handleRemove,
	handleAutoSet,
	snipeData,
	handleRefreshWallet,
	approveStabile,
	setVar,
}) => {
	const [open, setOpen] = useState(false);
	const [openPanel, setOpenPanel] = useState(true);

	const handleOpenDialog = (e) => {
		setOpen(true);
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
	};
	const handleChangePanel = (e, newValue) => {
		setOpenPanel(newValue);
	};
	const handleApprove = (e, value) => {
		e.stopPropagation();
		e.preventDefault();
		if (!!snipeData.approve) {
			const f = snipeData.approve.ETH.find(
				(f) => f.value.toLowerCase() === value.toLowerCase()
			);
			if (!!f) {
				if (f.UniswapV2) {
					approveStabile(value, UniswapV2);
				}
				if (f.ReadySwapRouter) {
					approveStabile(value, ReadySwapRouter);
				}
			}
		}
		return false;
	};
	const checkApprove = (value) => {
		if (!!snipeData.approve) {
			const f = snipeData.approve.ETH.find(
				(f) => f.value.toLowerCase() === value.toLowerCase()
			);
			if (!!f) {
				if (f.UniswapV2 && f.ReadySwapRouter) {
					return true;
				}
			}
		}
		return false;
	};
	const handleOpenMEV = () => {
		setVar({
			openMEV: true,
		});
	};
	return (
		<Accordion expanded={openPanel} onChange={handleChangePanel}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				className="accordion-header"
			>
				<div className="buy_panel_header">
					<div>BUY</div>
					<div className="walletBTitle">
						<div className="walletBalance">
							<span className="subString">
								{snipeData.balance} ETH
							</span>
							<LoopIcon
								onClick={(e) =>
									handleRefreshWallet(
										e,
										snipeData.snipe_wallet
									)
								}
							/>
							<span
								onClick={(e) => {
									e.stopPropagation();
									callLink(
										fileds.network,
										snipeData.snipe_wallet
									);
								}}
								className="iconpos"
							>
								{fileds.network === "ETH" ? (
									<EtherScan />
								) : (
									<BscScan />
								)}
							</span>
						</div>
						<div className="rsbalance">
							{!!snipeData.rsBalance && (
								<div>
									<span>RS: </span>
									<span className="subString">
										{snipeData.rsBalance} ETH
									</span>
								</div>
							)}
						</div>
					</div>
					<div className="buy_panel_header_buttons">
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handleOpenDialog}
							>
								<SettingsIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</AccordionSummary>
			<AccordionDetails>
				<DialogBuyPanel
					open={open}
					setOpen={setOpen}
					fileds={fileds}
					setVar={setVar}
				/>
				<div className="tokenName">
					<span>{fileds.name}</span>

					{fileds.contract && (
						<span>
							<span
								onClick={(e) => {
									e.stopPropagation();
									callLink(fileds.network, fileds.contract);
								}}
								className="iconpos"
							>
								{fileds.network === "ETH" ? (
									<EtherScan />
								) : (
									<BscScan />
								)}
							</span>
							<IconButton
								onClick={() =>
									callNewSite(
										`https://dexscreener.com/ethereum/${fileds.contract.toLowerCase()}`
									)
								}
								color="primary"
							>
								<DexScreener width={25} />
							</IconButton>
						</span>
					)}
					<span className="swapInfo">{fileds.swap}</span>
					<CssTextField
						style={{ display: "none" }}
						id="publicKey"
						value={snipeData.snipe_wallet}
					/>
				</div>
				<Grid container spacing={1} className="dialog_form">
					<Grid item xs={10} className="contentCenter">
						<CssTextField
							className="formInput"
							label="Token address"
							id="contract"
							value={fileds.contract}
							onChange={(e) =>
								setVar({ contract: e.target.value })
							}
							autoComplete="off"
						/>
						<Tooltip_
							title="Token Address:  Paste here the contract address what you would like to swap."
							enterTouchDelay={0}
						>
							<InfoIcon className="infoIcon" />
						</Tooltip_>
					</Grid>
					<Grid item xs={2} className="contentCenter swapmain">
						<div className="swap_autoset_Icon">
							<IconButton color="primary" onClick={handleAutoSet}>
								<AutoFixHighIcon />
							</IconButton>
						</div>
					</Grid>

					<Grid item xs={12} sm={4} className="contentCenter">
						<Paper
							elevation={3}
							sx={{
								p: "2px 4px",
								display: "flex",
								alignItems: "center",
							}}
							className="selectField_form"
						>
							<Select
								id="network-select"
								value={fileds.pair}
								onChange={(event) => {
									if (
										event.target.value ===
										"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2"
									) {
										setVar({ pair: event.target.value });
									} else {
										setVar({
											pair: event.target.value,
											mev: false,
										});
									}
								}}
							>
								{_pairs.map((m) => (
									<MenuItem
										key={m.value}
										value={m.value}
										className="pairItem"
									>
										<span>{m.name}</span>

										{m.name !== "WETH" &&
											!checkApprove(m.value) && (
												<span
													className="sellicon-dark"
													onClick={(e) =>
														handleApprove(
															e,
															m.value
														)
													}
												>
													A
												</span>
											)}
									</MenuItem>
								))}
							</Select>
						</Paper>
						<Tooltip_
							title="The main token par you want to trade. Most trade with ETH but you can change to trade with USD, USDT, or DAI, if there is a liquidity pool."
							enterTouchDelay={0}
						>
							<InfoIcon className="infoIcon" />
						</Tooltip_>
					</Grid>
					<Grid item xs={12} sm={8} className="contentCenter">
						<Autocomplete
							multiple
							freeSolo
							limitTags={1}
							id="MethodIDs"
							className="formInput"
							options={fileds.allMethods}
							value={[...fileds.methods]}
							disableCloseOnSelect
							getOptionLabel={(option) =>
								!!option.func_hash ? option.func_hash : option
							}
							onChange={(event, newValue, reason) => {
								setVar({ methods: newValue });
							}}
							onInputChange={(event, newInputValue, reason) => {
								if (reason === "input") {
									setVar({ methodsString: newInputValue });
								} else {
									setVar({ methodsString: "" });
								}
							}}
							renderOption={(props, option, { selected }) => {
								return (
									<li {...props}>
										<Checkbox
											icon={icon}
											checkedIcon={checkedIcon}
											checked={selected}
											className="checkboxM"
										/>
										{!!option.func_name
											? option.func_name
											: option}
									</li>
								);
							}}
							renderInput={(params) => (
								<CssTextField
									{...params}
									label={`MethodIDs ${
										!!fileds.simulate.needs_open
											? "⚠️Add liquidity"
											: ""
									}`}
									className="formInput"
								/>
							)}
						/>

						<Tooltip_
							title="MethodIDs are the ways that the smart contract deployer might open trading on the contract, and are unique to individual smart contracts. ReadySwap sources this information from the smart contract and implements it for you."
							enterTouchDelay={0}
						>
							<InfoIcon className="infoIcon" />
						</Tooltip_>
					</Grid>

					<Grid container spacing={1} className="dialog_form">
						<Grid item xs={6} sm={3} className="contentCenter">
							<FormControlLabel
								className="buytokenLabel"
								control={
									<Switch
										color="primary"
										checked={fileds.buyToken}
										onChange={(e) => {
											if (!e.target.checked) {
												setVar({
													buyToken: e.target.checked,
													//mev: false,
												});
											} else {
												setVar({
													buyToken: e.target.checked,
												});
											}
										}}
									/>
								}
								label="Set token"
								labelPlacement="top"
							/>
						</Grid>

						<Grid item xs={6} sm={4} className="contentCenter">
							{fileds.buyToken && (
								<>
									<CssTextField
										className="formInput"
										label="Amount %"
										id="amount"
										error={!fileds.amountCheck}
										value={fileds.amount}
										onChange={(e) => {
											setVar({
												amount: e.target.value,
											});
										}}
										type="number"
									/>
									<Tooltip_
										title={
											<div>
												When buying Token amount, the
												Value is the Maximum you will
												pay. If you have allocated more
												that required, the excess funds
												are returned to you.
												<p>
													<div>0.5% - 0.05 ETH</div>
													<div>0.98% - 0.1 ETH</div>
													<div>1.98% - 0.2 ETH</div>
												</p>
											</div>
										}
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</>
							)}
						</Grid>

						<Grid item xs={12} sm={5} className="contentCenter">
							<CssTextField
								className="formInput valueInput"
								label="Value"
								id="value"
								error={
									!fileds.mevCheck ||
									(!fileds.amountCheck && fileds.buyToken)
								}
								value={fileds.value}
								onChange={(e) =>
									setVar({ value: e.target.value })
								}
								type="number"
							/>
							<Tooltip_
								title="The Base pair value you are supplying for the trade. If you are instead buying a Token amount, rather than Base pair amount, make sure you set sufficient value to avoid reverted/failed transaction due to low Base Pair amount. Eg don't try to buy 1% of a token supply, for 0.05 ETH at launch. It will fail."
								enterTouchDelay={0}
							>
								<InfoIcon className="infoIcon" />
							</Tooltip_>
						</Grid>
					</Grid>

					<Grid container spacing={1} className="dialog_form">
						<Grid item xs={6} sm={3} className="contentCenter">
							<FormControlLabel
								className="buytokenLabel"
								control={
									<Switch
										color="primary"
										checked={fileds.mev}
										onChange={(e) =>
											setVar({
												mev: e.target.checked,
											})
										}
									/>
								}
								label="Bribe"
								labelPlacement="top"
							/>
							<Tooltip_
								title={
									<div>
										<p>
											Please make sure you are having
											enough fund for the bribe trade!
											Until the amount%, value & bribe max
											prio red the setting is
											undercalculated!
										</p>
										Remember if the miner not accepting the
										tip the trade will go in regular mode!
									</div>
								}
								enterTouchDelay={0}
							>
								<InfoIcon
									className="infoIcon"
									style={{ marginLeft: "-22px" }}
								/>
							</Tooltip_>
						</Grid>

						{fileds.mev && (
							<Grid item xs={6} sm={4} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Tip (ETH Value)"
									id="mevEth"
									error={!fileds.mevCheck}
									value={fileds.mevEth}
									onChange={(e) =>
										setVar({
											mevEth: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title={
										<div>
											Select the amount of eth you are
											willing to pay for the miner to
											prioritise your transaction!
											<p>
												REMINDER: If you pay more you
												have better slot!
											</p>
											Make sure you have sufficient amount
											of eth in your wallet to perform the
											transaction!
										</div>
									}
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
						)}
						{fileds.mev && (
							<>
								<Grid
									item
									xs={6}
									sm={3}
									className="contentCenter"
								>
									<FormControlLabel
										className="buytokenLabel"
										control={
											<Switch
												color="primary"
												checked={fileds.buy_anyway}
												onChange={(e) =>
													setVar({
														buy_anyway:
															e.target.checked,
														multi_bribe: !!e.target
															.checked
															? false
															: fileds.multi_bribe,
													})
												}
											/>
										}
										label="Buy anyway"
										labelPlacement="top"
									/>
									<Tooltip_
										title="Bot will buy into the token no matter if the bundle fails."
										enterTouchDelay={0}
									>
										<InfoIcon
											className="infoIcon"
											style={{ marginLeft: "-22px" }}
										/>
									</Tooltip_>
								</Grid>

								<Grid
									item
									xs={6}
									sm={2}
									className="contentCenter swapmain"
									style={{ justifyContent: "end" }}
								>
									<div className="swap_autoset_Icon">
										<IconButton
											color="primary"
											onClick={handleOpenMEV}
										>
											<TornadoIcon />
										</IconButton>
									</div>
								</Grid>
								<Grid
									item
									xs={5}
									sm={3}
									className="contentCenter"
								>
									{fileds.pair ===
										"0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2" &&
										!fileds.transfer_delay && (
											<>
												<FormControlLabel
													className="buytokenLabel"
													control={
														<Switch
															color="primary"
															checked={
																fileds.multi_bribe
															}
															onChange={(e) => {
																setVar({
																	multi_bribe:
																		e.target
																			.checked,
																	buy_anyway:
																		!!e
																			.target
																			.checked
																			? false
																			: fileds.buy_anyway,
																});
															}}
														/>
													}
													label="Multi TX"
													labelPlacement="top"
												/>
												<Tooltip_
													title="Multi TX bribe button to be used only if you want RS contract send your transaction to the block builder."
													enterTouchDelay={0}
												>
													<InfoIcon
														className="infoIcon"
														style={{
															marginLeft: "-22px",
														}}
													/>
												</Tooltip_>
											</>
										)}
								</Grid>
								<Grid
									item
									xs={1}
									sm={6}
									className="contentCenter"
								></Grid>
							</>
						)}
						<Grid
							item
							xs={2}
							className="contentCenter swapmain"
							style={{
								marginLeft: "15px",
							}}
						>
							<FormControlLabel
								className="buytokenLabel"
								control={
									<Switch
										color="primary"
										checked={fileds.antiMEV}
										onChange={(e) =>
											setVar({
												antiMEV: e.target.checked,
											})
										}
									/>
								}
								label="AntiMEV"
								labelPlacement="top"
							/>
							<Tooltip_
								title="Enabling Anti-Mev changes the node used from a shared private node to a public AntiMev node, sacrificing a little speed, but avoiding the risk of being front-run by MevBot sandwich bots. Typical Mevbots are attractive to zero-tax token launches."
								enterTouchDelay={0}
							>
								<InfoIcon
									className="infoIcon"
									style={{ marginLeft: "-22px" }}
								/>
							</Tooltip_>
						</Grid>
					</Grid>
				</Grid>

				<Grid container spacing={1} className="dialog_form"></Grid>
				<div className="button_panel button_panel_buy">
					<div style={{ width: "50px" }}>
						{fileds.processing && (
							<RingLoader color="#36d7b7" size={40} />
						)}
					</div>
					<div className="buyButtons">
						<Button
							variant="contained"
							className="btnNew"
							onClick={buy}
							disabled={fileds.processing}
						>
							Trade
						</Button>

						<Button
							variant="contained"
							className="btnNew"
							onClick={stopBuy}
						>
							Stop
						</Button>
					</div>
					<div className="swap_remove_Icon">
						<IconButton
							color="primary"
							onClick={() => handleRemove()}
							style={{ margin: 0 }}
						>
							<DeleteForeverIcon />
						</IconButton>
					</div>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default BuyPanel;

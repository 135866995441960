import React, { useContext, useRef, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { browserName } from "react-device-detect";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import AppContext from "../context/AppContext";
import axiosI from "../api/api";
import BarLoader from "react-spinners/BarLoader";
import { Web3Button } from "@web3modal/react";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import { sendToken } from "../utils/functions";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
window.recaptchaOptions = {
	useRecaptchaNet: true,
};
let n = false;
const TGConnect = () => {
	const routeParams = useParams();
	const { reCaptcha, account } = useContext(AppContext);
	const navigate = useNavigate();

	const { chain, chains } = useNetwork();
	const { address, isConnected } = useAccount();
	const [message, setMessage] = useState("");
	const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage(
		{
			message,
			onSuccess(data) {
				setSignature(data);
				auth(data);
			},
			onError(error) {
				window.location.reload();
			},
		}
	);

	const [nonce, setNonce] = useState();
	const [signature, setSignature] = useState();
	const [userid, setUserid] = useState("");
	const [username, setUsername] = useState("");
	const [successConnect, setSuccessConnect] = useState(false);

	useEffect(() => {
		if (isConnected && !!address) {
			checkWalletSession();
		}
	}, [address, chain]);

	const checkWalletSession = () => {
		if (!!!routeParams.id) return false;
		axiosI
			.post("/api/checkAuthTGConnect", {
				token: reCaptcha.getReCAPTCHA.key,
				address,
				browser: browserName,
				type: reCaptcha.getReCAPTCHA.type,
				telegramToken: routeParams.id,
			})
			.then((res) => {
				try {
					console.log("telegram", res.data.data.telegram);
					if (!!res.data.data.telegram) {
						setUserid(res.data.data.telegram.userID);
						setUsername(res.data.data.telegram.username);
					} else {
						setUserid("");
						setUsername("");
					}

					account.setMainWallet({
						type: "set",
						data: {
							address,
							network: chain?.id,
						},
					});

					if (!!res.data.data.token) {
					} else {
						setNonce(res.data.data.data);
						setMessage(
							`\nSync request received between ReadySwap TG and ReadySwap Web.\n\nConfirm that this request has been initiated by you.\n\nImportant: This request will not trigger a blockchain transaction or cost any gas fees.\n\n\n\n ${res.data.data.data}`
						);
					}
				} catch (error) {}
			})
			.catch((error) => {
				if (error.response.data.error.code == 9) {
					toast.error(
						"Cookies are not enabled in your browser. On mobile, cookies are needed to manage the wallet connection. Please enable!"
					);
				} else if (error.response.data.error.code == 5) {
					navigate("/logout");
				} else {
					toast.error("Telegram connection session expired");
				}
			});
	};

	const handleLogin = async () => {
		try {
			if (n) return true;
			n = true;
			await signMessage({ message });
		} catch (error) {
			//console.log("handleLogin", handleLogin);
		}
	};
	const auth = (data) => {
		if (!!!routeParams.id) return false;
		try {
			axiosI
				.post("/api/loginTG", {
					signature: data,
					address,
					token: reCaptcha.getReCAPTCHA.key,
					nonce,
					telegramToken: routeParams.id,
				})
				.then((res) => {
					try {
						n = false;
						sessionStorage.setItem("token", res.data.data);
						//console.log("routeParams", routeParams);
						sendToken(res.data.data);
						setSuccessConnect(true);
					} catch (error) {}
				})
				.catch((error) => {
					toast.error("Telegram connection session expired");
				});
		} catch (error) {}
	};
	const redirectBot = () => {
		navigate("/main");
	};
	const redirectTGBot = () => {
		window.open(`https://t.me/ReadySwap_bot?start=1`, "_blank");
	};
	return (
		<div className="startBG">
			<div className="startPageTG">
				<div className="startPageBox">
					<div className="startPageName">
						Ready
						<span className="startPageSubName">Swap</span>
					</div>
					<div className="startPageName">
						<span className="startPageSubName">Connect</span>
					</div>

					{!!userid ? (
						<div>
							{!!!successConnect ? (
								<div style={{ marginTop: "30px" }}>
									<div>
										<p>
											Sync request received between
											ReadySwap TG and ReadySwap Web.
										</p>

										<p>
											Confirm that this request has been
											initiated by you.
										</p>
									</div>
									<div>
										<div>
											<label className="simulateInfoTokenLabel">
												Telegram User:
											</label>
											<span>{username}</span>
											<span>({userid})</span>
										</div>
									</div>
									<div className="swapForm swapConnectWallet">
										<div className="loginBox">
											<div className="loginMainWallet">
												<Web3Button />
											</div>
											{!!nonce && (
												<Button
													variant="contained"
													className="btnNew"
													onClick={handleLogin}
												>
													{isLoading
														? "Sign Message"
														: "Login"}
												</Button>
											)}
										</div>
									</div>
								</div>
							) : (
								<div style={{ marginTop: "30px" }}>
									<div>
										<p>Connection successful!</p>
									</div>
									<div className="loginBoxTG">
										<div>
											<Button
												variant="contained"
												className="btnNew"
												onClick={redirectBot}
											>
												ReadySwap Web
											</Button>
										</div>
										<div>
											<Button
												variant="contained"
												className="btnNew"
												onClick={redirectTGBot}
											>
												ReadySwap Telegram
											</Button>
										</div>
									</div>
								</div>
							)}{" "}
						</div>
					) : (
						<div style={{ marginTop: "30px" }}>
							⚠️ Telegram connection session expired ⚠️
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TGConnect;

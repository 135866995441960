import React, { useState, useEffect, useContext } from "react";
import "../App.css";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import { toast } from "react-toastify";
import io from "socket.io-client";
import axiosI from "../api/api";
import { useAccount } from "wagmi";
import IconButton from "@mui/material/IconButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { Tooltip_ } from "./Tooltip";
import Checkbox from "@mui/material/Checkbox";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import useSound from "use-sound";
import alertMP3 from "../sound/alert.mp3";
import DialogWallets from "./DialogWallets";
import DialogAddWallet from "./DialogAddWallet";

import {
	getSortCode,
	callNewSite,
	sendToken,
	createDefault,
	setDefault,
	getDefault,
	sleepMs,
} from "../utils/functions";
import LocalGasStationIcon from "@mui/icons-material/LocalGasStation";

import { GitBook } from "./Icons";
import Snipe from "./Snipe";
import ClickAwayListener from "@mui/base/ClickAwayListener";
import { styled, useTheme } from "@mui/material/styles";

import Drawer from "@mui/material/Drawer";

import List from "@mui/material/List";

import MenuIcon from "@mui/icons-material/Menu";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import RingLoader from "react-spinners/RingLoader";
import BounceLoader from "react-spinners/BounceLoader";

const _pairs = [
	{
		value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		name: "WETH",
	},
	{
		value: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
		name: "DAI",
	},
	{
		value: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
		name: "USDT",
	},
	{
		value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		name: "USDC",
	},
];
const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "center",
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-end",
}));

const networks = ["ETH"];
let lastHash = "";
let lastHashM = "";
let lastTsStop = {};
let lastGlobalTsStop = 0;
let taxInterval = {};
const Main = () => {
	const navigate = useNavigate();
	const { account } = useContext(AppContext);
	const [play] = useSound(alertMP3);
	const { address } = useAccount({
		onDisconnect() {
			navigate("/logout");
		},
	});
	const [network, setNetwork] = useState("ETH");
	const [openWallets, setOpenWallets] = useState(false);
	const [openAddWallet, setOpenAddWallet] = useState(false);
	const [gas, setGas] = useState("");
	const [wsConnect, setWsConnect] = useState(false);
	const [snipeWallet, setSnipeWallet] = useState("");
	const [tradeConnect, setTradeConnect] = useState(false);
	const [figy, setFigy] = useState(false);

	const [open, setOpen] = React.useState(false);

	const handleDrawerOpen = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setOpen(true);
	};

	const handleTradeConnect = () => {
		if (tradeConnect) {
			account.setWallets({
				type: "resetTradeConnect",
			});
			setTradeConnect(false);
		} else {
			let f = 0;
			for (
				let index = 0;
				index < Object.keys(account.wallets).length;
				index++
			) {
				const wallet = Object.keys(account.wallets)[index];

				if (!!account.wallets[wallet].connect) f++;
			}
			if (f > 1) {
				account.setWallets({
					type: "setTradeConnect",
				});
				setTradeConnect(true);
			}
		}
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};

	useEffect(() => {
		if (!wsConnect) {
			connectWS();
		}
		getUserData();
		getGas();
	}, []);

	useEffect(() => {
		if (Object.keys(account.wallets).length > 0 && figy) {
			let f = 0;
			for (
				let index = 0;
				index < Object.keys(account.wallets).length;
				index++
			) {
				const wallet = Object.keys(account.wallets)[index];

				if (!!account.wallets[wallet].connect) f++;
			}
			if (f > 1) {
				account.setWallets({
					type: "setTradeConnect",
				});
				setTradeConnect(true);
			}
		}
	}, [figy]);

	const handleChangeNetwork = (event) => {
		setNetwork(event.target.value);
	};

	const handleClickWallet = (wallet) => {
		setSnipeWallet(wallet);
		handleDrawerClose();
	};
	const getGas = () => {
		setInterval(() => {
			axiosI
				.post("/api/getGas", {})
				.then((res) => {
					//console.log("getContract", res.data.data);
					let gas = !!res.data.data ? res.data.data.FastGasPrice : "";
					setGas(gas);
				})
				.catch((error) => {
					/* if (error.response.data.error.code == 5) {
						//navigate("/logout");
					} */
				});
		}, 5000);
		axiosI
			.post("/api/getGas", {})
			.then((res) => {
				//console.log("getContract", res.data.data);
				let gas = !!res.data.data ? res.data.data.FastGasPrice : "";
				setGas(gas);
			})
			.catch((error) => {
				/* if (error.response.data.error.code == 5) {
					//navigate("/logout");
				} */
			});
	};
	const getTax = (_publicKey, contract) => {
		taxInterval[contract.toLowerCase()] = setInterval(() => {
			axiosI
				.post("/api/getTax", {
					contract,
				})
				.then((res) => {
					setTaxes(_publicKey, res.data.data);
				})
				.catch((error) => {
					stopTax(_publicKey);
				});
		}, [5000]);
		axiosI
			.post("/api/getTax", {
				contract,
			})
			.then((res) => {
				setTaxes(_publicKey, res.data.data);
			})
			.catch((error) => {});
	};
	const setTaxes = (_publicKey, tax) => {
		try {
			if (!!!tax) throw "!!!tax";
			if (!!!tax.buy_tax && !!!tax.sell_tax)
				throw "!!!tax.buy_tax && !!!tax.sell_tax";

			setVar(_publicKey.toLowerCase(), {
				buy_tax: parseFloat(tax.buy_tax),
				sell_tax: parseFloat(tax.sell_tax),
			});
		} catch (error) {
			stopTax(_publicKey);
		}
	};
	const stopTax = (_publicKey) => {
		for (let index = 0; index < Object.keys(taxInterval).length; index++) {
			const element = Object.keys(taxInterval)[index];

			clearInterval(taxInterval[element]);
			taxInterval[element] = null;
			setVar(_publicKey.toLowerCase(), {
				buy_tax: "-",
				sell_tax: "-",
			});
		}
	};
	const getTokens = () => {
		axiosI
			.post("/api/tokens", {})
			.then(async (res) => {})
			.catch((error) => {
				/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
			});
	};
	const getUsertasks = () => {
		axiosI
			.post("/api/usertasks", {})
			.then(async (res) => {})
			.catch((error) => {
				/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
			});
	};
	const getUserData = (type = true) => {
		axiosI
			.post("/api/userdata", {})
			.then(async (res) => {
				try {
					if (res.data.data.wallets.length === 0) {
						//console.log("open Add wallet");
						setOpenAddWallet(true);
						setSnipeWallet("");
						return false;
					}

					await account.setWallets({
						type: "set",
						data: res.data.data,
					});
					if (type) {
						setSnipeWallet(res.data.data.wallets[0].snipe_wallet);

						for (
							let index = 0;
							index < res.data.data.wallets.length;
							index++
						) {
							const wallet = res.data.data.wallets[index];
							createDefault(wallet.snipe_wallet, {
								_allInv: 0,
								_profitETH: 0,
								_profitPerc: 0,
								_processing: false,
								_contract: "",
								_autoApprove: false,
								_approved: [],
								_proc: false,
								_getPool: true,
								_fee: "",
								_pair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
								_router:
									"0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
								_swap: "",
								_lastHash: "",
							});
						}
						setFigy(true);
						getTokens();
						getUsertasks();
					}
				} catch (error) {}
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};

	const connectWS = async () => {
		axiosI
			.post("/api/wsconnect", {})
			.then((res) => {
				wsInit(res.data.data);
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};
	const wsInit = async (data) => {
		const socket = await io.connect("https://app.readyswap.app:40888", {
			secure: true,
			auth: {
				token: sessionStorage.getItem("token"),
				session: data,
			},
		});

		socket.on("SUCCESS", (data) => {
			let _defaultD = getDefault();
			let _default = {};
			const d = JSON.parse(data);
			/* if (lastHash === d.data.txHash) return false;
			if (_defaultD[d.data.publicKey]?._lastHash === d.data.txHash)
				return false;
			lastHash = d.data.txHash; */
			if (!!lastTsStop[d.upd.publicKey]) {
				if (lastTsStop[d.upd.publicKey] + 1000 > new Date().getTime())
					return false;
			}
			lastTsStop[d.upd.publicKey] = new Date().getTime();
			play();
			toast.success("Success TX");
			// töltés
			setVar(d.data.publicKey.toLowerCase(), {
				contract: d.data.contract,
				pair: d.data.pair,
				//router: d.data.router,
				fee: d.data.fee,
				swap: d.data.swap,
			});
			setDefault(d.data.publicKey, {
				_contract: d.data.contract,
				_pair: d.data.pair,
				//_router: d.data.router,
				_fee: d.data.fee,
				_swap: d.data.swap,
				_getPool: false,
				_lastHash: d.data.txHash,
			});

			if (d.data.type === "SELL") {
				setVar(d.data.publicKey, {
					sellAmount: "",
				});
				handleRefreshWallet(null, d.data.publicKey);
			} else if (
				d.data.type === "BUY_ETH" ||
				d.data.type === "BUY_TOKEN"
			) {
				setVar(d.data.publicKey, {
					swap: d.data.swap,
					router: d.data.router,
					owner: "",
					methods: [],
					methodsString: "",
				});

				_default._swap = d.data.swap;
				_default._router = d.data.router;
				if (!!d.data.fee) {
					setVar(d.data.publicKey, {
						fee: d.data.fee,
					});
					_default._fee = d.data.fee;
				}
				handleRefreshWallet(null, d.data.publicKey);
				startBalance(d.data);
			} else {
				const f = _pairs.find(
					(f) =>
						f.value.toLowerCase() === d.data.contract.toLowerCase()
				);
				if (!!f) {
					getUserData(false);
				}
			}

			_default._processing = false;
			_default._proc = false;
			setDefault(d.upd.publicKey.toLowerCase(), { ..._default });
			setVar(d.upd.publicKey.toLowerCase(), {
				processing: false,
			});
			loadHistoryData(d.upd.publicKey);
		});
		socket.on("SUCCESSTRADE", (data) => {
			const d = JSON.parse(data);
			if (!!lastTsStop[d.upd.publicKey]) {
				if (lastTsStop[d.upd.publicKey] + 1000 > new Date().getTime())
					return false;
			}
			lastTsStop[d.upd.publicKey] = new Date().getTime();

			play();
			toast.success("Success TX");
			getUsertasks();
		});
		socket.on("SUCCESSAUTOTRADE", (data) => {
			const d = JSON.parse(data);
			if (!!lastTsStop[d.upd.publicKey]) {
				if (lastTsStop[d.upd.publicKey] + 1000 > new Date().getTime())
					return false;
			}
			lastTsStop[d.upd.publicKey] = new Date().getTime();

			play();
			toast.success("Success TX");
			getUsertasks();
		});
		socket.on("MSG", (data) => {
			const d = JSON.parse(data);
			if (lastHashM === d.data) return false;
			lastHashM = d.data;
			//console.log("WS-MSG", getSortCode(JSON.parse(data)));
			toast.success(getSortCode(d.data));
		});
		socket.on("MSGTRADE_", (data) => {
			const d = JSON.parse(data);
			if (lastHashM === d.data) return false;
			lastHashM = d.data;
			//console.log("WS-MSG", getSortCode(JSON.parse(data)));
			toast.success(d.data);
		});
		socket.on("TESTSUCCESS", (data) => {
			if (lastGlobalTsStop + 1000 > new Date().getTime()) return false;
			lastGlobalTsStop = new Date().getTime();
			const d = JSON.parse(data);

			//console.log("WS-MSG", getSortCode(JSON.parse(data)));
			toast.success(d.data);
		});
		socket.on("TESTERROR", (data) => {
			if (lastGlobalTsStop + 1000 > new Date().getTime()) return false;
			lastGlobalTsStop = new Date().getTime();
			const d = JSON.parse(data);
			//console.log("WS-MSG", getSortCode(JSON.parse(data)));
			toast.error(d.data);
		});
		socket.on("MSGTRADE", (data) => {
			const d = JSON.parse(data);
			if (lastHashM === d.data) return false;
			lastHashM = d.data;
			//console.log("WS-MSG", getSortCode(JSON.parse(data)));
			toast.success(getSortCode(d.data));
		});
		socket.on("ERROR", (data) => {
			const d = JSON.parse(data);

			if (!!lastTsStop[d.upd.publicKey]) {
				if (lastTsStop[d.upd.publicKey] + 1000 > new Date().getTime())
					return false;
			}
			lastTsStop[d.upd.publicKey] = new Date().getTime();

			toast.error(d.data);
			setDefault(d.upd.publicKey.toLowerCase(), {
				_processing: false,
				_proc: false,
			});
			setVar(d.upd.publicKey.toLowerCase(), {
				processing: false,
			});
			loadHistoryData(d.upd.publicKey);
		});
		socket.on("ERRORTRADE", (data) => {
			const d = JSON.parse(data);
			if (!!lastTsStop[d.upd.publicKey]) {
				if (lastTsStop[d.upd.publicKey] + 1000 > new Date().getTime())
					return false;
			}
			lastTsStop[d.upd.publicKey] = new Date().getTime();
			getUsertasks();
			toast.error(d.data);
		});
		socket.on("ERRORAUTOTRADE", (data) => {
			const d = JSON.parse(data);
			if (!!lastTsStop[d.upd.publicKey]) {
				if (lastTsStop[d.upd.publicKey] + 1000 > new Date().getTime())
					return false;
			}
			lastTsStop[d.upd.publicKey] = new Date().getTime();
			getUsertasks();
			toast.error(d.data);
		});
		socket.on("BALANCE", (data) => {
			const d = JSON.parse(data);
			if (d.data == -1) {
				stopbalance(d.wallet);
				return false;
			}
			const _profitETH = parseFloat(d.data);

			calcPerc(d.wallet, _profitETH);
			setVar(d.wallet, {
				profitETH: _profitETH.toFixed(6),
			});
		});
		socket.on("BALANCE-END", (data) => {
			const d = JSON.parse(data);
			balance(d.wallet);
		});
		socket.on("TOKENS", (data) => {
			const d = JSON.parse(data);
			//console.log("TOKENS", d.data);
			account.setWallets({
				type: "setTokens",
				data: d.data,
			});
		});
		socket.on("TASKS", (data) => {
			const d = JSON.parse(data);
			account.setWallets({
				type: "setTasks",
				data: d.data,
			});
			account.setWallets({
				type: "setAutoSnipe",
				data: d.autoSnipe,
			});
		});
	};

	const calcPerc = (wallet, _profitETH) => {
		let _default = getDefault();
		if (_default[wallet]._allInv === 0) return false;
		if (_profitETH === 0) return false;

		let _profitPerc =
			((_default[wallet]._allInv - _profitETH) /
				(_default[wallet]._allInv / 100)) *
			-1;
		setVar(wallet, {
			profitPerc: _profitPerc.toFixed(2),
		});
		setDefault(wallet, { _profitPerc, _profitETH });
	};
	const startBalance = (tx) => {
		if (tx.type === "BUY_ETH" || tx.type === "BUY_TOKEN") {
			let _default = getDefault();
			_default[tx.publicKey.toLowerCase()]._allInv += parseFloat(tx.inv);

			setVar(tx.publicKey.toLowerCase(), {
				allInv: _default[tx.publicKey.toLowerCase()]._allInv.toFixed(6),
			});

			if (!!_default[tx.publicKey.toLowerCase()]._contract) {
				if (_default[tx.publicKey.toLowerCase()]._autoApprove) {
					if (
						_default[tx.publicKey.toLowerCase()]._approved.indexOf(
							document
								.getElementById("contract")
								.value.toLowerCase()
						) === -1
					) {
						setTimeout(() => {
							approve(tx.publicKey.toLowerCase());
						}, 2000);
					}
				}
			}

			setDefault(tx.publicKey.toLowerCase(), {
				..._default[tx.publicKey.toLowerCase()],
			});
			balance(tx.publicKey.toLowerCase());
		}
	};
	const stopbalance = (wallet) => {
		axiosI
			.post("/api/stopbalance", { wallet })
			.then((res) => {
				//console.log("stopbalance", res.data.data);
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
	};
	const balance = (wallet) => {
		let _default = getDefault();
		if (!!!_default[wallet]._contract) return false;
		axiosI
			.post("/api/balance", {
				contract: _default[wallet]._contract,
				pair: _default[wallet]._pair,
				router: _default[wallet]._router,
				publicKey: wallet,
				network: "ETH",
				fee: _default[wallet]._fee,
				swap: _default[wallet]._swap,
			})
			.then((res) => {
				//console.log("balance", res.data.data);
			})
			.catch((error) => {
				toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			});
		stopTax(wallet.toLowerCase());
		getTax(wallet.toLowerCase(), _default[wallet]._contract);
	};
	const approve = (_publicKey) => {
		let _default = getDefault();
		_default[_publicKey]._approved.push(
			document.getElementById("contract").value.toLowerCase()
		);
		if (_default[_publicKey]._proc) return false;
		setDefault(_publicKey, {
			_proc: true,
			_approved: _default[_publicKey]._approved,
		});
		axiosI
			.post("/api/trade", {
				main_wallet: "",
				publicKey: _publicKey, //account.wallets[0]?.snipe_wallet,
				privateKey: "",
				contract: _default[_publicKey]._contract,
				sortContract: "", // .slice(2, -1)
				methodIDs: [], // ide több methodID is kerülhet de nem kötelező
				owner: "",
				gas: {
					autoGwei: false,
					copyMultiple: false,
					multiple: "10", //lehet üres
					gasPrice: "", //lehet üres
					gasLimit: "608000", //lehet üres
					maxFeePerGas: "", //lehet üres
					maxPriorityFeePerGas: "", //lehet üres
				},
				value: "0",
				amount: "", //lehet üres
				type: "APPROVE", //BUY_ETH, BUY_TOKEN, SELL, APPROVE
				delay: {
					type: "", //BLOCK, MS
					value: "",
				},
				slippage: "100", //lehet üres
				pair: _default[_publicKey]._pair, //router címe
				router: "0x2C57f6DFe219bE08d92Ea55F985311ABaEce89A5",
				antiMev: false,
				onlyManGas: false,
				prevGenerate: false,
				txHash: "",
				network: network,
				swap: _default[_publicKey]._swap,
				status: 0,
				fee: _default[_publicKey]._fee,
			})
			.then((res) => {
				reNewSession(res.data.data.token);
			})
			.catch((error) => {
				toast.error(
					"Process start error. Click stop and than start trade again."
				);
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				}
			})
			.finally(function () {
				setDefault(_publicKey, { _proc: false });
			});
	};
	const handleRefreshWallet = (e, wallet) => {
		if (!!e) {
			e.stopPropagation();
			e.preventDefault();
			e.stopPropagation();
		}
		let _default = getDefault();
		if (_default[wallet]._proc) return false;
		setDefault(wallet, { _proc: true });
		axiosI
			.post("/api/getWalletBalance", {
				publicKey: wallet,
			})
			.then((res) => {
				//console.log("getWalletBalance", res.data.data);
				account.setWallets({
					type: "setBalance",
					data: res.data.data.wallets,
				});
			})
			.catch((error) => {})
			.finally(function () {
				setDefault(wallet, { _proc: false });
			});
	};
	const reNewSession = (token) => {
		sessionStorage.setItem("token", token);
		sendToken(token);
	};
	const setVar = (_publicKey, obj) => {
		if (!!!_publicKey) return false;
		account.setWallets({
			type: "setDefault",
			wallet: _publicKey,
			data: obj,
		});
	};
	const loadHistoryData = (_publicKey) => {
		axiosI
			.post("/api/history", {
				publicKey: _publicKey,
			})
			.then((res) => {
				setVar(_publicKey.toLowerCase(), {
					history: [...res.data.data],
				});
			})
			.catch((error) => {
				toast.error("Error load history");
			});
	};

	return (
		<div className="main_">
			<DialogWallets
				open={openWallets}
				setOpen={setOpenWallets}
				getUserData={getUserData}
				network={network}
			/>
			<DialogAddWallet
				open={openAddWallet}
				setOpen={setOpenAddWallet}
				getUserData={getUserData}
			/>
			<div className="swap_main_header">
				<div style={{ display: "flex", alignItems: "center" }}>
					<Tooltip_ title="Access Wallets under the Hamburger menu, Toggle between multiple wallets in your Readyswap Account.">
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={(e) => handleDrawerOpen(e)}
							edge="start"
							sx={{ mr: 2, ...(open && { display: "none" }) }}
						>
							<MenuIcon />
						</IconButton>
					</Tooltip_>

					<div className="swap_main_title">ReadySwap</div>
				</div>
				<div className="swap_header_network">
					<div>
						<Paper
							elevation={3}
							sx={{
								p: "2px 4px",
								display: "flex",
								alignItems: "center",
							}}
							className="selectField"
						>
							<Select
								id="network-select"
								value={network}
								onChange={handleChangeNetwork}
							>
								{networks.map((m) => (
									<MenuItem key={m} value={m}>
										{m}
									</MenuItem>
								))}
							</Select>
						</Paper>
					</div>
					<div className="swap_header_Icon">
						<IconButton
							color="primary"
							onClick={() => setOpenWallets(true)}
						>
							<ManageAccountsIcon />
						</IconButton>
					</div>
				</div>
			</div>

			{Object.keys(account.wallets).length > 0 && (
				<div style={{ display: "block" }}>
					<ClickAwayListener onClickAway={handleDrawerClose}>
						<Drawer
							variant="persistent"
							anchor="top"
							open={open}
							ModalProps={{
								keepMounted: true, // Better open performance on mobile.
							}}
							sx={{
								"& .MuiDrawer-root": {
									position: "absolute",
								},
								"& .MuiPaper-root": {
									position: "absolute",
								},
							}}
							onClose={handleDrawerClose}
							className="drawer_"
						>
							<DrawerHeader className="drawer_header">
								<div>
									<IconButton onClick={handleDrawerClose}>
										<ExpandLessIcon />
									</IconButton>

									<Tooltip_ title="Multi wallet lock - Use the lock if you want buy into one specific token with more wallets. The Active Wallet settings control all the settings across all wallets in the locked group.">
										<IconButton
											onClick={handleTradeConnect}
											className="lockIcon_"
										>
											{tradeConnect ? (
												<LockIcon />
											) : (
												<LockOpenIcon />
											)}
										</IconButton>
									</Tooltip_>
								</div>
								<div className="swap_main_title">Wallets</div>
							</DrawerHeader>
							<List className="menulist_">
								{Object.keys(account.wallets).map((wallet) => (
									<ListItem
										button
										key={wallet}
										onClick={(e) =>
											handleClickWallet(wallet)
										}
									>
										<ListItemIcon className="menuCheckbox">
											<Tooltip_ title="Check the boxes of the wallets you want to enter into locked multi-wallet group.">
												<Checkbox
													checked={
														account.wallets[wallet]
															.connect
													}
													onClick={(e) =>
														e.stopPropagation()
													}
													onChange={(e) =>
														account.setWallets({
															type: "setConnect",
															wallet: wallet,
															connect:
																!account
																	.wallets[
																	wallet
																].connect,
														})
													}
												/>
											</Tooltip_>
										</ListItemIcon>
										<div className="menuProc">
											{account.wallets[wallet].default
												.processing && (
												<RingLoader
													color="#36d7b7"
													size={20}
												/>
											)}
											{!!account.wallets[wallet].default
												.profitPerc && (
												<BounceLoader
													color="#36d7b7"
													size={20}
												/>
											)}
										</div>
										<ListItemText
											primary={
												account.wallets[wallet].name
											}
											secondary={`${getSortCode(
												wallet
											)} - ${
												account.wallets[wallet].balance
											} ETH`}
										/>
									</ListItem>
								))}
							</List>
						</Drawer>
					</ClickAwayListener>
					<div>
						<Snipe
							network={network}
							setNetwork={setNetwork}
							snipeWallet={snipeWallet}
							handleRefreshWallet={handleRefreshWallet}
							getTokens={getTokens}
							loadHistoryData={loadHistoryData}
							gas={gas}
							getTax={getTax}
							stopTax={stopTax}
						/>
					</div>
				</div>
			)}

			<div className="swapFooter">
				<div className="swapFooterBox">
					<div className="footerGas">
						<LocalGasStationIcon />
						<span>{gas}</span>
					</div>
					<div className="footerInfo">
						<span className="footerLinks">
							<span
								onClick={() => {
									callNewSite(
										`https://tufdocs.gitbook.io/readyswap-powered-by-tuf/overview/welcome-to-readyswap`
									);
								}}
							>
								<GitBook color="#ffffff" width={25} />
							</span>
						</span>
						<span>v1.0.21</span>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Main;

export const initHotLinkParams = { contract: "", ref: "" };

const reducerHotLinkParams = (state, action) => {
	switch (action.type) {
		case "set":
			return { ...action.data };
		case "reset":
			return initHotLinkParams;
		default:
			throw new Error();
	}
};

export { reducerHotLinkParams as default };

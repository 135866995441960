export const initWallets = {};

export const ethDefault = {
	network: "ETH",
	name: "",
	simulate: {},
	contract: "",
	owner: "",
	router: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
	swap: "",
	fee: "",
	poolsData: [],
	openPoolList: false,
	allMethods: [],
	methods: [],
	transfer_delay: false,
	methodsString: "",
	value: "0.01",
	amount: "",
	type: "BUY_ETH",
	buyToken: false,
	pair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
	buySlippage: "100",
	multiple: "10",
	buyGasLimit: "608000",
	buyGasPrice: "",
	buymaxFeePerGas: "",
	buymaxPriorityFeePerGas: "",
	delayType: "",
	delayValue: "",
	onlyManGas: false,
	antiMEV: false,
	dynamicNonce: true,
	copyMultipleBuy: false,
	manualGasBuy: false,
	autoApprove: false,
	antiRevert: false,
	onlyOwner: true,
	sellAmount: "",
	sellSlippage: "100",
	multipleSell: "10",
	sellGasLimit: "608000",
	sellGasPrice: "",
	sellmaxFeePerGas: "",
	sellmaxPriorityFeePerGas: "",
	copyMultipleSell: false,
	methodsSell: [],
	methodsStringSell: "",
	allInv: "",
	profitETH: "",
	profitPerc: "",
	antiMEVSell: false,
	manualGasSell: false,
	processing: false,
	openHistory: false,
	openMEV: false,
	mev: false,
	mevGas: "20",
	mevEth: "0.03",
	mevCheck: true,
	buy_anyway: true,
	amountCheck: true,
	buy_tax: "-",
	sell_tax: "-",
	multi_bribe: false,
	only_owner: false,
	proxy_contract: "",

	// trade fields
	tcontract: "",
	tname: "",
	tnetwork: "ETH",
	tswap: "",
	tfee: "",
	tvalue: "0.1",
	tamount: "",
	tsellamount: "100",
	tslippage: "100",
	tmanualGas: false,
	tmultiple: "10",
	tGasLimit: "608000",
	tGasPrice: "",
	tmaxFeePerGas: "",
	tmaxPriorityFeePerGas: "",
	tantiMEV: true,
	tpair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
	tpairToken: "",
	tperc: [-20, 100],
	tpercnum: 100,
	openTrade: false,
	tint: false,
	stopLoss: false,
	ttype: "BUY",
	ttask: "PRICE",
	trouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
	tprice: "",
	tmc: "",
	tprofit: "",
	tstabil: "",
	tid: "",
	ttip: "",

	stslippage: "100",
	stantiMEV: true,
	stmanualGas: false,
	stmultiple: "10",
	stGasLimit: "608000",
	stGasPrice: "",
	stmaxFeePerGas: "",
	stmaxPriorityFeePerGas: "",
	sttip: "",

	cond_tsl: false,
	fomo: "5",
	maxFomoTx: "1",
	fomoValue: "0.1",
	task_tab: "1",
	trade_tab: "1",
	showNum: "",
	lowShowNum: "",
	highShowNum: "",
	percTask: false,
	tp_sl: false,
	limit_protection: "90",
	innerTasks: [
		{
			lowValue: "",
			lowAmount: "",
			highValue: "",
			highAmount: "",
		},
	],
	aid: "",
	aSignals: [],
	aVolume: "",
	aName: "",
	aSymbol: "",
	aCheckSum: "",
	aBuyTax: false,
	aBuyTaxNum: [2, 9],
	aSellTax: false,
	aSellTaxNum: [2, 9],
	aScams: false,
	aScamsNum: [0, 10],
	aMaxLimit: "",
	aValue: "0.03",
	aStopLossAmount: "",
	aStopLoss: "",
	aInnerTasks: [
		{
			takeProfit: "",
			takeProfitAmount: "",
		},
	],
	arouter: "0x7a250d5630B4cF539739dF2C5dAcb4c659F2488D",
	apair: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
	aslippage: "100",
	amanualGas: false,
	amultiple: "10",
	aGasLimit: "608000",
	aGasPrice: "",
	amaxFeePerGas: "",
	amaxPriorityFeePerGas: "",
	anetwork: "ETH",
	aswap: "",
	afee: "",
	aantiMEV: true,
	aType: "AUTOTRADE",
	amaxTx: false,
	atip: "",
	only_cr_addlp: true,

	saslippage: "100",
	samanualGas: false,
	samultiple: "10",
	saGasLimit: "608000",
	saGasPrice: "",
	samaxFeePerGas: "",
	samaxPriorityFeePerGas: "",
	saantiMEV: true,
	satip: "",

	history: [],
	tauto_approve: false,
	aauto_approve: false,
};

const reducerWallets = (state, action) => {
	switch (action.type) {
		case "set":
			let dataW = {};
			let tNum = 0;
			for (let index = 0; index < action.data.wallets.length; index++) {
				const wallet = action.data.wallets[index];
				let d = { ...wallet };
				if (!!!dataW[d.snipe_wallet]) {
					let task = [];
					for (
						let index = 0;
						index < action.data.task.length;
						index++
					) {
						const t = action.data.task[index];
						if (t.publickey === d.snipe_wallet) {
							task.push(t);
						}
					}

					let data = { ...loadData([...action.data.settings]) };
					//d.processing = task;
					d.default = loadTask(data, task);
					d.connect = false;
					d.tradeConnect = false;
					for (
						let index = 0;
						index < action.data.settings.length;
						index++
					) {
						const element = action.data.settings[index];
						if (element.field === "tradeConnect") {
							const tC = JSON.parse(element.value);
							if (tC.length > 0) {
								if (tC.indexOf(d.snipe_wallet) !== -1) {
									d.connect = true;
									d.tradeConnect = true;
									tNum = tC.length;
								}
							}
						}
					}

					d.tradeNum = tNum;
					dataW[d.snipe_wallet] = d;
				} else {
					dataW[d.snipe_wallet].balance = d.balance;
					dataW[d.snipe_wallet].approve = { ...d.approve };
				}
			}

			return { ...dataW };

		case "setDefault":
			let dataD = { ...state };

			for (
				let index = 0;
				index < Object.keys(action.data).length;
				index++
			) {
				const key = Object.keys(action.data)[index];
				const value = Object.values(action.data)[index];
				dataD[action.wallet].default[key] = value;
			}

			return { ...dataD };
		case "setBalance":
			let dataB = { ...state };
			for (let index = 0; index < action.data.length; index++) {
				const wallet = action.data[index];
				dataB[wallet.snipe_wallet].balance = wallet.balance;
				dataB[wallet.snipe_wallet].rsBalance = wallet.rsBalance;
			}
			return { ...dataB };
		case "setTokens":
			let dataQ = { ...state };

			for (
				let index = 0;
				index < Object.keys(action.data).length;
				index++
			) {
				const key = Object.keys(action.data)[index];
				let value = Object.values(action.data)[index];

				dataQ[key] = { ...dataQ[key], tokens: value };
			}

			//dataQ[action.wallet].tokens = action.data;
			return { ...dataQ };
		case "setTasks":
			let dataX = { ...state };
			if (Object.keys(action.data).length > 0) {
				for (
					let index = 0;
					index < Object.keys(action.data).length;
					index++
				) {
					const key = Object.keys(action.data)[index];
					let value = Object.values(action.data)[index];

					dataX[key] = { ...dataX[key], tasks: value };
				}
			} else {
				for (
					let index = 0;
					index < Object.keys(dataX).length;
					index++
				) {
					const key = Object.keys(dataX)[index];

					dataX[key] = { ...dataX[key], tasks: [] };
				}
			}

			return { ...dataX };
		case "setAutoSnipe":
			let dataA = { ...state };
			if (Object.keys(action.data).length > 0) {
				for (
					let index = 0;
					index < Object.keys(action.data).length;
					index++
				) {
					const key = Object.keys(action.data)[index];
					let value = Object.values(action.data)[index];

					dataA[key] = { ...dataA[key], autoSnipeTasks: value };
				}
			} else {
				for (
					let index = 0;
					index < Object.keys(dataA).length;
					index++
				) {
					const key = Object.keys(dataA)[index];

					dataA[key] = { ...dataA[key], autoSnipeTasks: [] };
				}
			}

			return { ...dataA };
		case "setConnect":
			let dataC = { ...state };
			dataC[action.wallet].connect = action.connect;
			return { ...dataC };
		case "setTradeConnect":
			let dataT = { ...state };
			let tradeN = 0;
			for (let index = 0; index < Object.keys(dataT).length; index++) {
				const wallet = Object.keys(dataT)[index];
				if (!!dataT[wallet].connect) {
					dataT[wallet].tradeConnect = true;
					tradeN++;
				}
			}
			for (let index = 0; index < Object.keys(dataT).length; index++) {
				const wallet = Object.keys(dataT)[index];
				if (!!dataT[wallet].tradeConnect) {
					dataT[wallet].tradeNum = tradeN;
				}
			}
			return { ...dataT };
		case "resetTradeConnect":
			let dataRT = { ...state };
			for (let index = 0; index < Object.keys(dataRT).length; index++) {
				const wallet = Object.keys(dataRT)[index];
				dataRT[wallet].tradeConnect = false;
				dataRT[wallet].connect = false;
				dataRT[wallet].tradeNum = 0;
			}
			return { ...dataRT };
		case "reset":
			return {};
		default:
			throw new Error();
	}
};
const loadData = (data) => {
	let d = { ...ethDefault };
	for (let index = 0; index < data.length; index++) {
		const element = data[index];
		//if (element.field === "network") d.network = element.value;
		if (element.field === "value")
			if (!!element.value) d.value = element.value;
		if (element.field === "buySlippage")
			if (!!element.value) d.buySlippage = element.value;
		if (element.field === "multiple")
			if (!!element.value) d.multiple = element.value;
		if (element.field === "buyGasLimit")
			if (!!element.value) d.buyGasLimit = element.value;
		if (element.field === "buyGasPrice")
			if (!!element.value) d.buyGasPrice = element.value;
		if (element.field === "buymaxFeePerGas")
			if (!!element.value) d.buymaxFeePerGas = element.value;
		if (element.field === "buymaxPriorityFeePerGas")
			if (!!element.value) d.buymaxPriorityFeePerGas = element.value;
		if (element.field === "antiMEV")
			d.antiMEV =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "antiRevert")
			d.antiRevert =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "onlyOwner")
			d.onlyOwner =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "dynamicNonce")
			d.dynamicNonce =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "copyMultipleBuy")
			d.copyMultipleBuy =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "manualGasBuy")
			d.manualGasBuy =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "autoApprove")
			d.autoApprove =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "tauto_approve")
			d.tauto_approve =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "aauto_approve")
			d.aauto_approve =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "sellSlippage")
			if (!!element.value) d.sellSlippage = element.value;
		if (element.field === "multipleSell")
			if (!!element.value) d.multipleSell = element.value;
		if (element.field === "sellGasLimit")
			if (!!element.value) d.sellGasLimit = element.value;
		if (element.field === "sellGasPrice")
			if (!!element.value) d.sellGasPrice = element.value;
		if (element.field === "sellmaxFeePerGas")
			if (!!element.value) d.sellmaxFeePerGas = element.value;
		if (element.field === "sellmaxPriorityFeePerGas")
			if (!!element.value) d.sellmaxPriorityFeePerGas = element.value;
		if (element.field === "copyMultipleSell")
			d.copyMultipleSell =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "antiMEVSell")
			d.antiMEVSell =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "manualGasSell")
			d.manualGasSell =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "mev")
			d.mev = !!element.value && element.value === "true" ? true : false;
		if (element.field === "buy_anyway")
			d.buy_anyway =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "multi_bribe")
			d.multi_bribe =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "mevGas")
			if (!!element.value) d.mevGas = element.value;
		if (element.field === "mevEth")
			if (!!element.value) d.mevEth = element.value;

		// trade data
		if (element.field === "tvalue")
			if (!!element.value) d.tvalue = element.value;
		if (element.field === "tslippage")
			if (!!element.value) d.tslippage = element.value;
		if (element.field === "tmultiple")
			if (!!element.value) d.tmultiple = element.value;
		if (element.field === "tGasLimit")
			if (!!element.value) d.tGasLimit = element.value;
		if (element.field === "tGasPrice")
			if (!!element.value) d.tGasPrice = element.value;
		if (element.field === "tmaxFeePerGas")
			if (!!element.value) d.tmaxFeePerGas = element.value;
		if (element.field === "tmaxPriorityFeePerGas")
			if (!!element.value) d.tmaxPriorityFeePerGas = element.value;
		if (element.field === "tantiMEV")
			d.tantiMEV =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "tmanualGas")
			d.tmanualGas =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "tint")
			d.tint = !!element.value && element.value === "true" ? true : false;
		if (element.field === "stopLoss")
			d.stopLoss =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "cond_tsl")
			d.cond_tsl =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "tpair")
			if (!!element.value) d.tpair = element.value;
		if (element.field === "ttype")
			if (!!element.value) d.ttype = element.value;
		if (element.field === "ttask")
			if (!!element.value) d.ttask = element.value;
		if (element.field === "limit_protection")
			if (!!element.value) d.limit_protection = element.value;

		if (element.field === "stslippage")
			if (!!element.value) d.stslippage = element.value;
		if (element.field === "stmultiple")
			if (!!element.value) d.stmultiple = element.value;
		if (element.field === "stGasLimit")
			if (!!element.value) d.stGasLimit = element.value;
		if (element.field === "stGasPrice")
			if (!!element.value) d.stGasPrice = element.value;
		if (element.field === "stmaxFeePerGas")
			if (!!element.value) d.stmaxFeePerGas = element.value;
		if (element.field === "stmaxPriorityFeePerGas")
			if (!!element.value) d.stmaxPriorityFeePerGas = element.value;
		if (element.field === "stantiMEV")
			d.stantiMEV =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "stmanualGas")
			d.stmanualGas =
				!!element.value && element.value === "true" ? true : false;

		if (element.field === "fomo")
			if (!!element.value) d.fomo = element.value;
		if (element.field === "maxFomoTx")
			if (!!element.value) d.maxFomoTx = element.value;
		if (element.field === "fomoValue")
			if (!!element.value) d.fomoValue = element.value;

		if (element.field === "tperc") {
			if (!!element.value) {
				d.showNum = JSON.parse(element.value);
			}
		}

		//auto Trades
		if (element.field === "aSignals")
			if (!!element.value) d.aSignals = JSON.parse(element.value);
		if (element.field === "aVolume")
			if (!!element.value) d.aVolume = element.value;
		if (element.field === "aName")
			if (!!element.value) d.aName = element.value;
		if (element.field === "aSymbol")
			if (!!element.value) d.aSymbol = element.value;
		if (element.field === "aCheckSum")
			if (!!element.value) d.aCheckSum = element.value;
		if (element.field === "aMaxLimit")
			if (!!element.value) d.aMaxLimit = element.value;
		if (element.field === "aBuyTax")
			d.aBuyTax =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "aBuyTaxNum")
			if (!!element.value) d.aBuyTaxNum = JSON.parse(element.value);
		if (element.field === "aSellTax")
			d.aSellTax =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "aSellTaxNum")
			if (!!element.value) d.aSellTaxNum = JSON.parse(element.value);
		if (element.field === "aScams")
			d.aScams =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "aScamsNum")
			if (!!element.value) d.aScamsNum = JSON.parse(element.value);
		if (element.field === "arouter")
			if (!!element.value) d.arouter = element.value;
		if (element.field === "apair")
			if (!!element.value) d.apair = element.value;
		if (element.field === "aslippage")
			if (!!element.value) d.aslippage = element.value;
		if (element.field === "amanualGas")
			d.amanualGas =
				!!element.value && element.value === "true" ? true : false;
		if (element.field === "amultiple")
			if (!!element.value) d.amultiple = element.value;
		if (element.field === "aGasLimit")
			if (!!element.value) d.aGasLimit = element.value;
		if (element.field === "aGasPrice")
			if (!!element.value) d.aGasPrice = element.value;
		if (element.field === "amaxFeePerGas")
			if (!!element.value) d.amaxFeePerGas = element.value;
		if (element.field === "amaxPriorityFeePerGas")
			if (!!element.value) d.amaxPriorityFeePerGas = element.value;
		if (element.field === "anetwork")
			if (!!element.value) d.anetwork = element.value;
		if (element.field === "aswap")
			if (!!element.value) d.aswap = element.value;
		if (element.field === "afee")
			if (!!element.value) d.afee = element.value;
		if (element.field === "aantiMEV")
			d.aantiMEV =
				!!element.value && element.value === "true" ? true : false;
	}
	return d;
};
const getMethods = (a) => {
	if (!!!a) return a;
	return JSON.parse(a.replace("{", "[").replace("}", "]"));
};

const loadTask = (d, task) => {
	for (let index = 0; index < task.length; index++) {
		const t = task[index];
		if (t.type === "BUY_ETH" || t.type === "BUY_TOKEN") {
			d.network = t.network;
			d.contract = t.contract;
			d.amount = t.amount;
			d.antiMEV = t.antimev;
			d.delayType = t.delay_type;
			d.delayValue = t.delay_value;
			d.copyMultipleBuy = t.gas_copy_multiple;
			d.buyGasLimit = t.gas_gaslimit;
			d.buyGasPrice = t.gas_gasprice;
			d.buymaxFeePerGas = t.gas_maxfeepergas;
			d.buymaxPriorityFeePerGas = t.gas_priorityfeepergas;
			d.multiple = t.gas_multiple;
			d.pair = t.pair;
			d.router = t.router;
			d.swap = t.swap;
			d.buySlippage = t.slippage;
			d.value = t.value;
			d.dynamicNonce = !t.prevgenerate;
			d.allMethods = getMethods(t.methodids);
			d.methods = getMethods(t.methodids);
			d.buyToken = !!t.amount;
			d.processing = true;
			d.fee = !!t.fee ? t.fee.toString() : "";
			d.mev = !!t.mev;
			d.mevGas = t.mev_gas;
			d.mevEth = t.tip_eth;
			d.buy_anyway = !!t.buy_anyway;
			d.multi_bribe = !!t.multi_bribe;
		} else if (t.type === "SELL") {
			d.network = t.network;
			d.contract = t.contract;
			d.sellAmount = t.amount;
			d.antiMEVSell = t.antimev;
			d.fee = !!t.fee ? t.fee.toString() : "";
			d.copyMultipleSell = t.gas_copy_multiple;
			d.sellGasLimit = t.gas_gaslimit;
			d.sellGasPrice = t.gas_gasprice;
			d.sellmaxFeePerGas = t.gas_maxfeepergas;
			d.sellmaxPriorityFeePerGas = t.gas_priorityfeepergas;
			d.multipleSell = t.gas_multiple;
			d.pair = t.pair;
			d.router = t.router;
			d.swap = t.swap;
			d.sellSlippage = t.slippage;
			d.methodsSell = getMethods(t.methodids);
		} else {
			//stopBuy();
		}
	}
	return { ...d };
};

export { reducerWallets as default };

import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Tooltip_ } from "./Tooltip";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});

const DialogSellPanel = ({ open, setOpen, fileds, setVar }) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ buyPanelDialog"
				>
					<DialogContent>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Slippage"
									id="sellSlippage"
									value={fileds.sellSlippage}
									onChange={(e) =>
										setVar({
											sellSlippage: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="Slippage is the difference between the expected price of an order and the price when the order actually executes. By default set to 100% means you accept any amount of token. For new launches, it is common to have 100% slippage, but you can change it to suit your risk profile"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="GasLimit"
									id="sellGasLimit"
									value={fileds.sellGasLimit}
									onChange={(e) =>
										setVar({
											sellGasLimit: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="'Gas limit' is the maximum amount of work you're estimating a validator will do on a particular transaction. By default set 521k, but you can set it as you would like. Do not set it too low, as transaction will not be processed and the transaction will fail."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="buytokenLabel"
									control={
										<Switch
											color="primary"
											checked={fileds.manualGasSell}
											onChange={(e) =>
												setVar({
													manualGasSell:
														e.target.checked,
												})
											}
										/>
									}
									label="Manual Gas"
									labelPlacement="top"
								/>
								<Tooltip_
									title=" Use manual gas if you want to personalize your gas settings. In case you want to use auto set to autogas settings."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							{!fileds.manualGasSell && (
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="Gas %"
										id="multipleSell"
										value={fileds.multipleSell}
										onChange={(e) =>
											setVar({
												multipleSell: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas%  Using the gas multiplier, you can increase the actual gas price value you want to pay to process your swap transaction with an added percentage amount."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							)}
						</Grid>
						{fileds.manualGasSell && (
							<Grid container spacing={1} className="dialog_form">
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="GasPrice"
										id="sellGasPrice"
										value={fileds.sellGasPrice}
										onChange={(e) =>
											setVar({
												sellGasPrice: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas price   General reference for approximate transaction fees. By default, the bot runs with a Gas multiplier so it`s always using the estimated gas of the current block. You can increase this gas further by adding additional extra with % to increase the probability of faster transaction execution."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxFeePerGas"
										id="sellmaxFeePerGas"
										value={fileds.sellmaxFeePerGas}
										onChange={(e) =>
											setVar({
												sellmaxFeePerGas:
													e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxfeepergas  EIP-1559 Transaction. This represents the maximum amount of gas fee a user is willing to pay for a transaction. Set the maximum you are willing to spend for the transaction. With that, you leave room for your gas settings in case the gas spikes suddenly."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxPriorityFeePerGas"
										id="sellmaxPriorityFeePerGas"
										value={fileds.sellmaxPriorityFeePerGas}
										onChange={(e) =>
											setVar({
												sellmaxPriorityFeePerGas:
													e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxpriority feepergas  Miners can be compensated for the computational work by receiving a 'tip'. When you submit a transaction you will also provide this 'tip' in the maxPriorityFeePerGas field. Gas price is the primary method of getting a fast swap execution. Typically maxpriority is used for lower-gas low-priority transactions where immediate execution is not required, but tipping the miner directly increases the probability the swap is still processed."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>
						)}
						{/*<Grid container spacing={1} className="dialog_form">
							<Grid item xs={12} className="contentCenter">
								<CssTextField
									className="formInput"
									label="MethodIds"
									id="methodsSell"
									value={fileds.methodsSell}
									onChange={(e) =>
										fileds.setMethodsSell(e.target.value)
									}
								/>
								<InfoIcon className="infoIcon" />
							</Grid>
								</Grid>*/}
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="copyMultipleSell"
									control={
										<Switch
											color="primary"
											checked={fileds.copyMultipleSell}
											onChange={(e) => {
												if (e.target.checked) {
													setVar({
														manualGasSell: false,
														copyMultipleSell:
															e.target.checked,
													});
												} else {
													setVar({
														copyMultipleSell:
															e.target.checked,
													});
												}
											}}
										/>
									}
									label="Copy Gwei +gas"
									labelPlacement="top"
								/>
								<InfoIcon className="infoIcon" />
							</Grid>
							{/*<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="antiMEV"
									control={
										<Switch
											color="primary"
											id="antiMEVSell"
											checked={fileds.antiMEVSell}
											onChange={(e) =>
												fileds.setAntiMEVSell(
													e.target.checked
												)
											}
										/>
									}
									label="AntiMEV"
									labelPlacement="top"
								/>
								<Tooltip_
									title="Enabling Anti-Mev changes the node used from a shared private node to a public AntiMev node, sacrificing a little speed, but avoiding the risk of being front-run by MevBot sandwich bots. Typical Mevbots are attractive to zero-tax token launches."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
								</Grid>*/}
						</Grid>
					</DialogContent>
					<DialogActions>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogSellPanel;

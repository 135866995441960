export const initUserSettings = {};

const reducerUserSettings = (state, action) => {
	switch (action.type) {
		case "set":
			return { ...action.data };
		case "reset":
			return initUserSettings;
		default:
			throw new Error();
	}
};

export { reducerUserSettings as default };

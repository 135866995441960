import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { Tooltip_ } from "./Tooltip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
const _delay = [
	{
		value: "BLOCK",
		name: "Block",
	},
	{
		value: "MS",
		name: "ms",
	},
];
const _swap_ = [
	{
		value: "V2",
		name: "V2",
		router: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
	},
	{
		value: "V3",
		name: "V3",
		router: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
	},
];
const _fee_ = [
	{
		value: "10000",
		name: "1%",
	},
	{
		value: "3000",
		name: "0.3%",
	},
	{
		value: "500",
		name: "0.05%",
	},
];
const DialogBuyPanel = ({ open, setOpen, fileds, setVar }) => {
	const handleClose = () => {
		setOpen(false);
	};

	const handleSwap = (value) => {
		if (!!!value) {
			setVar({
				router: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
				swap: "",
				fee: "",
			});
			return true;
		}
		const ff = _swap_.find((f) => f.value === value);
		if (!!ff) {
			setVar({
				router: ff.router,
				swap: ff.value,
				fee: ff.value === "V2" ? "" : fileds.fee,
			});
		} else {
			setVar({
				router: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
				swap: "",
				fee: "",
			});
		}
	};

	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ buyPanelDialog"
				>
					<DialogContent>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Slippage"
									id="buySlippage"
									value={fileds.buySlippage}
									onChange={(e) =>
										setVar({
											buySlippage: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="Slippage is the difference between the expected price of an order and the price when the order actually executes. By default set to 100% means you accept any amount of token. For new launches, it is common to have 100% slippage, but you can change it to suit your risk profile"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="GasLimit"
									id="buyGasLimit"
									value={fileds.buyGasLimit}
									onChange={(e) =>
										setVar({
											buyGasLimit: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="'Gas limit' is the maximum amount of work you're estimating a validator will do on a particular transaction. By default set 521k, but you can set it as you would like. Do not set it too low, as transaction will not be processed and the transaction will fail."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="buytokenLabel"
									control={
										<Switch
											color="primary"
											checked={fileds.manualGasBuy}
											onChange={(e) =>
												setVar({
													manualGasBuy:
														e.target.checked,
												})
											}
										/>
									}
									label="Manual Gas"
									labelPlacement="top"
								/>
								<Tooltip_
									title=" Use manual gas if you want to personalize your gas settings. In case you want to use auto set to autogas settings."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							{!fileds.manualGasBuy && (
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="Gas %"
										id="outlined-size-small"
										value={fileds.multiple}
										onChange={(e) =>
											setVar({
												multiple: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas%  Using the gas multiplier, you can increase the actual gas price value you want to pay to process your swap transaction with an added percentage amount."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							)}
						</Grid>
						{fileds.manualGasBuy && (
							<Grid container spacing={1} className="dialog_form">
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="GasPrice"
										id="buyGasPrice"
										value={fileds.buyGasPrice}
										onChange={(e) =>
											setVar({
												buyGasPrice: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas price   General reference for approximate transaction fees. By default, the bot runs with a Gas multiplier so it`s always using the estimated gas of the current block. You can increase this gas further by adding additional extra with % to increase the probability of faster transaction execution."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxFeePerGas"
										id="buymaxFeePerGas"
										value={fileds.buymaxFeePerGas}
										onChange={(e) =>
											setVar({
												buymaxFeePerGas: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxfeepergas  EIP-1559 Transaction. This represents the maximum amount of gas fee a user is willing to pay for a transaction. Set the maximum you are willing to spend for the transaction. With that, you leave room for your gas settings in case the gas spikes suddenly."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxPriorityFeePerGas"
										id="buymaxPriorityFeePerGas"
										value={fileds.buymaxPriorityFeePerGas}
										onChange={(e) =>
											setVar({
												buymaxPriorityFeePerGas:
													e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxpriority feepergas  Miners can be compensated for the computational work by receiving a 'tip'. When you submit a transaction you will also provide this 'tip' in the maxPriorityFeePerGas field. Gas price is the primary method of getting a fast swap execution. Typically maxpriority is used for lower-gas low-priority transactions where immediate execution is not required, but tipping the miner directly increases the probability the swap is still processed."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>
						)}
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="delayTypeLabel"
									control={
										<Paper
											elevation={3}
											sx={{
												p: "2px 4px",
												display: "flex",
												alignItems: "center",
											}}
											className="selectField_form"
										>
											<Select
												id="network-select"
												value={fileds.swap}
												onChange={(event) =>
													handleSwap(
														event.target.value
													)
												}
												label="Uniswap Router"
											>
												<MenuItem value="">-</MenuItem>
												{_swap_.map((m) => (
													<MenuItem
														key={m.value}
														value={m.value}
													>
														{m.name}
													</MenuItem>
												))}
											</Select>
										</Paper>
									}
									label="Uniswap Router"
									labelPlacement="top"
								/>

								<Tooltip_
									title="If the contract address is not using the default V2 Router, and using a V3 Uniswap Router, select V3 here and the associated pool Fee the V3 Router is using, in the box to the right (1 of 3 options)."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>

							<Grid item xs={6} className="contentCenter">
								{fileds.swap === "V3" && (
									<>
										<FormControlLabel
											className="delayTypeLabel"
											control={
												<Paper
													elevation={3}
													sx={{
														p: "2px 4px",
														display: "flex",
														alignItems: "center",
													}}
													className="selectField_form"
												>
													<Select
														id="network-select"
														value={fileds.fee}
														onChange={(event) =>
															setVar({
																fee: event
																	.target
																	.value,
															})
														}
														label="V3 Router Fee"
													>
														{_fee_.map((m) => (
															<MenuItem
																key={m.value}
																value={m.value}
															>
																{m.name}
															</MenuItem>
														))}
													</Select>
												</Paper>
											}
											label="V3 Router Fee"
											labelPlacement="top"
										/>

										<Tooltip_
											title="Uniswap v3  having multiples swapping fee. Set the correct fee accordingly to the correct v3 pool. Make sure you are selecting the correct before you are buying any token."
											enterTouchDelay={0}
										>
											<InfoIcon className="infoIcon" />
										</Tooltip_>
									</>
								)}
							</Grid>
						</Grid>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="delayTypeLabel"
									control={
										<Paper
											elevation={3}
											sx={{
												p: "2px 4px",
												display: "flex",
												alignItems: "center",
											}}
											className="selectField_form"
										>
											<Select
												id="network-select"
												value={fileds.delayType}
												onChange={(event) =>
													setVar({
														delayType:
															event.target.value,
													})
												}
												label="MaxPriorityFeePerGas"
											>
												{_delay.map((m) => (
													<MenuItem
														key={m.value}
														value={m.value}
													>
														{m.name}
													</MenuItem>
												))}
											</Select>
										</Paper>
									}
									label="Delay type"
									labelPlacement="top"
								/>

								<Tooltip_
									title="You can set delay sending your swap transaction, but block delay or millisecond delay. In case you want to skip particular blocks or set some time to delay your transaction use it accordingly. You might want to delay the block if the first 3 blocks have very high buy taxes, for example."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Delay Value"
									id="delayValue"
									value={fileds.delayValue}
									onChange={(e) =>
										setVar({
											delayValue: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="Delay value  If you are using block delay set the number of blocks you want to skip. Eg:1,2,3 If you are using block ms, set the number of milliseconds you want to delay your transaction."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="dynamicNonce"
									control={
										<Switch
											color="primary"
											checked={fileds.dynamicNonce}
											onChange={(e) =>
												setVar({
													dynamicNonce:
														e.target.checked,
												})
											}
										/>
									}
									label="Dynamic Nonce"
									labelPlacement="top"
								/>
								<Tooltip_
									title="Dynamic Nonce  Each transaction's nonce is an identifier that ensures your transactions are processed by the network in the correct order. Enable Dynamic Nonce if you performing more than one trade from one wallet at one time. This avoids the 'Nonce too low' error message."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="autoApprove"
									control={
										<Switch
											color="primary"
											checked={fileds.autoApprove}
											onChange={(e) =>
												setVar({
													autoApprove:
														e.target.checked,
												})
											}
										/>
									}
									label="Auto Approve"
									labelPlacement="top"
								/>
								<Tooltip_
									title="Auto Approve [0N/OFF]"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>

							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="antiRevert"
									control={
										<Switch
											color="primary"
											checked={fileds.antiRevert}
											onChange={(e) =>
												setVar({
													antiRevert:
														e.target.checked,
												})
											}
										/>
									}
									label="Anti Revert"
									labelPlacement="top"
								/>
								<Tooltip_
									title="When selecting an Exact Token amount (% ), and the ETH value you have set for the transaction is not large enough, your swap will always revert with a failed transaction. Upon receiving a reverted transaction, enabling Anti-Revert will automatically send a 2nd follow-up transaction without a specified Token amount with your set ETH Value, offering the ReadySwap user a 2nd opportunity to enter a successful swap."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="onlyOwner"
									control={
										<Switch
											color="primary"
											checked={fileds.onlyOwner}
											onChange={(e) =>
												setVar({
													onlyOwner: e.target.checked,
												})
											}
										/>
									}
									label="Dev scan"
									labelPlacement="top"
								/>
								<Tooltip_
									title="The following function enable just those  methods what`s are called by the token dev. This is great when you want to avoid others to interact with the contract to create wrong pool or add fake methods."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={6} className="contentCenter">
								<FormControlLabel
									className="onlyManGas"
									control={
										<Switch
											color="primary"
											checked={fileds.onlyManGas}
											onChange={(e) => {
												if (e.target.checked) {
													setVar({
														manualGasBuy: true,
													});
												}
												setVar({
													onlyManGas:
														e.target.checked,
												});
											}}
										/>
									}
									label="Only Manual Gas"
									labelPlacement="top"
								/>
								<Tooltip_
									title="With this button you will be using your manual gas settings only. Even if you are method sniping!"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Proxy Contract"
									id="proxy_contract"
									value={fileds.proxy_contract}
									onChange={(e) =>
										setVar({
											proxy_contract: e.target.value,
										})
									}
								/>
								<Tooltip_
									title="Use this option when your target token is managed via 3rd party/external contract."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogBuyPanel;

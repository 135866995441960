const CryptoJS = require("crypto-js");
const BigNumber = require("bignumber.js");

BigNumber.config({ EXPONENTIAL_AT: 1e9 });
const key = process.env.REACT_APP_SECRET_KEY;
const _network = {
	ETH: "https://etherscan.io/",
	BSC: "https://bscscan.com/",
};

const getSortCode = (code) => {
	if (!!code) {
		code = code.toLowerCase();
		return `${code[0]}${code[1]}${code[2]}${code[3]}${code[4]}${
			code[5]
		}...${code[code.length - 4]}${code[code.length - 3]}${
			code[code.length - 2]
		}${code[code.length - 1]}`;
	}
	return "";
};

const callLink = (network, address) => {
	window.open(
		`${_network[network]}address/${address.toLowerCase()}`,
		"_blank"
	);
};
const callTXLink = (network, address) => {
	window.open(`${_network[network]}tx/${address.toLowerCase()}`, "_blank");
};
const callNewSite = (link) => {
	window.open(`${link}`, "_blank");
};

const sendToken = (token) => {
	localStorage.setItem("CREDENTIALS_SHARING_READYSWAP", token);
	localStorage.removeItem("CREDENTIALS_SHARING_READYSWAP");
};
const removeToken = () => {
	localStorage.setItem("REMOVE_SHARING_READYSWAP", Date.now().toString());
	localStorage.removeItem("REMOVE_SHARING_READYSWAP");
};

const regex_address = new RegExp(/^0x[A-F0-9]{40}$/, "i");

const isAddress = (input) => {
	return regex_address.test(input);
};

const createDefault = (wallet, data) => {
	let tData = localStorage.getItem("tradedata");
	if (!!!tData) {
		tData = {};
		tData[wallet] = { ...data };
	} else {
		try {
			tData = decodedData(tData);
			tData = isJsonString(tData);
			if (!!!tData[wallet]) {
				tData[wallet] = { ...data };
			} else {
				tData[wallet] = { ...tData[wallet], ...data };
				for (let index = 0; index < Object.keys(data).length; index++) {
					const element = Object.keys(data)[index];
					const ff = Object.keys(tData[wallet]).find(
						(f) => f === element
					);
					if (!ff) {
						tData[wallet][element] = data[element];
					}
				}
			}
		} catch (error) {
			tData = {};
			tData[wallet] = { ...data };
		}
	}

	tData = JSON.stringify(tData);
	tData = encodedData(tData);
	localStorage.setItem("tradedata", tData);
};

const setDefault = (wallet, data) => {
	let tData = localStorage.getItem("tradedata");

	if (!!!tData) {
		tData = {};
		tData[wallet] = { ...data };
	} else {
		try {
			tData = decodedData(tData);
			tData = isJsonString(tData);
			if (!!tData[wallet]) {
				tData[wallet] = { ...tData[wallet], ...data };
			} else {
				tData[wallet] = { ...data };
			}
		} catch (error) {
			tData = {};
			tData[wallet] = { ...data };
		}
	}

	tData = JSON.stringify(tData);
	tData = encodedData(tData);
	localStorage.setItem("tradedata", tData);
};
const getDefault = () => {
	let tData = localStorage.getItem("tradedata");
	tData = decodedData(tData);
	tData = isJsonString(tData);
	return tData;
};
const encodedData = (data) => {
	const res = CryptoJS.Rabbit.encrypt(data, key).toString();
	return res;
};
const decodedData = (data) => {
	const bytes = CryptoJS.Rabbit.decrypt(data, key);

	const decryptedData = isJsonString(bytes.toString(CryptoJS.enc.Utf8));
	return decryptedData;
};
const isJsonString = (str) => {
	try {
		return JSON.parse(str);
	} catch (e) {
		return str;
	}
};
const sleepMs = (ms) => {
	return new Promise((resolve) => setTimeout(resolve, ms));
};

const percValue = (num, perc) => {
	try {
		const res = new BigNumber(num)
			.multipliedBy(parseFloat(perc) / 100)
			.plus(num);
		return res.toString();
	} catch (error) {}
};
const valuePerc = (num1, num2) => {
	try {
		const res = new BigNumber(num2)
			.minus(new BigNumber(num1))
			.dividedBy(new BigNumber(num1))
			.multipliedBy(100);

		return res.toFixed(2);
	} catch (error) {}
};

const checkGASBalance = (data, gas) => {
	try {
		const balance = new BigNumber(data.balance).multipliedBy(10 ** 18);
		const value = new BigNumber(data.default.value).multipliedBy(10 ** 18);
		const mevEth = new BigNumber(data.default.mevEth).multipliedBy(
			10 ** 18
		);
		const limit = new BigNumber(data.default.buyGasLimit);

		let gasP = new BigNumber(gas).multipliedBy(1000000000);

		const n = gasP.multipliedBy(limit).plus(value).plus(mevEth);

		if (balance.isLessThanOrEqualTo(n)) {
			return false;
		} else {
			return true;
		}
	} catch (error) {
		//console.log("error", error);
		return false;
	}
};
const checkRSBalance = (data) => {
	try {
		if (data.rsBalance === 0) return false;

		const balance = new BigNumber(data.rsBalance).multipliedBy(10 ** 18);
		const value = new BigNumber(data.default.value).multipliedBy(10 ** 18);
		const mevEth = new BigNumber(data.default.mevEth).multipliedBy(
			10 ** 18
		);

		const n = value.plus(mevEth);

		if (balance.isLessThanOrEqualTo(n)) {
			return false;
		} else {
			return true;
		}
	} catch (error) {
		//console.log("error", error);
		return false;
	}
};
const checkInvestment = (data) => {
	try {
		const value = parseFloat(data.default.value);
		const amount = parseFloat(data.default.amount);
		if (amount / 10 <= value) {
			return true;
		} else {
			return false;
		}
	} catch (error) {
		return false;
	}
};
const readableNumber = (num) => {
	let a = num.toString();
	if (a.indexOf("0.00000") !== -1) {
		const s = a.lastIndexOf("00000") + 5;

		let w = `0.00...${a.slice(s, s + 3)}`;
		return w;
	}
	const t = a.slice(0, 9);
	return t;
};
const priceFormat = (num) => {
	if (parseFloat(num) > 0) {
		if (num[1] !== ".") return "$" + num;
		let f = true;
		let n = 0;
		let m = -1;
		for (let index = 2; index < num.length; index++) {
			const element = num[index];
			if (element === "0" && f) {
				n++;
			} else {
				f = false;
				if (m === -1) m = index;
			}
		}
		if (n > 1 && m !== -1) {
			return (
				<span>
					{`$${num[0]}${num[1]}${num[2]}`}
					<sub className="lowindex">{n - 1}</sub>
					{`${num[m]}${!!num[m + 1] ? num[m + 1] : ""}${
						!!num[m + 2] ? num[m + 2] : ""
					}${!!num[m + 3] ? num[m + 3] : ""}`}
				</span>
			);
		} else {
			return "$" + num.slice(0, 7);
		}
	} else {
		return num;
	}
};
const numberFormat = (num, text = "") => {
	if (parseFloat(num) > 0) {
		if (num[1] !== ".") return text + num;
		let f = true;
		let n = 0;
		let m = -1;
		for (let index = 2; index < num.length; index++) {
			const element = num[index];
			if (element === "0" && f) {
				n++;
			} else {
				f = false;
				if (m === -1) m = index;
			}
		}
		if (n > 1 && m !== -1) {
			return (
				<span>
					{`${text}${num[0]}${num[1]}${num[2]}`}
					<sub className="lowindex">{n - 1}</sub>
					{`${num[m]}${!!num[m + 1] ? num[m + 1] : ""}${
						!!num[m + 2] ? num[m + 2] : ""
					}${!!num[m + 3] ? num[m + 3] : ""}`}
				</span>
			);
		} else {
			return text + num.slice(0, 7);
		}
	} else {
		return text + num;
	}
};
export {
	getSortCode,
	callLink,
	callTXLink,
	callNewSite,
	sendToken,
	removeToken,
	isAddress,
	createDefault,
	setDefault,
	getDefault,
	sleepMs,
	checkGASBalance,
	checkInvestment,
	readableNumber,
	priceFormat,
	percValue,
	valuePerc,
	numberFormat,
	checkRSBalance,
};

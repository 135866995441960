import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const DialogTOS = ({ open, setOpen }) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{open && (
				<Dialog open={open} onClose={handleClose} className="dialog_">
					<DialogContent>
						<div className="alertHeader">
							<h4 className="dialogHeader">Terms of Service</h4>
						</div>
						<p>
							These terms of service constitute the entire
							agreement (hereinafter “the Agreement”) subject to
							which you may access and make use the Products
							offered by the TUF Team (hereinafter referred to as
							“TUF”, “TUF Team”, “we”, “us” or “our”). The
							Products include but are not limited to the TUF
							Sniper Bot, TUFExtractool or TUFEXT
							(https://tufext.com/) and ReadySwap
							(https://readyswap.app/). By accessing or using our
							Products you are representing and holding yourself
							out as being competent to contract with us in your
							respective legal jurisdiction and you undertake and
							affirm that you have read carefully, understood and
							accept this Agreement in its entirety. In case you
							disagree with the terms of this Agreement, you are
							not permitted to access or use our Products.{" "}
						</p>
						<b>Restrictions on Use </b>
						<p>
							You agree to use the services of the Products,
							including but not limited to TUFEXT, for your own
							private non-commercial use and benefit and not for
							resale or transfer to or for the benefit of any
							other person, individual or entity. You hereby
							undertake not to copy, reverse engineer,
							disassemble, distribute, publish, display, or create
							derivative works arising from or on the basis of or
							out of the information provided to you by the
							Products. You agree and undertake not to share,
							distribute or in any way convey the information
							provided by the Products in any manner which would
							compete with TUF or the Products.{" "}
						</p>
						<p>
							You are prohibited from interfering or taking steps
							to interfere with or otherwise hinder, limit, harm
							or disrupt the proper working of the Products, any
							activities conducted on or through the Products or
							any servers or networks connected to the Products.
							You shall not violate the security of the Products
							or attempt to gain unauthorized access to the
							Products. You shall not use or attempt to use any
							“scraper,” “robot,” “bot,” or any other automated
							program, tool, algorithm, process or methodology to
							access, acquire, copy, or monitor any portion of the
							Products, any data or content found on or accessed
							through the Products.{" "}
						</p>
						<p>
							You acquire absolutely no rights or licenses in or
							to the Products and information provided by the
							Products other than the limited right to utilize the
							Products in accordance with this Agreement.{" "}
						</p>
						<b>Disclaimer</b>
						<p>
							The Products are provided by TUF on an ‘as-is’,
							‘where-is’ basis without any warranties or
							representations of any nature whatsoever, either
							express or implied.{" "}
						</p>
						<p>
							The Products provide a web or mobile-based mechanism
							of access to decentralized protocols on various
							public blockchains that allows you to trade certain
							compatible digital assets. TUF does not control or
							operate any version of such protocols or any
							blockchain. By using the Products, you understand
							that you are not buying or selling digital assets
							from us and that we do not operate any liquidity
							pools on any blockchain/protocol or control trade
							execution on such blockchain/protocol.{" "}
						</p>
						<p>
							You understand that to access the Products, you may
							be using non-custodial wallet softwares which are
							governed by your independent agreements with
							providers of such wallet softwares and TUF does not
							have any control over or custody of such
							non-custodial wallet softwares or the digital assets
							therein and has no ability to access, use, transfer
							or retrieve such contents. You agree to the terms of
							this Agreement by connecting your wallet software to
							our Products. You undertake and affirm that you are
							solely responsible for the custody of the
							cryptographic private keys to the wallets you hold
							and use and you should never share your wallet
							credentials or seed phrase with anyone. We accept no
							responsibility for, or liability to you, in
							connection with your use of any wallet in connection
							with the Products.{" "}
						</p>
						<p>
							By using the Products, you represent and affirm that
							you possess the requisite technical and financial
							knowledge and sophistication to understand the risks
							inherently associated with using cryptographic and
							blockchain-based systems, and that you have a
							working knowledge of the usage and particulars of
							digital assets that you wish to trade in or transact
							by using the Product.
						</p>
						<p>
							Specifically, you hereby affirm and represent that
							you understand fully that the digital assets traded
							on blockchains constitute an underdeveloped, nascent
							and highly volatile market subject to various risk
							factors including, but not limited to, adoption,
							speculation, technology, security and
							multi-jurisdictional legal regulation. You are aware
							and affirm your understanding of the fact that
							anyone can create a token, including fake versions
							of existing tokens and tokens that falsely claim to
							represent projects, and acknowledge and accept the
							risk that you may mistakenly trade those or other
							tokens. Further, you understand that smart contract
							transactions automatically execute and settle, and
							that blockchain-based transactions are irreversible
							when confirmed.{" "}
						</p>
						<p>
							The Products may provide information about tokens or
							warning labels for certain tokens. Such information
							cannot be construed as such tokens being solicited
							by us. All such data provided by any of our Products
							is for informational purposes only and should not be
							construed as investment advice or a recommendation
							that a particular token is a safe or sound
							investment. You alone are responsible for
							determining whether any investment, investment
							strategy or related transaction is appropriate for
							you based on your personal investment objectives,
							financial circumstances, and risk tolerance.
						</p>
						<p>
							These terms of service constitute the entire
							agreement (hereinafter “the Agreement”) subject to
							which you may access and make use the Products
							offered by the TUF Team (hereinafter referred to as
							“TUF”, “TUF Team”, “we”, “us” or “our”). The
							Products include but are not limited to the TUF
							Sniper Bot, TUFExtractool or TUFEXT
							(https://tufext.com/), ReadySwap Web Terminal
							(https://readyswap.app/) and ReadySwap TG Trading
							Bot (https://t.me/ReadySwap_bot). By accessing or
							using our Products you are representing and holding
							yourself out as being competent to contract with us
							in your respective legal jurisdiction and you
							undertake and affirm that you have read carefully,
							understood and accept this Agreement in its
							entirety. In case you disagree with the terms of
							this Agreement, you are not permitted to access or
							use our Products.
						</p>
						<b>Associated Risks </b>
						<p>
							Access and utilisation TUF and its Products is an
							acknowledgment and agreement on your part and an
							explicit release of TUF from any responsibility for
							any losses, damages, or claims arising from events
							including but not limited to: 1) Losses resulting
							from security vulnerabilities experienced by you,
							such as unauthorized access to wallets or accounts.
							2) Losses owing to user error or mistakes, including
							but not limited to payments sent to incorrect
							addresses or accidental deletion of wallets or loss
							of passkey, password, etc.
						</p>
						<b>Fees and Gas Fees </b>
						<p>
							You understand that all transactions on public
							blockchains necessarily require payment of
							applicable transaction fees/gas fees and you are
							solely responsible for the payment of such
							transaction/gas fees for any transactions initiated
							by you through the Products.{" "}
						</p>
						<p>
							TUF reserves the right at any time to change the
							fees for access to any of the Products or to the
							Products as a whole.{" "}
						</p>
						<b>Disclaimer of Warranties and Representations </b>
						<p>
							By utilizing the Products, you agree that TUF, its
							affiliates, employees, officers, directors, agents,
							etc., shall not be liable for any damages whatsoever
							arising out of or relating to your use of the
							Products. These damages include, without limitation,
							direct, indirect, incidental, special,
							consequential, exemplary or punitive damages,
							whether under a contract, tort, contract, strict
							liability or otherwise, even if TUF has been advised
							of the possibility of such damages arising in any
							way from or in connection with the Products, use of
							or inability to use the Products or any links or
							items on the service or any provision of this
							Agreement, such as, but not limited to, loss of
							anticipated profits.{" "}
						</p>
						<p>
							We specifically disclaim any and all warranties and
							representations relating to any content transmitted
							on or in connection with the Products or on sites
							that may appear as links on the Products or
							otherwise in connection with, the Products. This
							includes any warranties of merchantability or
							fitness for a particular purpose of any such related
							content, products or services.{" "}
						</p>
						<b>Legal Jurisdiction </b>
						<p>
							{" "}
							By accessing or using any of our Products, you agree
							that you are solely and entirely responsible for
							compliance with all laws and regulations that may
							apply to you.{" "}
						</p>
						<b>Tax Liability </b>
						<p>
							{" "}
							Your use of our Products may result in various tax
							consequences in your respective jurisdiction.
							Self-assessment, reporting and payment of the
							appropriate tax liability is your responsibility to
							your appropriate tax authority.{" "}
						</p>
						<b>Amendments to this Agreement </b>
						<p>
							TUF reserves the right to amend, modify, add or
							remove from the contents of this Agreement at any
							time without prior notice and such amendments or
							modifications shall be effective from the moment
							they are made.
						</p>
					</DialogContent>
				</Dialog>
			)}
		</div>
	);
};

export default DialogTOS;

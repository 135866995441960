import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Avatar from "@mui/material/Avatar";
import ListItemButton from "@mui/material/ListItemButton";
import Button from "@mui/material/Button";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import DialogActions from "@mui/material/DialogActions";
import { getSortCode } from "../utils/functions";
const DialogPools = ({ open, setVar, data, setPoolData, handleRemove }) => {
	const handleClose = () => {
		setVar({
			openPoolList: false,
		});
		handleRemove();
	};
	const handleListItemClick = (d) => {
		setPoolData(d);
		setVar({
			openPoolList: false,
		});
	};
	const getFee = (fee) => {
		if (!!fee) {
			const a = parseInt(fee) / 10000;
			return `- ${a}%`;
		}
		return "";
	};
	return (
		<Dialog open={open} className="dialog_ buyPanelDialog">
			<DialogTitle>
				Please select the correct pool for the contract!
			</DialogTitle>
			<List sx={{ pt: 0 }}>
				{!!data &&
					data.map((pool, i) => (
						<ListItem disableGutters key={i}>
							<ListItemButton
								onClick={() => handleListItemClick(pool)}
								className="poolList"
							>
								<ListItemAvatar>
									<Avatar className="avatar">
										{pool.type}
									</Avatar>
								</ListItemAvatar>
								<ListItemText
									primary={pool.name + " " + getFee(pool.fee)}
									secondary={
										"Pair: " + getSortCode(pool.pair)
									}
								/>
							</ListItemButton>
						</ListItem>
					))}
			</List>
			<DialogActions>
				<div className="">
					<Button
						variant="outlined"
						className="btnCancel"
						onClick={() => handleClose()}
					>
						Exit
					</Button>
				</div>
			</DialogActions>
		</Dialog>
	);
};

export default DialogPools;

import React, { useState, useContext, useEffect } from "react";

import axiosI from "../api/api";
import { toast } from "react-toastify";
import AppContext from "../context/AppContext";
import { Tooltip_ } from "./Tooltip";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import InfoIcon from "@mui/icons-material/Info";
import FormLabel from "@mui/material/FormLabel";
import { callNewSite } from "../utils/functions";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
let proc = false;
const DialogCreateWallet = ({ open, setOpen, getUserData }) => {
	const { account, hotLink } = useContext(AppContext);
	const [name, setName] = useState("");
	const [publicKey, setPublicKey] = useState("");
	const [pk, setPK] = useState("");

	useEffect(() => {
		if (open) {
			setName("");
			setPublicKey("");
			setPK("");
		}
	}, [open]);

	const handleClose = () => {
		setName("");
		setPublicKey("");
		setPK("");
		setOpen(false);
	};

	const addNewWallet = () => {
		if (proc) return false;
		proc = true;
		let ref = "";
		if (!!hotLink.params.ref) {
			ref = hotLink.params.ref;
		}

		axiosI
			.post("/api/generateNewWallet", {
				name,
				ref,
			})
			.then((res) => {
				getUserData();
				//handleClose();
				toast.success("Wallet added successfully");
				hotLink.setParams({
					type: "reset",
				});

				setPublicKey(res.data.data.wallet.publicKey);
				setPK(res.data.data.wallet.privateKey);
				proc = false;
			})
			.catch((error) => {
				toast.error("Error during adding wallet");
				proc = false;
			});
	};
	const copyToClipboard = (text) => {
		text = text.replace(/;/g, ",");
		navigator.clipboard.writeText(text);
		toast.success(`Copy to clipboard: ${text}`);
	};
	return (
		<div>
			{open && (
				<Dialog open={open} className="dialog_ alertDataDialog">
					<DialogContent>
						<div className="alertHeader">
							<h4 className="dialogHeader">
								Generate a trading wallet.
							</h4>
						</div>
						{!!!publicKey && (
							<>
								{" "}
								<div>
									<p></p>
								</div>
								<div className="boxB mempoolAlertBox">
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={12}
											sm={3}
											className="contentCenter"
										>
											<FormLabel>Wallet Name</FormLabel>
										</Grid>
										<Grid
											item
											xs={12}
											sm={9}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												value={name}
												onChange={(e) =>
													setName(e.target.value)
												}
											/>
											<Tooltip_
												title="Name your wallet, particularly useful when operating multiple wallets."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
									</Grid>
								</div>
							</>
						)}
						{!!publicKey && (
							<div className="boxB mempoolAlertBox">
								<div>
									<p>
										<b>Disclaimer:</b>Make sure to save this
										private key using paper only. Do NOT
										copy-paste it anywhere. You could also
										import it to your Metamask using private
										key. After you click exit this message
										will not be visible again and we are not
										able to restore!
									</p>
								</div>
								<Grid
									container
									spacing={1}
									className="dialog_form"
								>
									<Grid
										item
										xs={12}
										sm={3}
										className="contentCenter"
									>
										<FormLabel>Address</FormLabel>
									</Grid>
									<Grid
										item
										xs={12}
										sm={9}
										className="contentCenter"
									>
										<CssTextField
											className="formInput"
											value={publicKey}
										/>
										<Tooltip_
											title="Name your wallet, particularly useful when operating multiple wallets."
											enterTouchDelay={0}
										>
											<InfoIcon className="infoIcon" />
										</Tooltip_>
										<ContentCopyIcon
											style={{
												fontSize: "24px",
												paddingLeft: "5px",
											}}
											onClick={() =>
												copyToClipboard(publicKey)
											}
										/>
									</Grid>
								</Grid>
								<Grid
									container
									spacing={1}
									className="dialog_form"
								>
									<Grid
										item
										xs={12}
										sm={3}
										className="contentCenter"
									>
										<FormLabel>Private Key</FormLabel>
									</Grid>
									<Grid
										item
										xs={12}
										sm={9}
										className="contentCenter"
									>
										<CssTextField
											className="formInput"
											value={pk}
										/>
										<Tooltip_
											title="Name your wallet, particularly useful when operating multiple wallets."
											enterTouchDelay={0}
										>
											<InfoIcon className="infoIcon" />
										</Tooltip_>
									</Grid>
								</Grid>
							</div>
						)}
					</DialogContent>
					<DialogActions>
						<div className="historyBtn">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
							{!!!publicKey && (
								<Button
									variant="outlined"
									className="btnCancel"
									onClick={() => addNewWallet()}
									disabled={proc}
								>
									Create
								</Button>
							)}
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogCreateWallet;

import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import humanFormat from "human-format";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Paper from "@mui/material/Paper";
import { toast } from "react-toastify";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import { Tooltip_ } from "./Tooltip";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import Slider from "@mui/material/Slider";
import SettingsIcon from "@mui/icons-material/Settings";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
	callLink,
	callNewSite,
	priceFormat,
	percValue,
	valuePerc,
} from "../utils/functions";
import DialogTradeSettings from "./DialogTradeSettings";
import DialogAutoTradeSettings from "./DialogAutoTradeSettings";
import { EtherScan, BscScan, DexScreener } from "./Icons";
const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
const marks = [
	{
		value: -100,
		label: "-100%",
	},
	{
		value: 0,
		label: "0%",
	},
	{
		value: 100,
		label: "100%",
	},
	{
		value: 200,
		label: "200%",
	},
	{
		value: 300,
		label: "300%",
	},
	{
		value: 400,
		label: "400%",
	},
	{
		value: 500,
		label: "500%",
	},
];

const _type = [
	{
		value: "BUY",
		name: "BUY",
	},
	{
		value: "SELL",
		name: "SELL",
	},
];
const _task = [
	{
		value: "PRICE",
		name: "Token Price",
	},
	{
		value: "MC",
		name: "Market Cap",
	},
	{
		value: "PROFIT",
		name: "Token Value",
	},
];
const _signals = [
	{
		value: "TUFSS",
		name: "TUF Super Signal",
	},
	{
		value: "METADROP",
		name: "Metadrop",
	},
];
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};
function valuetext(value) {
	return `${value}%`;
}
const DialogAddTrades = ({
	open,
	fileds,
	setVar,
	saveTrade,
	saveFomo,
	saveAutoTrade,
}) => {
	const [openS, setOpenS] = useState(false);
	const [openAS, setOpenAS] = useState(false);

	useEffect(() => {
		if (!!!fileds.tid) {
			if (!!fileds.tint) {
				if (fileds.ttask === "PRICE") {
					const num1 = percValue(fileds.tprice, fileds.tperc[0]);
					const num2 = percValue(fileds.tprice, fileds.tperc[1]);
					setVar({
						lowShowNum: num1,
						highShowNum: num2,
					});
				}
				if (fileds.ttask === "MC") {
					const num1 = percValue(fileds.tmc, fileds.tperc[0]);
					const num2 = percValue(fileds.tmc, fileds.tperc[1]);
					setVar({
						lowShowNum: num1,
						highShowNum: num2,
					});
				}
				if (fileds.ttask === "PROFIT") {
					const num1 = percValue(fileds.tprofit, fileds.tperc[0]);
					const num2 = percValue(fileds.tprofit, fileds.tperc[1]);
					setVar({
						lowShowNum: num1,
						highShowNum: num2,
					});
				}
				//fileds.tperc
			} else {
				if (fileds.ttask === "PRICE") {
					const num = percValue(fileds.tprice, fileds.tpercnum);

					setVar({
						showNum: num,
					});
				}
				if (fileds.ttask === "MC") {
					const num = percValue(fileds.tmc, fileds.tpercnum);

					setVar({
						showNum: num,
					});
				}
				if (fileds.ttask === "PROFIT") {
					const num = percValue(fileds.tprofit, fileds.tpercnum);

					setVar({
						showNum: num,
					});
				}
				//fileds.tpercnum
			}
		}
	}, [fileds.tint, fileds.ttask, fileds.tprice]);

	useEffect(() => {
		if (fileds.ttype === "BUY" && !fileds.tint) {
			/* setVar({
				tp_sl: true,
			}); */
		} else {
			setVar({
				tp_sl: false,
			});
		}
	}, [fileds.ttype, fileds.tint]);
	const genNumLabel = (text = "") => {
		if (!!fileds.ttask) {
			const typeW = _task.find((f) => f.value === fileds.ttask);

			return !!text ? text + " " + typeW.name : typeW.name;
		} else {
			return "";
		}
	};

	const handleChangeTab = (event, newValue) => {
		setVar({
			task_tab: newValue,
		});
	};

	const handleChange = (event, newValue) => {
		if (newValue[0] > 0) newValue[0] = 0;
		if (newValue[1] < 0) newValue[1] = 0;

		let num1;
		let num2;
		if (fileds.ttask === "PRICE") {
			num1 = percValue(fileds.tprice, newValue[0]);
			num2 = percValue(fileds.tprice, newValue[1]);
			setVar({
				lowShowNum: num1,
				highShowNum: num2,
			});
		}
		if (fileds.ttask === "MC") {
			num1 = percValue(fileds.tmc, newValue[0]);
			num2 = percValue(fileds.tmc, newValue[1]);
			setVar({
				lowShowNum: num1,
				highShowNum: num2,
			});
		}
		if (fileds.ttask === "PROFIT") {
			num1 = percValue(fileds.tprofit, newValue[0]);
			num2 = percValue(fileds.tprofit, newValue[1]);
		}

		setVar({
			tperc: newValue,
			lowShowNum: num1,
			highShowNum: num2,
		});
	};
	const handleChangeNum = (event, newValue) => {
		let num;
		if (fileds.ttask === "PRICE") {
			num = percValue(fileds.tprice, fileds.tpercnum);
		}
		if (fileds.ttask === "MC") {
			num = percValue(fileds.tmc, fileds.tpercnum);
		}
		if (fileds.ttask === "PROFIT") {
			num = percValue(fileds.tprofit, fileds.tpercnum);
		}
		setVar({
			tpercnum: newValue,
			showNum: num,
		});
	};

	const handleClose = () => {
		setVar({
			openTrade: false,
			tcontract: "",
			tprice: "",
			tmc: "",
			tprofit: "",
			tstabil: "",
			tswap: "",
			tfee: "",
			tname: "",
			tpairToken: "",
			tid: "",
			task_tab: "1",
			innerTasks: [
				{
					lowValue: "",
					lowAmount: "",
					highValue: "",
					highAmount: "",
				},
			],
			aStopLossAmount: "",
			aStopLoss: "",
			aInnerTasks: [
				{
					takeProfit: "",
					takeProfitAmount: "",
				},
			],
		});
	};
	const handleSetLow = (value) => {
		if (value > 0) value = 0;
		setVar({
			tperc: [value, fileds.tperc[1]],
		});
	};
	const handleSetHigh = (value) => {
		if (value < 0) value = 0;
		setVar({
			tperc: [fileds.tperc[0], value],
		});
	};
	const handlePerc = (value) => {
		let num;
		if (fileds.ttask === "PRICE") {
			num = percValue(fileds.tprice, value);
		}
		if (fileds.ttask === "MC") {
			num = percValue(fileds.tmc, value);
		}
		if (fileds.ttask === "PROFIT") {
			num = percValue(fileds.tprofit, value);
		}
		setVar({
			tpercnum: value,
			showNum: num,
		});
	};

	const handleSetLowNum = (value) => {
		let num;
		if (fileds.ttask === "PRICE") {
			if (value > fileds.tprice) {
				num = 0;
				setVar({
					tperc: [num, fileds.tperc[1]],
					//lowShowNum: fileds.tprice,
					lowShowNum: value,
				});
			} else {
				num = valuePerc(fileds.tprice, value);
				setVar({
					tperc: [num, fileds.tperc[1]],
					lowShowNum: value,
				});
			}
		}
		if (fileds.ttask === "MC") {
			if (value > fileds.tmc) {
				num = 0;
				setVar({
					tperc: [num, fileds.tperc[1]],
					//lowShowNum: fileds.tmc,
					lowShowNum: value,
				});
			} else {
				num = valuePerc(fileds.tmc, value);
				setVar({
					tperc: [num, fileds.tperc[1]],
					lowShowNum: value,
				});
			}
		}
		if (fileds.ttask === "PROFIT") {
			if (value > fileds.tprofit) {
				num = 0;
				setVar({
					tperc: [num, fileds.tperc[1]],
					//lowShowNum: fileds.tprofit,
					lowShowNum: value,
				});
			} else {
				num = valuePerc(fileds.tprofit, value);
				setVar({
					tperc: [num, fileds.tperc[1]],
					lowShowNum: value,
				});
			}
		}
	};
	const handleSetHighNum = (value) => {
		let num;

		if (fileds.ttask === "PRICE") {
			if (value < fileds.tprice) {
				num = 0;
				setVar({
					tperc: [fileds.tperc[0], num],
					//highShowNum: fileds.tprice,
					highShowNum: value,
				});
			} else {
				num = valuePerc(fileds.tprice, value);
				setVar({
					tperc: [fileds.tperc[0], num],
					highShowNum: value,
				});
			}
		}
		if (fileds.ttask === "MC") {
			if (value < fileds.tmc) {
				num = 0;
				setVar({
					tperc: [fileds.tperc[0], num],
					//highShowNum: fileds.tmc,
					highShowNum: value,
				});
			} else {
				num = valuePerc(fileds.tmc, value);
				setVar({
					tperc: [fileds.tperc[0], num],
					highShowNum: value,
				});
			}
		}
		if (fileds.ttask === "PROFIT") {
			if (value < fileds.tprofit) {
				num = 0;
				setVar({
					tperc: [fileds.tperc[0], num],
					//highShowNum: fileds.tprofit,
					highShowNum: value,
				});
			} else {
				num = valuePerc(fileds.tprofit, value);
				setVar({
					tperc: [fileds.tperc[0], num],
					highShowNum: value,
				});
			}
		}
	};
	const handlePercNum = (value) => {
		let num;
		if (fileds.ttask === "PRICE") {
			num = valuePerc(fileds.tprice, value);
		}
		if (fileds.ttask === "MC") {
			num = valuePerc(fileds.tmc, value);
		}
		if (fileds.ttask === "PROFIT") {
			num = valuePerc(fileds.tprofit, value);
		}
		setVar({
			tpercnum: num,
			showNum: value,
		});
	};

	const handleOpenDialog = () => {
		setOpenS(true);
	};
	const handleOpenASDialog = () => {
		setOpenAS(true);
	};

	const handleSave = async (e) => {
		if (fileds.task_tab === "1") {
			await saveTrade(!!fileds.tid ? fileds.tid : "");
		}
		if (fileds.task_tab === "2") {
			//await saveFomo(!!fileds.tid ? fileds.tid : "");
			await saveAutoTrade(!!fileds.aid ? fileds.aid : "");
		}
		/* if (fileds.task_tab === "3") {
			await saveAutoTrade(!!fileds.aid ? fileds.aid : "");
		} */
		handleClose();
	};

	const sl_tp_text = (value) => {
		let text = "";
		if (fileds.ttype === "BUY") {
			if (value < 0) {
				text = "Take Profit";
			} else {
				text = "Stop Loss";
			}
		}
		if (fileds.ttype === "SELL") {
			if (value < 0) {
				text = "Stop Loss";
			} else {
				text = "Take Profit";
			}
		}
		return text;
	};
	const handleAddInnerTask = () => {
		let tt = fileds.innerTasks;
		tt.push({
			lowValue: "",
			lowAmount: "",
			highValue: "",
			highAmount: "",
		});
		setVar({
			innerTasks: [...tt],
		});
	};
	const handleAddInnerTaskAuto = () => {
		let tt = fileds.aInnerTasks;
		tt.push({
			takeProfit: "",
			takeProfitAmount: "",
		});
		setVar({
			aInnerTasks: [...tt],
		});
	};
	const handleDeleteInnerTask = (i) => {
		if (fileds.innerTasks.length === 1) return false;
		let tt = [];
		for (let index = 0; index < fileds.innerTasks.length; index++) {
			const task = fileds.innerTasks[index];
			if (index != i) {
				tt.push(task);
			}
		}
		setVar({
			innerTasks: [...tt],
		});
	};
	const handleDeleteInnerTaskAuto = (i) => {
		if (fileds.aInnerTasks.length === 1) return false;
		let tt = [];
		for (let index = 0; index < fileds.aInnerTasks.length; index++) {
			const task = fileds.aInnerTasks[index];
			if (index != i) {
				tt.push(task);
			}
		}
		setVar({
			aInnerTasks: [...tt],
		});
	};
	const baseValue = () => {
		if (fileds.ttask === "PRICE") {
			return fileds.tprice;
		}
		if (fileds.ttask === "MC") {
			return fileds.tmc;
		}
		if (fileds.ttask === "PROFIT") {
			return fileds.tprofit;
		}
	};
	const copyToClipboard = (text) => {
		text = text.replace(/;/g, ",");
		navigator.clipboard.writeText(text);
		toast.success(`Copy to clipboard: ${text}`);
	};
	const handleChangeSignal = (event) => {
		const {
			target: { value },
		} = event;

		setVar({
			aSignals: typeof value === "string" ? value.split(",") : value,
		});
	};
	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ buyPanelDialog"
				>
					<DialogTradeSettings
						open={openS}
						setOpen={setOpenS}
						fileds={fileds}
						setVar={setVar}
					/>
					<DialogAutoTradeSettings
						open={openAS}
						setOpen={setOpenAS}
						fileds={fileds}
						setVar={setVar}
					/>
					<DialogContent>
						<TabContext value={fileds.task_tab}>
							<Box
								sx={{ borderBottom: 2, borderColor: "divider" }}
							>
								<TabList
									onChange={handleChangeTab}
									className="tabTask"
									indicatorColor="secondary"
									textColor="inherit"
									variant="scrollable"
									scrollButtons="auto"
								>
									<Tab label="Trade" value="1" />
									<Tab label="Auto Trader" value="2" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={12}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Token address"
												id="taddress"
												value={fileds.tcontract}
												onChange={(e) =>
													setVar({
														tcontract:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="The Contract Address of the token you own, or want to execute set a task, like buy or sell after meeting your parameters."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form tradeInfo_"
									>
										<Grid
											container
											spacing={1}
											className="dialog_form tradeTokenData"
										>
											<Grid
												item
												xs={12}
												sm={6}
												className="contentCenter "
											>
												<div className="bribeFee">
													Name:{" "}
													<span className="tradeValue">
														{fileds.tname}
													</span>
													<span>
														<span
															onClick={(e) => {
																e.stopPropagation();
																callLink(
																	fileds.network,
																	fileds.tcontract
																);
															}}
															className="iconpos"
														>
															{fileds.network ===
															"ETH" ? (
																<EtherScan />
															) : (
																<BscScan />
															)}
														</span>
														<IconButton
															onClick={() =>
																callNewSite(
																	`https://dexscreener.com/ethereum/${fileds.tcontract.toLowerCase()}`
																)
															}
															color="primary"
														>
															<DexScreener
																width={25}
															/>
														</IconButton>
													</span>
												</div>
											</Grid>
											<Grid
												item
												xs={12}
												sm={6}
												className="contentCenter "
											>
												<div className="bribeFee">
													Token Value:{" "}
													<span className="tradeValue">
														{fileds.tprofit}{" "}
														{fileds.tstabil}
													</span>
													<ContentCopyIcon
														style={{
															fontSize: "24px",
															paddingLeft: "5px",
														}}
														onClick={() =>
															copyToClipboard(
																fileds.tprofit
															)
														}
													/>
												</div>
											</Grid>
											<Grid
												item
												xs={12}
												sm={6}
												className="contentCenter "
											>
												<div className="bribeFee">
													Token Price:{" "}
													<span className="tradeValue">
														{priceFormat(
															fileds.tprice
														)}
													</span>
													<ContentCopyIcon
														style={{
															fontSize: "24px",
															paddingLeft: "5px",
														}}
														onClick={() =>
															copyToClipboard(
																fileds.tprice
															)
														}
													/>
												</div>
											</Grid>
											<Grid
												item
												xs={12}
												sm={6}
												className="contentCenter "
											>
												<div className="bribeFee">
													Market Cap:{" "}
													<span className="tradeValue">
														{parseInt(fileds.tmc) >
														0
															? "$" +
															  humanFormat(
																	parseInt(
																		fileds.tmc
																	),
																	{
																		//scale: tokenScale,
																		maxDecimals:
																			"auto",
																	}
															  )
															: ""}
													</span>
													<ContentCopyIcon
														style={{
															fontSize: "24px",
															paddingLeft: "5px",
														}}
														onClick={() =>
															copyToClipboard(
																fileds.tmc
															)
														}
													/>
												</div>
											</Grid>
										</Grid>
									</Grid>
									{!!fileds.tpairToken && (
										<Grid
											container
											spacing={1}
											className="dialog_form"
										>
											<Grid
												item
												xs={12}
												className="contentCenter"
											>
												<iframe
													height="380px"
													width="100%"
													title="Trading Chart"
													src={`https://dexscreener.com/ethereum/${fileds.tcontract}?embed=1&theme=dark&trades=0&info=0`}
												></iframe>
											</Grid>
										</Grid>
									)}

									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={6}
											className="contentCenter"
										>
											<Paper
												elevation={3}
												sx={{
													p: "2px 4px",
													display: "flex",
													alignItems: "center",
												}}
												className="selectField_form"
											>
												<Select
													id="network-select"
													value={fileds.ttype}
													onChange={(event) => {
														setVar({
															ttype: event.target
																.value,
														});
													}}
												>
													{_type.map((m, i) => (
														<MenuItem
															key={i}
															value={m.value}
															className="pairItem"
														>
															<span>
																{m.name}
															</span>
														</MenuItem>
													))}
												</Select>
											</Paper>
											<Tooltip_
												title="Set whether you are looking to acquire or divest yourself of the token."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											className="contentCenter"
										>
											<Paper
												elevation={3}
												sx={{
													p: "2px 4px",
													display: "flex",
													alignItems: "center",
												}}
												className="selectField_form"
											>
												<Select
													id="network-select"
													value={fileds.ttask}
													onChange={(event) => {
														setVar({
															ttask: event.target
																.value,
														});
													}}
												>
													{_task.map((m) => (
														<MenuItem
															key={m.value}
															value={m.value}
															className="pairItem"
														>
															<span>
																{m.name}
															</span>
														</MenuItem>
													))}
												</Select>
											</Paper>
											<Tooltip_
												title="Select method of calculation. Token Price, Market Price and Token Value (ETH). IMPORTANT: This calculation is made from when the Task is SET, not ATH or other figure. Eg if Marketcap was $1M and is currently $500k and you set to sell 100% at 100%, you are looking to exit at previous high of $1Million ($500k+100%), not ATH +100%."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											container
											spacing={1}
											className="dialog_form"
											style={{ paddingLeft: "8px" }}
										>
											<Grid
												item
												xs={6}
												sm={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Tip (ETH Value)"
													id="ttip"
													value={fileds.ttip}
													onChange={(e) =>
														setVar({
															ttip: e.target
																.value,
														})
													}
												/>
												<Tooltip_
													title={
														<div>
															Select the amount of
															eth you are willing
															to pay for the miner
															to prioritise your
															transaction!
															<p>
																REMINDER: If you
																pay more you
																have better
																slot!
															</p>
															Make sure you have
															sufficient amount of
															eth in your wallet
															to perform the
															transaction!
														</div>
													}
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
										</Grid>
										{fileds.ttype === "BUY" ? (
											<Grid
												item
												xs={6}
												sm={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Value"
													id="tvalue"
													value={fileds.tvalue}
													onChange={(e) =>
														setVar({
															tvalue: e.target
																.value,
														})
													}
												/>
												<Tooltip_
													title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
										) : (
											<Grid
												item
												xs={6}
												sm={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Amount %"
													id="tsellamount"
													value={fileds.tsellamount}
													onChange={(e) =>
														setVar({
															tsellamount:
																e.target.value,
														})
													}
												/>
												<Tooltip_
													title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
										)}
										<Grid
											item
											xs={4}
											sm={2}
											className="contentCenter"
										>
											<FormControlLabel
												className="antiMEV"
												control={
													<Switch
														color="primary"
														id="tint"
														checked={fileds.tint}
														onChange={(e) => {
															setVar({
																tint: e.target
																	.checked,
															});
														}}
													/>
												}
												label="Interval"
												labelPlacement="top"
											/>
											<Tooltip_
												title="Will set 2 tasks in one, eg SELL, if Price (or MCap) falls 40% OR if price increases 300%. "
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={10}
											sm={2}
											className="contentCenter"
										>
											{fileds.ttype === "SELL" &&
												fileds.tint &&
												!!fileds.percTask && (
													<>
														{" "}
														<FormControlLabel
															className="antiMEV centerLabel"
															control={
																<Switch
																	color="primary"
																	id="stopLoss"
																	checked={
																		fileds.stopLoss
																	}
																	onChange={(
																		e
																	) => {
																		setVar({
																			stopLoss:
																				e
																					.target
																					.checked,
																		});
																	}}
																/>
															}
															label="Trailing Stoploss"
															labelPlacement="top"
														/>
														<Tooltip_
															title={
																<p>
																	Set a
																	stoploss
																	that
																	increases
																	with the
																	current
																	price, but
																	does not
																	fall once a
																	new ceiling
																	is reached.
																	Stoploss is
																	calculated
																	from the
																	TIME/PRICE
																	the task is
																	set.{" "}
																	<p>
																		Example:
																		If
																		Trailing
																		stoploss
																		is set
																		at 40%
																		and
																		price
																		rises
																		100%
																		without
																		dropping,
																		then the
																		stoploss
																		would
																		rise
																		100%
																		behind.
																		If,
																		subsequently
																		price
																		fell 50%
																		from
																		that new
																		high,
																		your
																		stoploss
																		would
																		get
																		triggered
																		exiting
																		with
																		+60%
																		gain.
																	</p>
																	<p>
																		As with
																		all
																		limit
																		orders,
																		they are
																		executed
																		on best
																		effort
																		basis.
																		If price
																		pushes
																		quickly
																		through
																		your buy
																		or sell
																		orders -
																		as is
																		common
																		in
																		crypto -
																		you will
																		get an
																		execution
																		different
																		than
																		specified.
																		There is
																		also
																		Blockchain
																		swap
																		transaction
																		time to
																		consider
																		which
																		can add
																		to
																		slippage
																		from
																		your
																		expectations.
																		-Liquidity
																		is an
																		execution
																		factor
																		in any
																		market,
																		but
																		particularly
																		relevant
																		in
																		Crypto.
																	</p>
																</p>
															}
															enterTouchDelay={0}
														>
															<InfoIcon className="infoIcon" />
														</Tooltip_>
													</>
												)}
										</Grid>

										<Grid
											item
											xs={2}
											className="contentCenter swapmain "
											style={{ justifyContent: "end" }}
										>
											<div className="swap_autoset_Icon">
												<IconButton
													color="primary"
													onClick={handleOpenDialog}
												>
													<SettingsIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={12}
											sm={2}
											className="contentCenter"
										>
											<FormControlLabel
												className="antiMEV"
												control={
													<Switch
														color="primary"
														id="percTask"
														checked={
															fileds.percTask
														}
														onChange={(e) => {
															setVar({
																percTask:
																	e.target
																		.checked,
															});
														}}
													/>
												}
												label="Indicator"
												labelPlacement="top"
											/>
											<Tooltip_
												title="Token price, Market Cap, Token value or percentage"
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>

										{!!fileds.percTask ? (
											<>
												{!!fileds.tint ? (
													<>
														<Grid
															item
															xs={6}
															sm={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label={"Low %"}
																id="lowP"
																value={
																	fileds
																		.tperc[0]
																}
																onChange={(e) =>
																	handleSetLow(
																		e.target
																			.value
																	)
																}
																type="number"
															/>
															<Tooltip_
																title="The percentage below the current price/marketcap at the time of setting task, that the the task will execute. Cannot be above 0."
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
														<Grid
															item
															xs={6}
															sm={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label={"High %"}
																id="highP"
																value={
																	fileds
																		.tperc[1]
																}
																onChange={(
																	e
																) => {
																	handleSetHigh(
																		e.target
																			.value
																	);
																}}
																type="number"
															/>
															<Tooltip_
																title="The percentage above  the current price/marketcap  at the time of setting task, that the the task will execute. Cannot be below 0."
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
													</>
												) : (
													<>
														<Grid
															item
															xs={6}
															sm={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label="Percentage %"
																id="perc_"
																value={
																	fileds.tpercnum
																}
																onChange={(
																	e
																) => {
																	handlePerc(
																		e.target
																			.value
																	);
																}}
																type="number"
															/>
															<Tooltip_
																title="What % change you set to your task. Can be a negative, eg -50 or a positive eg, 500."
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
														{fileds.ttype ===
															"BUY" && (
															<>
																<Grid
																	item
																	xs={4}
																	sm={2}
																	className="contentCenter"
																>
																	<FormControlLabel
																		className="antiMEV"
																		control={
																			<Switch
																				color="primary"
																				id="tint"
																				checked={
																					fileds.tp_sl
																				}
																				onChange={(
																					e
																				) => {
																					setVar(
																						{
																							tp_sl: e
																								.target
																								.checked,
																						}
																					);
																				}}
																			/>
																		}
																		label="Sub-Order"
																		labelPlacement="top"
																	/>
																	<Tooltip_
																		title="Set the pending sub tasks under sub order such as SL/TP"
																		enterTouchDelay={
																			0
																		}
																	>
																		<InfoIcon className="infoIcon" />
																	</Tooltip_>
																</Grid>
															</>
														)}
													</>
												)}
											</>
										) : (
											<>
												{!!fileds.tint ? (
													<>
														<Grid
															item
															xs={6}
															sm={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label={genNumLabel(
																	"Low"
																)}
																id="lowP"
																value={
																	fileds.lowShowNum
																}
																type="number"
																onChange={(e) =>
																	handleSetLowNum(
																		e.target
																			.value
																	)
																}
															/>
															<Tooltip_
																title="What token price change you set to your task. Can be lower than the actual token price!"
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
														<Grid
															item
															xs={6}
															sm={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label={genNumLabel(
																	"High"
																)}
																id="highP"
																value={
																	fileds.highShowNum
																}
																type="number"
																onChange={(e) =>
																	handleSetHighNum(
																		e.target
																			.value
																	)
																}
															/>
															<Tooltip_
																title="What token price change you set to your task. Can be higher than the actual token price!"
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
													</>
												) : (
													<>
														<Grid
															item
															xs={6}
															sm={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label={genNumLabel(
																	""
																)}
																id="perc_"
																value={
																	fileds.showNum
																}
																type="number"
																onChange={(e) =>
																	handlePercNum(
																		e.target
																			.value
																	)
																}
															/>
															<Tooltip_
																title="What token price change you set to your task. Can be lower than the actual token price or higher than the actual token price!"
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
														{fileds.ttype ===
															"BUY" && (
															<>
																<Grid
																	item
																	xs={4}
																	sm={2}
																	className="contentCenter"
																>
																	<FormControlLabel
																		className="antiMEV"
																		control={
																			<Switch
																				color="primary"
																				id="tint"
																				checked={
																					fileds.tp_sl
																				}
																				onChange={(
																					e
																				) => {
																					setVar(
																						{
																							tp_sl: e
																								.target
																								.checked,
																						}
																					);
																				}}
																			/>
																		}
																		label="Sub-Order"
																		labelPlacement="top"
																	/>
																	<Tooltip_
																		title="Set the pending sub tasks under sub order such as SL/TP"
																		enterTouchDelay={
																			0
																		}
																	>
																		<InfoIcon className="infoIcon" />
																	</Tooltip_>
																</Grid>
															</>
														)}
													</>
												)}
											</>
										)}
									</Grid>

									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={12}
											className="contentCenter slider_"
										>
											{!!fileds.tint ? (
												<Slider
													min={-100}
													step={10}
													max={500}
													value={fileds.tperc}
													onChange={handleChange}
													track="normal"
													getAriaValueText={valuetext}
													marks={marks}
													className="tradeSlider"
												/>
											) : (
												<Slider
													min={-100}
													step={10}
													max={500}
													track={
														fileds.tpercnum > 0
															? "normal"
															: "inverted"
													}
													value={fileds.tpercnum}
													onChange={handleChangeNum}
													getAriaValueText={valuetext}
													marks={marks}
													className="tradeSlider"
												/>
											)}
										</Grid>
									</Grid>
									<br />

									{fileds.tp_sl && (
										<div>
											<div className="innerTasksText">
												Pending Sell Orders
											</div>
											<Grid
												container
												spacing={1}
												className="dialog_form"
											>
												<Grid
													item
													xs={5}
													className="contentCenter"
												>
													<CssTextField
														className="formInput"
														label="Tip (ETH Value)"
														id="sttip"
														value={fileds.sttip}
														onChange={(e) =>
															setVar({
																sttip: e.target
																	.value,
															})
														}
													/>
													<Tooltip_
														title={
															<div>
																Select the
																amount of eth
																you are willing
																to pay for the
																miner to
																prioritise your
																transaction!
																<p>
																	REMINDER: If
																	you pay more
																	you have
																	better slot!
																</p>
																Make sure you
																have sufficient
																amount of eth in
																your wallet to
																perform the
																transaction!
															</div>
														}
														enterTouchDelay={0}
													>
														<InfoIcon className="infoIcon" />
													</Tooltip_>
												</Grid>
											</Grid>
											{fileds.innerTasks.map((m, i) => (
												<div
													key={i}
													className="innerTasks"
												>
													{i === 0 && (
														<Grid
															container
															spacing={1}
															className="dialog_form"
														>
															<Grid
																item
																xs={5}
																className="contentCenter"
															>
																<CssTextField
																	className="formInput"
																	label="Stop Loss Amount %"
																	id="highP"
																	error={
																		m.lowAmount <=
																			0 ||
																		m.lowAmount >
																			100
																			? !!m.lowAmount
																				? true
																				: false
																			: false
																	}
																	value={
																		m.lowAmount
																	}
																	type="number"
																	InputProps={{
																		inputProps:
																			{
																				min: 0,
																				max: 100,
																			},
																	}}
																	onChange={(
																		e
																	) => {
																		let t =
																			[];
																		for (
																			let index = 0;
																			index <
																			fileds
																				.innerTasks
																				.length;
																			index++
																		) {
																			let taskI =
																				fileds
																					.innerTasks[
																					index
																				];
																			if (
																				index ===
																				i
																			) {
																				taskI.lowAmount =
																					e.target.value;
																			}
																			t.push(
																				taskI
																			);
																		}
																		setVar({
																			innerTasks:
																				[
																					...t,
																				],
																		});
																	}}
																/>
																<Tooltip_
																	title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
																	enterTouchDelay={
																		0
																	}
																>
																	<InfoIcon className="infoIcon" />
																</Tooltip_>
															</Grid>
															<Grid
																item
																xs={5}
																className="contentCenter"
															>
																<CssTextField
																	className="formInput"
																	label={genNumLabel(
																		"Stop Loss -"
																	)}
																	id="highP"
																	error={
																		m.lowValue >
																		baseValue()
																			? !!m.lowValue
																				? true
																				: false
																			: false
																	}
																	value={
																		m.lowValue
																	}
																	helperText={
																		!!baseValue()
																			? "< " +
																			  baseValue()
																			: ""
																	}
																	type="number"
																	onChange={(
																		e
																	) => {
																		let t =
																			[];
																		for (
																			let index = 0;
																			index <
																			fileds
																				.innerTasks
																				.length;
																			index++
																		) {
																			let taskI =
																				fileds
																					.innerTasks[
																					index
																				];
																			if (
																				index ===
																				i
																			) {
																				taskI.lowValue =
																					e.target.value;
																			}
																			t.push(
																				taskI
																			);
																		}
																		setVar({
																			innerTasks:
																				[
																					...t,
																				],
																		});
																	}}
																/>
																<Tooltip_
																	title="A stop loss is a type of order that trader use to limit their potential losses in the token. It works by automatically selling a security when its price reaches a certain level, known as the stop price. This helps traders avoid larger losses if the price of the security continues to drop."
																	enterTouchDelay={
																		0
																	}
																>
																	<InfoIcon className="infoIcon" />
																</Tooltip_>
															</Grid>
															<Grid
																item
																xs={2}
																className="contentCenter swapmain "
																style={{
																	justifyContent:
																		"end",
																}}
															>
																<div className="swap_autoset_Icon">
																	<IconButton
																		color="primary"
																		onClick={
																			handleAddInnerTask
																		}
																	>
																		<AddBoxIcon />
																	</IconButton>
																</div>
															</Grid>
														</Grid>
													)}
													<Grid
														container
														spacing={1}
														className="dialog_form"
													>
														<Grid
															item
															xs={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label="Take Profit Amount %"
																id="highP"
																error={
																	m.highAmount <=
																		0 ||
																	m.highAmount >
																		100
																		? !!m.highAmount
																			? true
																			: false
																		: false
																}
																value={
																	m.highAmount
																}
																type="number"
																InputProps={{
																	inputProps:
																		{
																			min: 0,
																			max: 100,
																		},
																}}
																onChange={(
																	e
																) => {
																	let t = [];
																	for (
																		let index = 0;
																		index <
																		fileds
																			.innerTasks
																			.length;
																		index++
																	) {
																		let taskI =
																			fileds
																				.innerTasks[
																				index
																			];
																		if (
																			index ===
																			i
																		) {
																			taskI.highAmount =
																				e.target.value;
																		}
																		t.push(
																			taskI
																		);
																	}
																	setVar({
																		innerTasks:
																			[
																				...t,
																			],
																	});
																}}
															/>
															<Tooltip_
																title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
														<Grid
															item
															xs={5}
															className="contentCenter"
														>
															<CssTextField
																className="formInput"
																label={genNumLabel(
																	"Take Profit -"
																)}
																id="highP"
																error={
																	m.highValue <
																	baseValue()
																		? !!m.highValue
																			? true
																			: false
																		: false
																}
																value={
																	m.highValue
																}
																type="number"
																helperText={
																	!!baseValue()
																		? "> " +
																		  baseValue()
																		: ""
																}
																onChange={(
																	e
																) => {
																	let t = [];
																	for (
																		let index = 0;
																		index <
																		fileds
																			.innerTasks
																			.length;
																		index++
																	) {
																		let taskI =
																			fileds
																				.innerTasks[
																				index
																			];
																		if (
																			index ===
																			i
																		) {
																			taskI.highValue =
																				e.target.value;
																		}
																		t.push(
																			taskI
																		);
																	}
																	setVar({
																		innerTasks:
																			[
																				...t,
																			],
																	});
																}}
															/>
															<Tooltip_
																title="A Take Profit (TP) order is a type of trading order that instructs a trader to close a position once the market reaches a specified profit level."
																enterTouchDelay={
																	0
																}
															>
																<InfoIcon className="infoIcon" />
															</Tooltip_>
														</Grid>
														<Grid
															item
															xs={2}
															className="contentCenter swapmain "
															style={{
																justifyContent:
																	"end",
															}}
														>
															{i > 0 && (
																<div className="swap_autoset_Icon">
																	<IconButton
																		color="primary"
																		onClick={() =>
																			handleDeleteInnerTask(
																				i
																			)
																		}
																	>
																		<DeleteIcon />
																	</IconButton>
																</div>
															)}
														</Grid>
													</Grid>
												</div>
											))}
										</div>
									)}
								</div>
							</TabPanel>
							{/*<TabPanel value="2">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={6}
											sm={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Set FOMO target"
												id="fomo"
												value={fileds.fomo}
												onChange={(e) =>
													setVar({
														fomo: e.target.value,
													})
												}
											/>
											<Tooltip_
												title="Assign the minimum number of Readyswap users to scan for a token. Once this number is reached, the FOMO trigger is set and you will also start to scan with this wallet address, and will enter on launch with the ETH amount set below."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Max FOMO Triggers"
												id="maxFomoTx"
												value={fileds.maxFomoTx}
												onChange={(e) =>
													setVar({
														maxFomoTx:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="Set the number of FOMO target new launch token buys you want to attempt.	Eg, set 4 and you will set scan to buy 4 tokens that hit your FOMO target amount"
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											sm={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="ETH value"
												id="fomoValue"
												value={fileds.fomoValue}
												onChange={(e) =>
													setVar({
														fomoValue:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="The eth value you assign to your FOMO trades."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={6}
											className="contentCenter swapmain "
											style={{ justifyContent: "end" }}
										>
											<div className="swap_autoset_Icon">
												<IconButton
													color="primary"
													onClick={handleOpenDialog}
												>
													<SettingsIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
									<div className="fomoText">
										<p>
											Disclaimer : The user acknowledges
											that they have reviewed their FOMO
											settings correctly.
										</p>
										<p>
											The user has made sure they can
											afford the maximum exposure.
											Calculate the # of potential FOMO
											trade entries you want to enter
											multiplied by, the ETH amount AND
											allow for a very generous gas
											consumption.
										</p>
										<p>
											The FOMO trigger to enter into a
											token swapping at launch, is based
											upon the number of other Readyswap
											users who want to enter the trade.
										</p>
										<p>
											The crowd could be 'smart' or 'dumb'
											and the token grabbing Readyswap
											user attention could be hyped in the
											market, or turn out to be a
											scam/rug/honeypot, high buy or sell
											taxes, or a token that blacklists
											wallet address.
										</p>
										<p>
											FOMO trades can also capture the
											wisdom of the crowd and capture
											significant upside potential on
											early token entries. The user
											accepts and acknowledges these
											risks, as well as the potential
											rewards, when using FOMO
											functionality.
										</p>
									</div>
								</div>
											</TabPanel>*/}
							<TabPanel value="2">
								<div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Value"
												id="aValue"
												value={fileds.aValue}
												onChange={(e) =>
													setVar({
														aValue: e.target.value,
													})
												}
											/>
											<Tooltip_
												title="The Base pair value you are supplying for the trade."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={4}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Max TX Limit"
												id="aMaxLimit"
												value={fileds.aMaxLimit}
												onChange={(e) =>
													setVar({
														aMaxLimit:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="Set your max transcation amount here what you would like to allow to your auto trader to hit. (including reverted transactions too)"
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>

										<Grid
											item
											xs={2}
											className="contentCenter swapmain "
											style={{ justifyContent: "end" }}
										>
											<div className="swap_autoset_Icon">
												<IconButton
													color="primary"
													onClick={handleOpenASDialog}
												>
													<SettingsIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={6}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Tip (ETH Value)"
												id="atip"
												value={fileds.atip}
												onChange={(e) =>
													setVar({
														atip: e.target.value,
													})
												}
											/>
											<Tooltip_
												title={
													<div>
														Select the amount of eth
														you are willing to pay
														for the miner to
														prioritise your
														transaction!
														<p>
															REMINDER: If you pay
															more you have better
															slot!
														</p>
														Make sure you have
														sufficient amount of eth
														in your wallet to
														perform the transaction!
													</div>
												}
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
									</Grid>
									<div className="autoTradeParams">
										<Grid
											container
											spacing={1}
											className="dialog_form"
										>
											<Grid
												item
												xs={6}
												className="contentCenter"
											>
												<FormControlLabel
													className="delayTypeLabel"
													control={
														<Paper
															elevation={3}
															sx={{
																p: "2px 4px",
																display: "flex",
																alignItems:
																	"center",
															}}
															className="selectField_form"
														>
															<Select
																id="network-select"
																value={
																	fileds.aSignals
																}
																onChange={(e) =>
																	handleChangeSignal(
																		e
																	)
																}
																renderValue={(
																	selected
																) =>
																	selected.join(
																		", "
																	)
																}
																MenuProps={
																	MenuProps
																}
															>
																{_signals.map(
																	(m, i) => (
																		<MenuItem
																			key={
																				i
																			}
																			value={
																				m.value
																			}
																		>
																			<Checkbox
																				checked={
																					fileds
																						.aSignals
																						.length >
																					0
																						? fileds.aSignals.indexOf(
																								m.value
																						  ) >
																						  -1
																						: false
																				}
																			/>
																			<ListItemText
																				primary={
																					m.name
																				}
																			/>
																		</MenuItem>
																	)
																)}
															</Select>
														</Paper>
													}
													label="Signal"
													labelPlacement="top"
												/>

												<Tooltip_
													title=""
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
											{fileds.aSignals.indexOf(
												"METADROP"
											) === -1 ? (
												<Grid
													item
													xs={6}
													className="contentCenter"
												>
													<FormControlLabel
														className="delayTypeLabel"
														control={
															<CssTextField
																className="formInput"
																id="aVolume"
																value={
																	fileds.aVolume
																}
																onChange={(e) =>
																	setVar({
																		aVolume:
																			e
																				.target
																				.value,
																	})
																}
															/>
														}
														label="TX Volume"
														labelPlacement="top"
													/>
													<Tooltip_
														title="Set the amount of transaction what you would like to scan for activating the Auto trader: Eg. 50transaction in mempool the auto buy function will proceed with the buy tx."
														enterTouchDelay={0}
													>
														<InfoIcon className="infoIcon" />
													</Tooltip_>
												</Grid>
											) : (
												<>
													<Grid
														item
														xs={3}
														className="contentCenter"
													>
														<FormControlLabel
															className="antiMEV"
															control={
																<Switch
																	color="primary"
																	id="amaxTx"
																	checked={
																		fileds.amaxTx
																	}
																	onChange={(
																		e
																	) =>
																		setVar({
																			amaxTx: e
																				.target
																				.checked,
																		})
																	}
																/>
															}
															label="Max TX"
															labelPlacement="top"
														/>
														<Tooltip_
															title="If you wish to buy the maximum allowed wallet size on the metadrop token use this function."
															enterTouchDelay={0}
														>
															<InfoIcon className="infoIcon" />
														</Tooltip_>
													</Grid>
													<Grid
														item
														xs={3}
														className="contentCenter"
													>
														<FormControlLabel
															className="antiMEV"
															control={
																<Switch
																	color="primary"
																	id="only_cr_addlp"
																	checked={
																		fileds.only_cr_addlp
																	}
																	onChange={(
																		e
																	) =>
																		setVar({
																			only_cr_addlp:
																				e
																					.target
																					.checked,
																		})
																	}
																/>
															}
															label="createERC20"
															labelPlacement="top"
														/>
														<Tooltip_
															title="Use this button in case you wish to buy only those Metadrop tokens where the createERC20 method being called first. Any other Method eg.: 'Add Initial Liquidity' will be skipped!t"
															enterTouchDelay={0}
														>
															<InfoIcon className="infoIcon" />
														</Tooltip_>
													</Grid>
												</>
											)}
											<Grid
												item
												xs={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Name"
													id="aName"
													value={fileds.aName}
													onChange={(e) =>
														setVar({
															aName: e.target
																.value,
														})
													}
												/>
												<Tooltip_
													title=""
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
											<Grid
												item
												xs={6}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Symbol"
													id="aSymbol"
													value={fileds.aSymbol}
													onChange={(e) =>
														setVar({
															aSymbol:
																e.target.value,
														})
													}
												/>
												<Tooltip_
													title=""
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
											<Grid
												item
												xs={2}
												className="contentCenter"
											>
												<FormControlLabel
													className="antiMEV"
													control={
														<Switch
															color="primary"
															id="aBuyTax"
															checked={
																fileds.aBuyTax
															}
															onChange={(e) =>
																setVar({
																	aBuyTax:
																		e.target
																			.checked,
																})
															}
														/>
													}
													label="Buy Tax"
													labelPlacement="top"
												/>
												<Tooltip_
													title="Select the range of tax what you would like to scan with the auto trader"
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>

											<Grid item xs={10} sm={10}>
												<br />
												<Slider
													getAriaLabel={() =>
														"Buy tax"
													}
													value={fileds.aBuyTaxNum}
													onChange={(e, newValue) =>
														setVar({
															aBuyTaxNum:
																newValue,
														})
													}
													valueLabelDisplay="on"
													min={0}
													max={50}
													disabled={!fileds.aBuyTax}
													className="tradeSlider"
												/>
											</Grid>

											<Grid
												item
												xs={2}
												className="contentCenter"
											>
												<FormControlLabel
													className="antiMEV"
													control={
														<Switch
															color="primary"
															id="aSellTax"
															checked={
																fileds.aSellTax
															}
															onChange={(e) =>
																setVar({
																	aSellTax:
																		e.target
																			.checked,
																})
															}
														/>
													}
													label="Sell Tax"
													labelPlacement="top"
												/>
												<Tooltip_
													title="Select the range of tax what you would like to scan with the auto trader"
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>

											<Grid item xs={10} sm={10}>
												<br />
												<Slider
													getAriaLabel={() =>
														"Sell tax"
													}
													value={fileds.aSellTaxNum}
													onChange={(e, newValue) =>
														setVar({
															aSellTaxNum:
																newValue,
														})
													}
													valueLabelDisplay="on"
													min={0}
													max={50}
													disabled={!fileds.aSellTax}
													className="tradeSlider"
												/>
											</Grid>
											{fileds.aSignals.indexOf(
												"METADROP"
											) === -1 && (
												<Grid
													item
													xs={6}
													className="contentCenter"
												>
													<CssTextField
														className="formInput"
														label="CheckSum"
														id="aCheckSum"
														value={fileds.aCheckSum}
														onChange={(e) =>
															setVar({
																aCheckSum:
																	e.target
																		.value,
															})
														}
													/>
													<Tooltip_
														title="Enter multiple conditions separated by comma (,)"
														enterTouchDelay={0}
													>
														<InfoIcon className="infoIcon" />
													</Tooltip_>
												</Grid>
											)}
										</Grid>
									</div>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={5}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Tip (ETH Value)"
												id="satip"
												value={fileds.satip}
												onChange={(e) =>
													setVar({
														satip: e.target.value,
													})
												}
											/>
											<Tooltip_
												title={
													<div>
														Select the amount of eth
														you are willing to pay
														for the miner to
														prioritise your
														transaction!
														<p>
															REMINDER: If you pay
															more you have better
															slot!
														</p>
														Make sure you have
														sufficient amount of eth
														in your wallet to
														perform the transaction!
													</div>
												}
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
									</Grid>
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid
											item
											xs={5}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Stop Loss Amount"
												id="aStopLossAmount"
												value={fileds.aStopLossAmount}
												error={
													fileds.aStopLossAmount <=
														0 ||
													fileds.aStopLossAmount > 100
														? !!fileds.aStopLossAmount
															? true
															: false
														: false
												}
												type="number"
												onChange={(e) =>
													setVar({
														aStopLossAmount:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={5}
											className="contentCenter"
										>
											<CssTextField
												className="formInput"
												label="Stop Loss %"
												id="aStopLoss"
												value={fileds.aStopLoss}
												error={
													fileds.aStopLoss >= 0
														? !!fileds.aStopLoss
															? true
															: false
														: false
												}
												type="number"
												onChange={(e) =>
													setVar({
														aStopLoss:
															e.target.value,
													})
												}
											/>
											<Tooltip_
												title="A stop loss is a type of order that trader use to limit their potential losses in the token. It works by automatically selling a security when its price reaches a certain level, known as the stop price. This helps traders avoid larger losses if the price of the security continues to drop."
												enterTouchDelay={0}
											>
												<InfoIcon className="infoIcon" />
											</Tooltip_>
										</Grid>
										<Grid
											item
											xs={2}
											className="contentCenter swapmain "
											style={{
												justifyContent: "end",
											}}
										>
											<div className="swap_autoset_Icon">
												<IconButton
													color="primary"
													onClick={
														handleAddInnerTaskAuto
													}
												>
													<AddBoxIcon />
												</IconButton>
											</div>
										</Grid>
									</Grid>
									{fileds.aInnerTasks.map((m, i) => (
										<Grid
											key={i}
											container
											spacing={1}
											className="dialog_form"
										>
											<Grid
												item
												xs={5}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label="Take Profit Amount %"
													id="highP"
													error={
														m.takeProfitAmount <=
															0 ||
														m.takeProfitAmount > 100
															? !!m.takeProfitAmount
																? true
																: false
															: false
													}
													value={m.takeProfitAmount}
													type="number"
													InputProps={{
														inputProps: {
															min: 0,
															max: 100,
														},
													}}
													onChange={(e) => {
														let t = [];
														for (
															let index = 0;
															index <
															fileds.aInnerTasks
																.length;
															index++
														) {
															let taskI =
																fileds
																	.aInnerTasks[
																	index
																];
															if (index === i) {
																taskI.takeProfitAmount =
																	e.target.value;
															}
															t.push(taskI);
														}
														setVar({
															aInnerTasks: [...t],
														});
													}}
												/>
												<Tooltip_
													title="What percentage of your tokens are you allocating to the task on a SELL transaction and ETH value on a BUY transaction."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
											<Grid
												item
												xs={5}
												className="contentCenter"
											>
												<CssTextField
													className="formInput"
													label={"Take Profit %"}
													id="highP"
													error={
														m.takeProfit <= 0
															? !!m.takeProfit
																? true
																: false
															: false
													}
													value={m.takeProfit}
													type="number"
													onChange={(e) => {
														let t = [];
														for (
															let index = 0;
															index <
															fileds.aInnerTasks
																.length;
															index++
														) {
															let taskI =
																fileds
																	.aInnerTasks[
																	index
																];
															if (index === i) {
																taskI.takeProfit =
																	e.target.value;
															}
															t.push(taskI);
														}
														setVar({
															aInnerTasks: [...t],
														});
													}}
												/>
												<Tooltip_
													title="A Take Profit (TP) order is a type of trading order that instructs a trader to close a position once the market reaches a specified profit level."
													enterTouchDelay={0}
												>
													<InfoIcon className="infoIcon" />
												</Tooltip_>
											</Grid>
											<Grid
												item
												xs={2}
												className="contentCenter swapmain "
												style={{
													justifyContent: "end",
												}}
											>
												{i > 0 && (
													<div className="swap_autoset_Icon">
														<IconButton
															color="primary"
															onClick={() =>
																handleDeleteInnerTaskAuto(
																	i
																)
															}
														>
															<DeleteIcon />
														</IconButton>
													</div>
												)}
											</Grid>
										</Grid>
									))}
								</div>
							</TabPanel>
						</TabContext>
					</DialogContent>
					<DialogActions>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={(e) => {
									handleSave(e);
								}}
							>
								{!!fileds.tid ? "Edit" : "Save"}
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogAddTrades;

import React, { useState, useEffect } from "react";
import {
	bgBG,
	DataGrid,
	GridToolbarContainer,
	GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import dayjs from "dayjs";
import axiosI from "../api/api";
import relativeTime from "dayjs/plugin/relativeTime";
import IconButton from "@mui/material/IconButton";
import { EtherScan, BscScan, DexScreener } from "./Icons";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Stack from "@mui/material/Stack";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DialogSellPanel from "./DialogSellPanel";
import SortIcon from "@mui/icons-material/Sort";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddTaskIcon from "@mui/icons-material/AddTask";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Tooltip_ } from "./Tooltip";
import Zoom from "@mui/material/Zoom";
import humanFormat from "human-format";
import DialogAlert from "./DialogAlert";
import DialogAddTrades from "./DialogAddTrades";
import SubtitlesOffIcon from "@mui/icons-material/SubtitlesOff";
import SubtitlesIcon from "@mui/icons-material/Subtitles";
import {
	getSortCode,
	callLink,
	callTXLink,
	callNewSite,
	readableNumber,
	priceFormat,
	numberFormat,
} from "../utils/functions";

const CustomToolbar = () => {
	return (
		<GridToolbarContainer className="tableToolbar2_">
			<GridToolbarQuickFilter className="quickFilter " />
		</GridToolbarContainer>
	);
};
function NoRowsOverlay() {
	return (
		<Stack height="100%" alignItems="center" justifyContent="center">
			No tasks are currently active
		</Stack>
	);
}
const _type = [
	{
		value: "BUY",
		name: "BUY",
	},
	{
		value: "SELL",
		name: "SELL",
	},
];
const _task = [
	{
		value: "PRICE",
		name: "Token Price",
	},
	{
		value: "MC",
		name: "Market Cap",
	},
	{
		value: "PROFIT",
		name: "Profit",
	},
];
const _pairs = [
	{
		value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		name: "ETH",
	},
	{
		value: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
		name: "DAI",
	},
	{
		value: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
		name: "USDT",
	},
	{
		value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		name: "USDC",
	},
];
const TasksPanel = ({
	fileds,
	setVar,
	data,
	openTrade,
	handleLoadTasks,
	handleDeleteTask,
	publicKey,
}) => {
	dayjs.extend(relativeTime);
	const [open, setOpen] = useState(false);
	const [pageSize, setPageSize] = useState(10);
	const [openPanel, setOpenPanel] = useState(true);
	const [_data, _setData] = useState([]);
	const [historyData, setHistoryData] = useState([]);
	const [openClosed, setOpenClosed] = useState(false);
	const [openDelete, setOpenDelete] = useState(false);
	const [id, setId] = useState("");
	const [type, setType] = useState("");
	useEffect(() => {
		if (!!data.tasks || !!data.autoSnipeTasks) {
			let d = [];
			if (!!data.tasks) {
				d = [...data.tasks];
			}
			if (!!data.autoSnipeTasks) {
				d = [...d, ...data.autoSnipeTasks];
			}

			_setData(d);
		} else {
			_setData([]);
		}

		//console.log("autoSnipeTasks", data);
	}, [data]);

	const handleOpenDialog = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		openTrade();
	};
	const handleLoadData = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		handleLoadTasks();
	};
	const handleOpenAlertDialog = (e, id, _type) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setId(id);
		setType(_type);
		setOpenDelete(true);
	};

	const handleChangePanel = (e, newValue) => {
		setOpenPanel(newValue);
	};

	const deleteTask = () => {
		handleDeleteTask(parseInt(id), type);
		setOpenDelete(false);
	};
	const editTask = (e, task) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		let tab = "1";
		if (task.task_type === "FOMO") tab = "2";
		if (task.type === "AUTOTRADE" || task.type === "METADROP") {
			tab = "2";
			axiosI
				.post("/api/getAutoTask", { id: task.id })
				.then(async (res) => {
					try {
						const t =
							res.data.data.task.length > 0
								? res.data.data.task[0]
								: [];
						const s = res.data.data.subTasks;

						if (t.length === 0) return false;

						let stop = "";
						let stopAmount = "";
						let takeProfit = [
							{
								takeProfit: "",
								takeProfitAmount: "",
							},
						];
						//console.log("s", s);
						for (let index = 0; index < s.length; index++) {
							const w = s[index];
							if (w.perc < 0) {
								stopAmount = w.num;
								stop = w.perc * 100;
							} else {
								if (!!!takeProfit[0].takeProfit) {
									takeProfit[0].takeProfit = w.perc * 100;
									takeProfit[0].takeProfitAmount = w.num;
								} else {
									takeProfit.push({
										takeProfit: w.perc * 100,
										takeProfitAmount: w.num,
									});
								}
							}
						}

						let d = {
							aslippage: !!t.slippage ? t.slippage : "100",
							amanualGas: !!t.gas_multiple ? false : true,
							amultiple: !!t.gas_multiple ? t.gas_multiple : "10",
							aGasLimit: !!t.gas_gaslimit
								? t.gas_gaslimit
								: "608000",
							aGasPrice: !!t.gas_gasprice ? t.gas_gasprice : "",
							atmaxFeePerGas: !!t.gas_maxfeepergas
								? t.gas_maxfeepergas
								: "",
							amaxPriorityFeePerGas: !!t.gas_priorityfeepergas
								? t.gas_priorityfeepergas
								: "",
							aantiMEV: t.antimev,
							apair: !!t.pair
								? t.pair
								: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
							aswap: !!t.swap ? t.swap : "",
							afee: !!t.fee ? t.fee : "",
							aValue: !!t.value ? t.value : fileds.aValue,
							aType: t.type,
							arouter: t.router,
							aid: t.id,
							anetwork: t.network,
							aSignals: t.signal.split(","),
							aVolume: t.volume,
							aName: t.name,
							aSymbol: t.symbol,
							aCheckSum: t.checksum,
							aMaxLimit: t.max_txs_limit,
							aBuyTax: t.buytax,
							aBuyTaxNum: !!t.buytax
								? [t.buytax_low, t.buytax_high]
								: fileds.aBuyTaxNum,
							aSellTax: t.selltax,
							aSellTaxNum: !!t.selltax
								? [t.selltax_low, t.selltax_high]
								: fileds.aSellTaxNum,

							aStopLossAmount: stopAmount,
							aStopLoss: stop,
							aInnerTasks: [...takeProfit],
							task_tab: tab,
							aauto_approve: t.auto_approve,
							atip: !!t.tip_eth ? t.tip_eth : "",
							amaxTx: t.amount_token == "true" ? true : false,
							only_cr_addlp: t.only_cr_addlp ? true : false,
						};
						console.log("d", d);
						if (s.length > 0) {
							const st = {
								saslippage: !!s[0].slippage
									? s[0].slippage
									: "100",
								samanualGas: !!s[0].gas_multiple ? false : true,
								samultiple: !!s[0].gas_multiple
									? s[0].gas_multiple
									: "10",
								saGasLimit: !!s[0].gas_gaslimit
									? s[0].gas_gaslimit
									: "608000",
								saGasPrice: !!s[0].gas_gasprice
									? s[0].gas_gasprice
									: "",
								samaxFeePerGas: !!s[0].gas_maxfeepergas
									? s[0].gas_maxfeepergas
									: "",
								samaxPriorityFeePerGas: !!s[0]
									.gas_priorityfeepergas
									? s[0].gas_priorityfeepergas
									: "",
								saantiMEV: s[0].antimev,
								satip: s[0].tip_eth,
							};
							d = { ...d, ...st };
						}
						setVar(d);
					} catch (error) {
						console.log("error", error);
					}
				})
				.catch((error) => {});
		} else {
			let d = {
				tcontract: !!task.contract ? task.contract : "",
				tname: !!task.name ? task.name : "",
				tswap: !!task.swap ? task.swap : "",
				tfee: !!task.fee ? task.fee : "",
				tvalue: !!task.value ? task.value : "0.1",
				tamount: "",
				tsellamount: !!task.amount ? task.amount : "100",
				tslippage: !!task.slippage ? task.slippage : "100",
				tmanualGas: !!task.gas_multiple ? false : true,
				tmultiple: !!task.gas_multiple ? task.gas_multiple : "10",
				tGasLimit: !!task.gas_gaslimit ? task.gas_gaslimit : "608000",
				tGasPrice: !!task.gas_gasprice ? task.gas_gasprice : "",
				tmaxFeePerGas: !!task.gas_maxfeepergas
					? task.gas_maxfeepergas
					: "",
				tmaxPriorityFeePerGas: !!task.gas_priorityfeepergas
					? task.gas_priorityfeepergas
					: "",
				tantiMEV: task.antimev,
				tpair: !!task.pair
					? task.pair
					: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
				//tpairToken: "",
				tperc: [task.low_perc * 100, task.high_perc * 100],
				tpercnum: task.perc * 100,
				tp_sl: false,
				tint: !!task.interval
					? task.interval
					: parseFloat(task.low_num) > 0 ||
					  parseFloat(task.high_num) > 0
					? true
					: false,
				stopLoss: task.stoploss,
				ttype: task.type,
				ttask: task.task_type,
				trouter: task.router,

				tid: task.id,
				cond_tsl: task.cond_tsl,
				task_tab: tab,
				showNum: parseFloat(task.num) > 0 ? task.num : "",
				lowShowNum: parseFloat(task.low_num) > 0 ? task.low_num : "",
				highShowNum: parseFloat(task.high_num) > 0 ? task.high_num : "",
				percTask:
					parseFloat(task.low_num) > 0 || !!task.high_num
						? false
						: true,
				limit_protection: task.limit_protection * 100,
				tauto_approve: task.auto_approve,
				ttip: !!task.tip_eth ? task.tip_eth : "",
			};

			if (task.type === "SELL") {
				const g = {
					stslippage: !!task.slippage ? task.slippage : "100",
					stmanualGas: !!task.gas_multiple ? false : true,
					stmultiple: !!task.gas_multiple ? task.gas_multiple : "10",
					stGasLimit: !!task.gas_gaslimit
						? task.gas_gaslimit
						: "608000",
					stGasPrice: !!task.gas_gasprice ? task.gas_gasprice : "",
					stmaxFeePerGas: !!task.gas_maxfeepergas
						? task.gas_maxfeepergas
						: "",
					stmaxPriorityFeePerGas: !!task.gas_priorityfeepergas
						? task.gas_priorityfeepergas
						: "",
					stantiMEV: task.antimev,
				};
				d = { ...d, ...g };
			}

			setVar(d);
		}
		openTrade();
	};
	const showType = (t) => {
		const v = _task.find((f) => f.value === t);
		if (!!v) {
			return v.name;
		} else {
			return t;
		}
	};
	const showStable = (t) => {
		const v = _pairs.find((f) => f.value.toLowerCase() === t.toLowerCase());
		if (!!v) {
			return v.name;
		} else {
			return t;
		}
	};
	const showData = (d) => {
		if (d.task_type === "PROFIT" && !!parseFloat(d.start_profit)) {
			return (
				<span className="tradeValue">
					{parseFloat(d.start_profit).toFixed(6)} {showStable(d.pair)}
				</span>
			);
		}
		if (d.task_type === "PRICE") {
			return (
				<span className="tradeValue">{priceFormat(d.start_price)}</span>
			);
		}
		if (d.task_type === "MC") {
			return (
				<span className="tradeValue">
					{parseInt(d.start_mc) > 0
						? "$" +
						  humanFormat(parseInt(d.start_mc), {
								//scale: tokenScale,
								maxDecimals: "auto",
						  })
						: ""}
				</span>
			);
		}
	};
	const getStopLoss = (perc) => {
		if (!!!perc) return "";

		return "(" + parseInt(perc * 100) + "%)";
	};
	const loadHistory = () => {
		axiosI
			.post("/api/closedusertasks", { wallet: publicKey })
			.then(async (res) => {
				setHistoryData(res.data.data);
			})
			.catch((error) => {
				/* toast.error("Network error! Please refresh the site.");
				if (error.response.data.error.code == 5) {
					navigate("/logout");
				} */
			});
	};
	const handleHistory = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setOpenClosed(true);
		loadHistory();
	};
	const handleData = (e) => {
		e.stopPropagation();
		e.preventDefault();
		e.stopPropagation();
		setOpenClosed(false);
		handleLoadTasks();
	};
	const baseValue = (data) => {
		if (data.task_type === "PRICE") {
			return data.start_price;
		}
		if (data.task_type === "MC") {
			return data.start_mc;
		}
		if (data.task_type === "PROFIT") {
			return data.start_profit;
		}
	};
	const genTiggerData = (data) => {
		let res = { type: "%", value: [], specC: "" };

		if (data.interval) {
			if (parseFloat(data.low_num) > 0 || parseFloat(data.high_num) > 0) {
				res.type = "NUM";
				res.value = [data.low_num, data.high_num];
				res.specC = "<->";
				if (
					parseFloat(data.low_num) > 0 &&
					!parseFloat(data.high_num) > 0
				) {
					res.value = [data.low_num];
					res.specC = "< ";
				}
				if (
					!parseFloat(data.low_num) > 0 &&
					parseFloat(data.high_num) > 0
				) {
					res.value = [data.high_num];
					res.specC = "> ";
				}

				/* res = `${showData(data.low_num * 100)} <-> ${showData(
					data.high_num * 100
				)}`; */
			} else {
				res.type = "%";
				res.value = [data.low_perc * 100, data.high_perc * 100];
				res.specC = `${getStopLoss(data.stoploss_perc)} <->`;
				/* res = `${data.low_perc * 100}% ${getStopLoss(
					data.stoploss_perc
				)} <-> ${data.high_perc * 100}%`; */
			}
		} else {
			if (parseFloat(data.low_num) > 0 || parseFloat(data.high_num) > 0) {
				res.type = "NUM";

				if (
					parseFloat(data.low_num) > 0 &&
					parseFloat(data.high_num) > 0
				) {
					res.value = [data.low_num, data.low_num];
					res.specC = "<->";
				} else {
					if (
						parseFloat(data.low_num) > 0 &&
						!parseFloat(data.high_num) > 0
					) {
						res.value = [data.low_num];
						res.specC = "< ";
					}
					if (
						!parseFloat(data.low_num) > 0 &&
						parseFloat(data.high_num) > 0
					) {
						res.value = [data.high_num];
						res.specC = "> ";
					}
				}
			} else {
				if (parseFloat(data.num) > 0) {
					res.type = "NUM";
					res.value = [data.num];
					if (parseFloat(data.num) > parseFloat(baseValue(data))) {
						//res = numberFormat(data.num, "> ");
						res.specC = "> ";
					} else {
						res.specC = "< ";
					}
				} else {
					res.type = "%";
					res.value = [data.perc * 100];
				}
			}
		}

		return res;
	};
	const columns = [
		{
			field: "name",
			headerName: "Token",
			flex: 1,
			minWidth: 130,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";
				if (!!params.row.type) {
					if (params.row.type === "BUY") {
						bg = "bgGreen";
					}
					if (params.row.type === "SELL") {
						bg = "bgRed";
					}
				}
				if (params.row.status === 1 && params.row.parent > 0) {
					bg += " subTask";
				}
				if (
					params.row.type === "AUTOTRADE" ||
					params.row.type === "METADROP"
				) {
					bg += " autoS autoSM";
				}

				return bg;
			},
			renderCell: (params) => {
				return (
					<div>
						{params.row.type === "AUTOTRADE" ||
						params.row.type === "METADROP" ? (
							<div>
								<div>
									<span>Auto Trade</span>
								</div>
								<div>
									<span>
										Value: {params.row.value} ETH | 💸 Tip
										ETH: {params.row.tip_eth}
									</span>
								</div>
							</div>
						) : params.row.task_type === "FOMO" ? (
							<div>
								<div>
									<span>
										Fomo Volume: {params.row.volume}
									</span>
								</div>
								<div>
									<span>
										Max FOMO Triggers:{" "}
										{params.row.task_value}
									</span>
								</div>
							</div>
						) : (
							<div>
								<div>
									<span>{params.row.name}</span>
								</div>
								<div>
									{getSortCode(params.row.contract)}

									<span>
										<span
											onClick={(e) => {
												e.stopPropagation();
												callLink(
													fileds.network,
													params.row.contract
												);
											}}
											className="iconpos"
										>
											{fileds.network === "ETH" ? (
												<EtherScan />
											) : (
												<BscScan />
											)}
										</span>
										<IconButton
											onClick={() =>
												callNewSite(
													`https://dexscreener.com/ethereum/${params.row.contract.toLowerCase()}`
												)
											}
											color="primary"
											style={{
												padding: "0px 10px",
											}}
										>
											<DexScreener width={20} />
										</IconButton>
									</span>
								</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "id",
			headerName: "",
			flex: 1,
			minWidth: 100,
			maxWidth: 100,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";

				if (
					params.row.type === "AUTOTRADE" ||
					params.row.type === "METADROP"
				) {
					bg += " autoS";
				}

				return bg;
			},
			renderCell: (params) => {
				return (
					<div>
						<div>
							<Tooltip_
								TransitionComponent={Zoom}
								title={
									<span>
										{dayjs(params.row.ts).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</span>
								}
							>
								<span>{dayjs(params.row.ts).fromNow()}</span>
							</Tooltip_>
						</div>
						{params.row.type === "AUTOTRADE" ||
						params.row.type === "METADROP" ? (
							<div>{params.row.signal}</div>
						) : (
							<div>{showType(params.row.task_type)}</div>
						)}
					</div>
				);
			},
		},

		{
			field: "value",
			headerName: "Data",
			flex: 1,
			minWidth: 160,
			maxWidth: 160,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";

				if (
					params.row.type === "AUTOTRADE" ||
					params.row.type === "METADROP"
				) {
					bg += " autoS";
				}

				return bg;
			},
			renderCell: (params) => {
				const res = genTiggerData(params.row);
				let t;
				if (!!res) {
					if (res.value.length === 2) {
						t =
							priceFormat(res.value[0]) +
							res.specC +
							priceFormat(res.value[2]);
					}
				}

				return (
					<div>
						{params.row.type === "AUTOTRADE" ||
						params.row.type === "METADROP" ? (
							<div>
								<div>
									{params.row.type === "METADROP"
										? "Max TX: " +
										  (params.row.amount_token == "true"
												? "✅"
												: "")
										: "Volume: " + params.row.volume}
								</div>
								<div>
									Tax:{" "}
									{`${
										!!params.row.buytax
											? params.row.buytax_high
											: "-"
									}  / ${
										!!params.row.selltax
											? params.row.selltax_high
											: "-"
									}`}
								</div>
							</div>
						) : params.row.task_type === "FOMO" ? (
							<div>
								<div>{params.row.value} ETH</div>
							</div>
						) : (
							<div>
								<div
									className={
										params.row.status === 1
											? "waitTask"
											: ""
									}
								>
									{res.value.length === 2 ? (
										res.type != "%" ? (
											<>
												<span>
													{priceFormat(res.value[0])}
												</span>
												{res.specC}{" "}
												<span>
													{priceFormat(res.value[1])}
												</span>
											</>
										) : (
											<>
												<span>
													{res.value[0].toFixed(2)}%
												</span>
												{res.specC}{" "}
												<span>
													{res.value[1].toFixed(2)}%
												</span>
											</>
										)
									) : res.type != "%" ? (
										<>
											{res.specC}{" "}
											<span>
												{priceFormat(res.value[0])}
											</span>
										</>
									) : (
										<>
											{res.specC}{" "}
											<span>
												{res.value[0].toFixed(2)}%
											</span>
										</>
									)}
								</div>
								<div>{showData(params.row)}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "symbol",
			headerName: "",
			width: 90,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";

				if (
					params.row.type === "AUTOTRADE" ||
					params.row.type === "METADROP"
				) {
					bg += " autoS";
				}

				return bg;
			},
			renderCell: (params) => {
				return (
					<div>
						<div style={{ float: "right" }}>
							<div className="swap_header_Icon">
								<IconButton
									color="primary"
									onClick={(e) => editTask(e, params.row)}
								>
									<EditIcon />
								</IconButton>
							</div>
							<div className="swap_header_Icon">
								<IconButton
									color="primary"
									onClick={(e) =>
										handleOpenAlertDialog(
											e,
											params.row.id,
											params.row.type
										)
									}
								>
									<DeleteIcon />
								</IconButton>
							</div>
						</div>
					</div>
				);
			},
		},
	];
	const columnsHistory = [
		{
			field: "name",
			headerName: "Token",
			flex: 1,
			minWidth: 100,
			disableExport: true,
			cellClassName: (params) => {
				let bg = "";
				if (!!params.row.type) {
					if (params.row.type === "BUY") {
						bg = "bgGreen";
					}
					if (params.row.type === "SELL") {
						bg = "bgRed";
					}
				}
				return bg;
			},
			renderCell: (params) => {
				return (
					<div>
						{params.row.task_type === "FOMO" ? (
							<div>
								<div>
									<span>
										Fomo Volume: {params.row.volume}
									</span>
								</div>
								<div>
									<span>
										Max FOMO Triggers:{" "}
										{params.row.task_value}
									</span>
								</div>
							</div>
						) : (
							<div>
								<div>
									<span>{params.row.name}</span>
								</div>
								<div>
									{getSortCode(params.row.contract)}

									<span>
										<span
											onClick={(e) => {
												e.stopPropagation();
												callLink(
													fileds.network,
													params.row.contract
												);
											}}
											className="iconpos"
										>
											{fileds.network === "ETH" ? (
												<EtherScan />
											) : (
												<BscScan />
											)}
										</span>
										<IconButton
											onClick={() =>
												callNewSite(
													`https://dexscreener.com/ethereum/${params.row.contract.toLowerCase()}`
												)
											}
											color="primary"
											style={{
												padding: "0px 10px",
											}}
										>
											<DexScreener width={20} />
										</IconButton>
									</span>
								</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "id",
			headerName: "",
			flex: 1,
			minWidth: 100,
			maxWidth: 100,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						<div>
							<Tooltip_
								TransitionComponent={Zoom}
								title={
									<span>
										{dayjs(params.row.ts).format(
											"YYYY-MM-DD HH:mm:ss"
										)}
									</span>
								}
							>
								<span>{dayjs(params.row.ts).fromNow()}</span>
							</Tooltip_>
						</div>
						<div>{showType(params.row.task_type)}</div>
					</div>
				);
			},
		},

		{
			field: "value",
			headerName: "Data",
			flex: 1,
			minWidth: 160,
			maxWidth: 160,
			disableExport: true,
			renderCell: (params) => {
				const res = genTiggerData(params.row);
				let t;
				if (!!res) {
					if (res.value.length === 2) {
						t =
							priceFormat(res.value[0]) +
							res.specC +
							priceFormat(res.value[2]);
					}
				}
				return (
					<div>
						{params.row.task_type === "FOMO" ? (
							<div>
								<div>{params.row.value} ETH</div>
							</div>
						) : (
							<div>
								<div>
									{res.value.length === 2 ? (
										res.type != "%" ? (
											<>
												<span>
													{priceFormat(res.value[0])}
												</span>
												{res.specC}{" "}
												<span>
													{priceFormat(res.value[1])}
												</span>
											</>
										) : (
											<>
												<span>
													{res.value[0].toFixed(2)}%
												</span>
												{res.specC}{" "}
												<span>
													{res.value[1].toFixed(2)}%
												</span>
											</>
										)
									) : res.type != "%" ? (
										<>
											{res.specC}{" "}
											<span>
												{priceFormat(res.value[0])}
											</span>
										</>
									) : (
										<>
											{res.specC}{" "}
											<span>
												{res.value[0].toFixed(2)}%
											</span>
										</>
									)}
								</div>
								<div>{showData(params.row)}</div>
							</div>
						)}
					</div>
				);
			},
		},
		{
			field: "symbol",
			headerName: "",
			width: 140,
			disableExport: true,
			renderCell: (params) => {
				return (
					<div>
						<div style={{ float: "right" }}>
							{params.row.status === -1 &&
								!!params.row.txhash && (
									<div className="error_">ERROR</div>
								)}
							{params.row.status === 2 && (
								<div className="success_">SUCCESS</div>
							)}
							{params.row.txhash && (
								<div>
									{getSortCode(params.row.contract)}
									<span>
										<span
											onClick={(e) => {
												e.stopPropagation();
												callTXLink(
													fileds.network,
													params.row.txhash
												);
											}}
											className="iconpos"
										>
											{fileds.network === "ETH" ? (
												<EtherScan />
											) : (
												<BscScan />
											)}
										</span>
									</span>
								</div>
							)}
						</div>
					</div>
				);
			},
		},
	];
	return (
		<Accordion expanded={openPanel} onChange={handleChangePanel}>
			<AccordionSummary
				expandIcon={<ExpandMoreIcon />}
				aria-controls="panel1a-content"
				className="accordion-header"
			>
				<div className="buy_panel_header">
					<div>TASKS</div>
					<div></div>
					<div className="buy_panel_header_buttons">
						<div className="swap_header_Icon">
							{!openClosed ? (
								<IconButton
									color="primary"
									onClick={(e) => handleHistory(e)}
								>
									<SubtitlesOffIcon />
								</IconButton>
							) : (
								<IconButton
									color="primary"
									onClick={(e) => handleData(e)}
								>
									<SubtitlesIcon />
								</IconButton>
							)}
						</div>
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handleLoadData}
							>
								<RestartAltIcon />
							</IconButton>
						</div>
						<div className="swap_header_Icon">
							<IconButton
								color="primary"
								onClick={handleOpenDialog}
							>
								<AddTaskIcon />
							</IconButton>
						</div>
					</div>
				</div>
			</AccordionSummary>
			<AccordionDetails className="tasks_cont ">
				<DialogSellPanel
					open={open}
					setOpen={setOpen}
					fileds={fileds}
					setVar={setVar}
				/>
				<DialogAlert
					open={openDelete}
					setOpen={setOpenDelete}
					title="Delete Task"
					msg="Are you sure you want to delete this task?"
					handleMethod={deleteTask}
				/>
				<div className="tasks_cont">
					<DataGrid
						className="tableDG"
						rows={openClosed ? historyData : _data}
						columns={openClosed ? columnsHistory : columns}
						pageSize={pageSize}
						onPageSizeChange={(newPageSize) =>
							setPageSize(newPageSize)
						}
						rowsPerPageOptions={[10, 25, 50, 100]}
						disableColumnMenu
						disableSelectionOnClick
						getRowClassName={(params) =>
							params.indexRelativeToCurrentPage % 2 === 0
								? "table_even"
								: "table_odd"
						}
						headerHeight={0}
						components={{
							Toolbar: CustomToolbar,
							NoRowsOverlay,
						}}
					/>
				</div>
			</AccordionDetails>
		</Accordion>
	);
};

export default TasksPanel;

import React from "react";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { Tooltip_ } from "./Tooltip";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";

const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
const _swap_ = [
	{
		value: "V2",
		name: "V2",
		router: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
	},
	{
		value: "V3",
		name: "V3",
		router: "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
	},
];
const _fee_ = [
	{
		value: "10000",
		name: "1%",
	},
	{
		value: "3000",
		name: "0.3%",
	},
	{
		value: "500",
		name: "0.05%",
	},
];
const _pairs = [
	{
		value: "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
		name: "WETH",
	},
	{
		value: "0x6B175474E89094C44Da98b954EedeAC495271d0F",
		name: "DAI",
	},
	{
		value: "0xdAC17F958D2ee523a2206206994597C13D831ec7",
		name: "USDT",
	},
	{
		value: "0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48",
		name: "USDC",
	},
];
const DialogAutoTradeSettings = ({ open, setOpen, fileds, setVar }) => {
	const handleClose = () => {
		setOpen(false);
	};
	const handleSwap = (value) => {
		if (!!!value) {
			setVar({
				arouter: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
				aswap: "",
				afee: "",
			});
			return true;
		}
		const ff = _swap_.find((f) => f.value === value);
		if (!!ff) {
			setVar({
				arouter: ff.router,
				aswap: ff.value,
				afee: ff.value === "V2" ? "" : fileds.afee,
			});
		} else {
			setVar({
				arouter: "0x7a250d5630b4cf539739df2c5dacb4c659f2488d",
				aswap: "",
				afee: "",
			});
		}
	};
	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ buyPanelDialog"
				>
					<DialogContent>
						<h4>Buy Settings</h4>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Slippage"
									id="aslippage"
									value={fileds.aslippage}
									onChange={(e) =>
										setVar({
											aslippage: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="Slippage is the difference between the expected price of an order and the price when the order actually executes. By default set to 100% means you accept any amount of token. For new launches, it is common to have 100% slippage, but you can change it to suit your risk profile"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="GasLimit"
									id="aGasLimit"
									value={fileds.aGasLimit}
									onChange={(e) =>
										setVar({
											aGasLimit: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="'Gas limit' is the maximum amount of work you're estimating a validator will do on a particular transaction. By default set 521k, but you can set it as you would like. Do not set it too low, as transaction will not be processed and the transaction will fail."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="buytokenLabel"
									control={
										<Switch
											color="primary"
											checked={fileds.amanualGas}
											onChange={(e) =>
												setVar({
													amanualGas:
														e.target.checked,
												})
											}
										/>
									}
									label="Manual Gas"
									labelPlacement="top"
								/>
								<Tooltip_
									title=" Use manual gas if you want to personalize your gas settings. In case you want to use auto set to autogas settings."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							{!fileds.amanualGas && (
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="Gas %"
										id="amultiple"
										value={fileds.amultiple}
										onChange={(e) =>
											setVar({
												amultiple: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas%  Using the gas multiplier, you can increase the actual gas price value you want to pay to process your swap transaction with an added percentage amount."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							)}
						</Grid>
						{fileds.amanualGas && (
							<Grid container spacing={1} className="dialog_form">
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="GasPrice"
										id="aGasPrice"
										value={fileds.aGasPrice}
										onChange={(e) =>
											setVar({
												aGasPrice: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas price   General reference for approximate transaction fees. By default, the bot runs with a Gas multiplier so it`s always using the estimated gas of the current block. You can increase this gas further by adding additional extra with % to increase the probability of faster transaction execution."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxFeePerGas"
										id="amaxFeePerGas"
										value={fileds.amaxFeePerGas}
										onChange={(e) =>
											setVar({
												amaxFeePerGas: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxfeepergas  EIP-1559 Transaction. This represents the maximum amount of gas fee a user is willing to pay for a transaction. Set the maximum you are willing to spend for the transaction. With that, you leave room for your gas settings in case the gas spikes suddenly."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxPriorityFeePerGas"
										id="amaxPriorityFeePerGas"
										value={fileds.amaxPriorityFeePerGas}
										onChange={(e) =>
											setVar({
												amaxPriorityFeePerGas:
													e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxpriority feepergas  Miners can be compensated for the computational work by receiving a 'tip'. When you submit a transaction you will also provide this 'tip' in the maxPriorityFeePerGas field. Gas price is the primary method of getting a fast swap execution. Typically maxpriority is used for lower-gas low-priority transactions where immediate execution is not required, but tipping the miner directly increases the probability the swap is still processed."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>
						)}
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<FormControlLabel
									className="delayTypeLabel"
									control={
										<Paper
											elevation={3}
											sx={{
												p: "2px 4px",
												display: "flex",
												alignItems: "center",
											}}
											className="selectField_form"
										>
											<Select
												id="network-select"
												value={fileds.aswap}
												onChange={(event) =>
													handleSwap(
														event.target.value
													)
												}
												label="Uniswap Router"
											>
												<MenuItem value="">-</MenuItem>
												{_swap_.map((m) => (
													<MenuItem
														key={m.value}
														value={m.value}
													>
														{m.name}
													</MenuItem>
												))}
											</Select>
										</Paper>
									}
									label="Uniswap Router"
									labelPlacement="top"
								/>

								<Tooltip_
									title="If the contract address is not using the default V2 Router, and using a V3 Uniswap Router, select V3 here and the associated pool Fee the V3 Router is using, in the box to the right (1 of 3 options)."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>

							<Grid item xs={6} className="contentCenter">
								{fileds.aswap === "V3" && (
									<>
										<FormControlLabel
											className="delayTypeLabel"
											control={
												<Paper
													elevation={3}
													sx={{
														p: "2px 4px",
														display: "flex",
														alignItems: "center",
													}}
													className="selectField_form"
												>
													<Select
														id="network-select"
														value={fileds.afee}
														onChange={(event) =>
															setVar({
																afee: event
																	.target
																	.value,
															})
														}
														label="V3 Router Fee"
													>
														{_fee_.map((m) => (
															<MenuItem
																key={m.value}
																value={m.value}
															>
																{m.name}
															</MenuItem>
														))}
													</Select>
												</Paper>
											}
											label="V3 Router Fee"
											labelPlacement="top"
										/>

										<Tooltip_
											title="Uniswap v3  having multiples swapping fee. Set the correct fee accordingly to the correct v3 pool. Make sure you are selecting the correct before you are buying any token."
											enterTouchDelay={0}
										>
											<InfoIcon className="infoIcon" />
										</Tooltip_>
									</>
								)}
							</Grid>
						</Grid>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<Paper
									elevation={3}
									sx={{
										p: "2px 4px",
										display: "flex",
										alignItems: "center",
									}}
									className="selectField_form"
								>
									<Select
										id="network-select"
										value={fileds.apair}
										onChange={(event) => {
											setVar({
												tpair: event.target.value,
											});
										}}
									>
										{_pairs.map((m) => (
											<MenuItem
												key={m.value}
												value={m.value}
												className="pairItem"
											>
												<span>{m.name}</span>
											</MenuItem>
										))}
									</Select>
								</Paper>
								<Tooltip_
									title="The main token par you want to trade. Most trade with ETH but you can change to trade with USD, USDT, or DAI, if there is a liquidity pool."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="antiMEV"
									control={
										<Switch
											color="primary"
											id="aantiMEV"
											checked={fileds.aantiMEV}
											onChange={(e) =>
												setVar({
													aantiMEV: e.target.checked,
												})
											}
										/>
									}
									label="AntiMEV"
									labelPlacement="top"
								/>
								<Tooltip_
									title="Enabling Anti-Mev changes the node used from a shared private node to a public AntiMev node, sacrificing a little speed, but avoiding the risk of being front-run by MevBot sandwich bots. Typical Mevbots are attractive to zero-tax token launches."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="aauto_approve"
									control={
										<Switch
											color="primary"
											checked={fileds.aauto_approve}
											onChange={(e) =>
												setVar({
													aauto_approve:
														e.target.checked,
												})
											}
										/>
									}
									label="Auto Approve"
									labelPlacement="top"
								/>
								<Tooltip_
									title="Auto Approve [0N/OFF]"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
						</Grid>
						<h4>SL/TP Settings</h4>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="Slippage"
									id="saslippage"
									value={fileds.saslippage}
									onChange={(e) =>
										setVar({
											saslippage: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="Slippage is the difference between the expected price of an order and the price when the order actually executes. By default set to 100% means you accept any amount of token. For new launches, it is common to have 100% slippage, but you can change it to suit your risk profile"
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} className="contentCenter">
								<CssTextField
									className="formInput"
									label="GasLimit"
									id="saGasLimit"
									value={fileds.saGasLimit}
									onChange={(e) =>
										setVar({
											saGasLimit: e.target.value,
										})
									}
									type="number"
								/>
								<Tooltip_
									title="'Gas limit' is the maximum amount of work you're estimating a validator will do on a particular transaction. By default set 521k, but you can set it as you would like. Do not set it too low, as transaction will not be processed and the transaction will fail."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="buytokenLabel"
									control={
										<Switch
											color="primary"
											checked={fileds.samanualGas}
											onChange={(e) =>
												setVar({
													samanualGas:
														e.target.checked,
												})
											}
										/>
									}
									label="Manual Gas"
									labelPlacement="top"
								/>
								<Tooltip_
									title=" Use manual gas if you want to personalize your gas settings. In case you want to use auto set to autogas settings."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							<Grid item xs={6} sm={3} className="contentCenter">
								<FormControlLabel
									className="antiMEV"
									control={
										<Switch
											color="primary"
											id="saantiMEV"
											checked={fileds.saantiMEV}
											onChange={(e) =>
												setVar({
													saantiMEV: e.target.checked,
												})
											}
										/>
									}
									label="AntiMEV"
									labelPlacement="top"
								/>
								<Tooltip_
									title="Enabling Anti-Mev changes the node used from a shared private node to a public AntiMev node, sacrificing a little speed, but avoiding the risk of being front-run by MevBot sandwich bots. Typical Mevbots are attractive to zero-tax token launches."
									enterTouchDelay={0}
								>
									<InfoIcon className="infoIcon" />
								</Tooltip_>
							</Grid>
							{!fileds.samanualGas && (
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="Gas %"
										id="samultiple"
										value={fileds.samultiple}
										onChange={(e) =>
											setVar({
												samultiple: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas%  Using the gas multiplier, you can increase the actual gas price value you want to pay to process your swap transaction with an added percentage amount."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							)}
						</Grid>
						{fileds.samanualGas && (
							<Grid container spacing={1} className="dialog_form">
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="GasPrice"
										id="saGasPrice"
										value={fileds.saGasPrice}
										onChange={(e) =>
											setVar({
												saGasPrice: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Gas price   General reference for approximate transaction fees. By default, the bot runs with a Gas multiplier so it`s always using the estimated gas of the current block. You can increase this gas further by adding additional extra with % to increase the probability of faster transaction execution."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxFeePerGas"
										id="samaxFeePerGas"
										value={fileds.samaxFeePerGas}
										onChange={(e) =>
											setVar({
												samaxFeePerGas: e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxfeepergas  EIP-1559 Transaction. This represents the maximum amount of gas fee a user is willing to pay for a transaction. Set the maximum you are willing to spend for the transaction. With that, you leave room for your gas settings in case the gas spikes suddenly."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
								<Grid item xs={6} className="contentCenter">
									<CssTextField
										className="formInput"
										label="MaxPriorityFeePerGas"
										id="samaxPriorityFeePerGas"
										value={fileds.samaxPriorityFeePerGas}
										onChange={(e) =>
											setVar({
												samaxPriorityFeePerGas:
													e.target.value,
											})
										}
										type="number"
									/>
									<Tooltip_
										title="Maxpriority feepergas  Miners can be compensated for the computational work by receiving a 'tip'. When you submit a transaction you will also provide this 'tip' in the maxPriorityFeePerGas field. Gas price is the primary method of getting a fast swap execution. Typically maxpriority is used for lower-gas low-priority transactions where immediate execution is not required, but tipping the miner directly increases the probability the swap is still processed."
										enterTouchDelay={0}
									>
										<InfoIcon className="infoIcon" />
									</Tooltip_>
								</Grid>
							</Grid>
						)}
					</DialogContent>
					<DialogActions>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogAutoTradeSettings;

export const initMainWallet = {
	address: null,
	network: null,
	nonce: null,
	signature: null,
};

const reducerMainWallet = (state, action) => {
	switch (action.type) {
		case "set":
			return { ...state, ...action.data };
		case "reset":
			return { address: null, network: null };
		default:
			throw new Error();
	}
};

export { reducerMainWallet as default };

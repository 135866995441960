import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axiosI from "../api/api";
import { toast } from "react-toastify";
import AppContext from "../context/AppContext";
import { useDisconnect } from "wagmi";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FormLabel from "@mui/material/FormLabel";
import IconButton from "@mui/material/IconButton";
import AddBoxIcon from "@mui/icons-material/AddBox";
import { styled } from "@mui/material/styles";
import { getSortCode, callNewSite, callLink } from "../utils/functions";
import DialogAlert from "./DialogAlert";
import DialogAddWallet from "./DialogAddWallet";
import DialogCreateWallet from "./DialogCreateWallet";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { EtherScan, BscScan } from "./Icons";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import DialogWallet from "./DialogWallet";

let figy = false;
const DialogWallets = ({ open, setOpen, getUserData, network }) => {
	const navigate = useNavigate();
	const { account } = useContext(AppContext);
	const [openDelete, setOpenDelete] = useState(false);
	const [tab, setTab] = useState("1");
	const [ref, setRef] = useState({});
	const [openNewWallet, setOpenNewWallet] = useState(false);
	const [openCrWallet, setOpenCrWallet] = useState(false);
	const [openWallet, setOpenWallet] = useState(false);
	const [currentWallet, setCurrentWallet] = useState(null);
	const [wallet, setWallet] = useState();
	const { disconnect } = useDisconnect({
		onError(error) {
			console.log("Error", error);
		},
		onSuccess(data) {
			localStorage.setItem("appopen", false);
			//navigate("/");
			window.location.reload();
		},
	});
	useEffect(() => {
		if (open) {
			setCurrentWallet(null);
			setOpenNewWallet(false);
			setOpenDelete(false);
		}
	}, [open]);
	useEffect(() => {
		if (tab === "2") {
			getref();
		}
	}, [tab]);

	const handleClose = () => {
		setOpen(false);
	};
	const handleDisconnect = () => {
		disconnect();
	};

	const handleDeleteWallet = (wallet) => {
		setCurrentWallet(wallet);
		setOpenDelete(true);
	};

	const deleteWallet = () => {
		axiosI
			.post("/api/removeWallet", {
				publicKey: currentWallet,
			})
			.then((res) => {
				getUserData();
				handleClose();
				toast.success("Wallet removed successfully ");
			})
			.catch((error) => {
				toast.error("Error during remove wallet");
			});
	};
	const newref = () => {
		if (figy) return false;
		figy = true;
		axiosI
			.post("/api/newref", {
				publicKey: currentWallet,
			})
			.then((res) => {
				setRef(res.data.data);
				figy = false;
			})
			.catch((error) => {
				toast.error("Referral error");
				figy = false;
			});
	};
	const getref = () => {
		if (figy) return false;
		figy = true;
		axiosI
			.post("/api/getref", {
				publicKey: currentWallet,
			})
			.then((res) => {
				setRef(res.data.data);
				figy = false;
			})
			.catch((error) => {
				toast.error("Referral error");
				figy = false;
			});
	};
	const handleChangeTab = (event, newValue) => {
		setTab(newValue);
	};
	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		toast.success(`Copy to clipboard: ${text}`);
	};
	const handleOpenWallet = (data) => {
		setOpenWallet(true);
		setWallet(data);
	};
	return (
		<div>
			<DialogWallet
				open={openWallet}
				setOpen={setOpenWallet}
				wallet={wallet}
				setWallet={setWallet}
				network={network}
				getUserData={getUserData}
			/>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ alertDataDialog"
				>
					<DialogAlert
						open={openDelete}
						setOpen={setOpenDelete}
						title="Delete Wallet"
						msg="Are you sure you want to delete this wallet?"
						handleMethod={deleteWallet}
					/>
					<DialogAddWallet
						open={openNewWallet}
						setOpen={setOpenNewWallet}
						getUserData={getUserData}
					/>
					<DialogCreateWallet
						open={openCrWallet}
						setOpen={setOpenCrWallet}
						getUserData={getUserData}
					/>
					<DialogContent>
						<TabContext value={tab}>
							<TabList
								onChange={handleChangeTab}
								variant="scrollable"
								scrollButtons="auto"
								textColor="secondary"
								indicatorColor="secondary"
							>
								<Tab label="Wallets" value="1" />
								<Tab label="Referrals" value="2" />
							</TabList>
							<TabPanel value="1">
								<div className="alertHeader">
									<div></div>
									<div className="walletDialog">
										<div style={{ marginRight: "10px" }}>
											<Button
												variant="contained"
												className="btnNew"
												onClick={() =>
													setOpenCrWallet(true)
												}
												startIcon={<AddBoxIcon />}
											>
												Generate
											</Button>
										</div>
										<div style={{ marginRight: "10px" }}>
											<Button
												variant="contained"
												className="btnNew"
												onClick={() =>
													setOpenNewWallet(true)
												}
												startIcon={<AddBoxIcon />}
											>
												Import
											</Button>
										</div>

										<div className="swap_remove_Icon">
											<IconButton
												color="primary"
												onClick={() =>
													handleDisconnect()
												}
											>
												<ExitToAppIcon />
											</IconButton>
										</div>
									</div>
								</div>

								<div className="boxB">
									{Object.keys(account.wallets).map(
										(wallet, i) => {
											const m = account.wallets[wallet];
											return (
												<Grid
													container
													spacing={1}
													className="dialog_form walletsrow"
													key={i}
													style={{
														alignItems: "center",
													}}
												>
													<Grid
														item
														xs={1}
														className="contentCenter"
													>
														<FormLabel>
															{i + 1}
														</FormLabel>
													</Grid>
													<Grid item xs={12} sm={3}>
														<div className="link_">
															{m.name}
														</div>
														<div className="subString">
															{getSortCode(
																m.snipe_wallet
															)}
															<span
																onClick={(
																	e
																) => {
																	e.stopPropagation();
																	callLink(
																		network,
																		m.snipe_wallet
																	);
																}}
																className="iconpos"
															>
																{network ===
																"ETH" ? (
																	<EtherScan />
																) : (
																	<BscScan />
																)}
															</span>
														</div>
													</Grid>
													<Grid item xs={12} sm={6}>
														<div>
															<span className="walletLabel">
																Balance:
															</span>
															<span className="subString">
																{!!m.balance
																	? `${m.balance} ETH`
																	: ""}
															</span>
														</div>
														<div>
															<span className="walletLabel">
																RS Balance:
															</span>
															<span className="subString">
																{!!m.rsBalance
																	? `${m.rsBalance} ETH`
																	: ""}
															</span>
														</div>
													</Grid>
													<Grid
														item
														xs={12}
														sm={2}
														className="contentCenter"
														style={{
															justifyContent:
																"end",
														}}
													>
														<div
															className="swap_remove_Icon"
															style={{
																marginRight:
																	"10px",
															}}
														>
															<IconButton
																color="primary"
																onClick={() =>
																	handleOpenWallet(
																		m
																	)
																}
															>
																<AccountBalanceWalletIcon />
															</IconButton>
														</div>
														<div className="swap_remove_Icon">
															<IconButton
																color="primary"
																onClick={() =>
																	handleDeleteWallet(
																		m.snipe_wallet
																	)
																}
															>
																<DeleteForeverIcon />
															</IconButton>
														</div>
													</Grid>
												</Grid>
											);
										}
									)}
								</div>
							</TabPanel>
							<TabPanel value="2">
								{!!!ref.ref_code && (
									<div className="alertHeader">
										<div></div>
										<div className="walletDialog">
											<div>
												<Button
													variant="contained"
													className="btnNew"
													onClick={() => newref()}
													startIcon={<AddBoxIcon />}
												>
													New Referral Code
												</Button>
											</div>
										</div>
									</div>
								)}
								<div>
									<h3>
										The ReadySwap Referral Program is as
										follows:
									</h3>
									<ul className="reftext">
										<li>
											All other ref link partners are
											entitled to <b>25%</b> of trading
											fee generated by those who sign up
											via their reflinks
										</li>
										<li>
											Minimum referral awards claim amount
											is <b>0.2 ETH</b>.
										</li>
										<li>
											Currently, referral award claim
											mechanism is manual. To get the
											claim contact{" "}
											<span
												className="link_"
												onClick={() =>
													callNewSite(
														"https://t.me/TomiieB"
													)
												}
											>
												<b>@TomiieB</b>
											</span>{" "}
											on telegram.
										</li>
										<li>
											The referral code only works with
											new user registration. Adding new
											trading wallet does not count!
										</li>
									</ul>
								</div>
								{!!ref.ref_code && (
									<Grid
										container
										spacing={1}
										className="dialog_form"
									>
										<Grid item xs={12}>
											<div>Ref Link:</div>
											<div
												className="boxBR infoColorBox"
												style={{
													display: "inline-flex",
												}}
											>
												<span>{`https://readyswap.app/?ref=${ref.ref_code}`}</span>
												<span
													onClick={(e) => {
														e.stopPropagation();
														copyToClipboard(
															`https://readyswap.app/?ref=${ref.ref_code}`
														);
													}}
													style={{
														marginLeft: "20px",
														marginRight: "20px",
														marginTop: "5px",
													}}
												>
													<ContentCopyIcon
														style={{
															color: "#fff",
															cursor: "pointer",
														}}
													/>
												</span>
											</div>
										</Grid>
										<Grid item xs={12}>
											<div>Ref Link Telegram:</div>
											<div
												className="boxBR infoColorBox"
												style={{
													display: "inline-flex",
												}}
											>
												<span>{`https://t.me/ReadySwap_bot?start=_ref=${ref.ref_code}`}</span>
												<span
													onClick={(e) => {
														e.stopPropagation();
														copyToClipboard(
															`https://t.me/ReadySwap_bot?start=_ref=${ref.ref_code}`
														);
													}}
													style={{
														marginLeft: "20px",
														marginRight: "20px",
														marginTop: "5px",
													}}
												>
													<ContentCopyIcon
														style={{
															color: "#fff",
															cursor: "pointer",
														}}
													/>
												</span>
											</div>
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											className="infoColorBox"
										>
											<div>Wallets:</div>
											<div
												className="boxBRW"
												style={{
													display: "inline-flex",
												}}
											>
												{ref.wallets}
											</div>
										</Grid>
										<Grid
											item
											xs={12}
											sm={6}
											className="infoColorBox"
										>
											<div>Ref ETH:</div>
											<div
												className="boxBRW"
												style={{
													display: "inline-flex",
												}}
											>
												{`${ref.ref_eth} ETH`}
											</div>
										</Grid>
									</Grid>
								)}
							</TabPanel>
						</TabContext>
					</DialogContent>
					<DialogActions>
						<div className="historyBtn">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogWallets;

export const initReCAPTCHA = { key: null, type: null };

const reducerReCAPTCHA = (state, action) => {
	switch (action.type) {
		case "set":
			return { key: action.key, type: action.data };
		case "reset":
			return { key: null };
		default:
			throw new Error();
	}
};

export { reducerReCAPTCHA as default };

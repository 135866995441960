import React, { useState } from "react";
import { useAccount, useNetwork, useSignMessage } from "wagmi";
import axiosI from "../api/api";
import { toast } from "react-toastify";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import { EtherScan, BscScan } from "./Icons";
import { getSortCode, callNewSite, callLink } from "../utils/functions";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import { Tooltip_ } from "./Tooltip";
import { styled } from "@mui/material/styles";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
const CssTextField = styled(TextField)({
	"& label.Mui-focused": {
		color: "#0FA38E",
	},
	"& .MuiInput-underline:after": {
		borderBottomColor: "#0FA38E",
	},
	"& .MuiOutlinedInput-root": {
		"& fieldset": {
			borderColor: "#0FA38E",
		},
		"&:hover fieldset": {
			borderColor: "#0FA38E",
		},
		"&.Mui-focused fieldset": {
			borderColor: "#0FA38E",
		},
	},
});
let figy = false;
const DialogWallet = ({
	open,
	setOpen,
	wallet,
	setWallet,
	network,
	getUserData,
}) => {
	const [deposit, setDeposit] = useState("");
	const [withdraw, setWithdraw] = useState("");
	const [sendEthToAddress, setSendEthToAddress] = useState("");
	const [sendEthValue, setSendEthValue] = useState("");

	const [sendTokenAddress, setSendTokenAddress] = useState("");
	const [sendTokenToAddress, setSendTokenToAddress] = useState("");
	const [sendToken, setSendToken] = useState("");
	const [message, setMessage] = useState("");

	const { data, isError, isLoading, isSuccess, signMessage } = useSignMessage(
		{
			message,
			onSuccess(data, arg) {
				arg.method(data, arg);
			},
			onError(error) {
				console.log("error", error);
			},
		}
	);

	const handleClose = () => {
		setOpen(false);
		setWallet();
		setDeposit("");
		setWithdraw("");
		setSendEthToAddress("");
		setSendEthValue("");
		setSendTokenAddress("");
		setSendTokenToAddress("");
		setSendToken("");
		figy = false;
	};
	const handleDeposit = () => {
		if (figy) return false;
		figy = true;
		if (!!!deposit) return toast.warn("Diposit value is empty");
		axiosI
			.post("/api/deposit", {
				publicKey: wallet.snipe_wallet,
				value: deposit,
				network,
			})
			.then((res) => {
				//console.log("res", res.data.data);
				setDeposit("");
				getUserData();
				figy = false;
			})
			.catch((error) => {
				toast.error("Error deposit");
				figy = false;
			});
	};
	const handleWithdraw = () => {
		if (figy) return false;
		figy = true;
		if (!!!withdraw) return toast.warn("Withdraw value is empty");
		axiosI
			.post("/api/withdraw", {
				publicKey: wallet.snipe_wallet,
				value: withdraw,
				network,
			})
			.then((res) => {
				//console.log("res", res.data.data);
				setWithdraw("");
				getUserData();
				figy = false;
			})
			.catch((error) => {
				toast.error("Error withdraw");
				figy = false;
			});
	};

	const handleSendEth = () => {
		if (!!!sendEthToAddress || !!!sendEthValue)
			return toast.warn("Reminder! All fields are mandatory!");
		const m = `With this signature you accept and confirm that ${wallet.snipe_wallet} wallet sending ${sendEthValue}ETH to ${sendEthToAddress} wallet.`;
		setMessage(m);
		signMessage({ message: m, method: sendEth });
	};

	const handleSendToken = () => {
		if (!!!sendTokenAddress || !!!sendTokenToAddress || !!!sendToken)
			return toast.warn("Reminder! All fields are mandatory!");
		const m = `With this signature you accept and confirm that ${wallet.snipe_wallet} wallet sending ${sendToken} tokens to ${sendTokenToAddress} wallet`;
		setMessage(m);
		signMessage({ message: m, method: transferToken });
	};
	const sendEth = (data, arg) => {
		if (figy) return false;
		figy = true;
		axiosI
			.post("/api/sendEth", {
				publicKey: wallet.snipe_wallet,
				toAddress: sendEthToAddress,
				value: sendEthValue,
				signature: data,
				network,
			})
			.then((res) => {
				//console.log("res", res.data.data);
				setSendEthToAddress("");
				setSendEthValue("");
				getUserData();
				figy = false;
			})
			.catch((error) => {
				toast.error("Error Send");
				figy = false;
			});
	};

	const transferToken = (data, arg) => {
		if (figy) return false;
		figy = true;
		axiosI
			.post("/api/transferToken", {
				publicKey: wallet.snipe_wallet,
				tokenAddress: sendTokenAddress,
				toAddress: sendTokenToAddress,
				amount: sendToken,
				signature: data,
				network,
			})
			.then((res) => {
				//console.log("res", res.data.data);
				setSendTokenAddress("");
				setSendTokenToAddress("");
				setSendToken("");
				getUserData();
				figy = false;
			})
			.catch((error) => {
				toast.error("Error Transfer");
				figy = false;
			});
	};
	const copyToClipboard = (text) => {
		text = text.replace(/;/g, ",");
		navigator.clipboard.writeText(text);
		toast.success(`Copy to clipboard: ${text}`);
	};
	return (
		<div>
			{open && (
				<Dialog
					open={open}
					onClose={handleClose}
					className="dialog_ alertDataDialog"
				>
					<DialogContent>
						<Grid container spacing={1} className="dialog_form">
							<Grid item xs={12} sm={6}>
								<div className="link_">{wallet.name}</div>
								<div className="subString">
									{getSortCode(wallet.snipe_wallet)}
									<span
										onClick={(e) => {
											e.stopPropagation();
											callLink(
												network,
												wallet.snipe_wallet
											);
										}}
										className="iconpos"
									>
										{network === "ETH" ? (
											<EtherScan />
										) : (
											<BscScan />
										)}
									</span>
									<ContentCopyIcon
										style={{
											fontSize: "24px",
											paddingLeft: "5px",
										}}
										onClick={() =>
											copyToClipboard(wallet.snipe_wallet)
										}
									/>
								</div>
							</Grid>
							<Grid item xs={12} sm={6} className="contentRight">
								<div>
									<div>
										<span className="walletLabel">
											Balance:
										</span>
										<span className="subString">
											{!!wallet.balance
												? `${wallet.balance} ETH`
												: ""}
										</span>
									</div>
									<div>
										<span className="walletLabel">
											RS Balance:
										</span>
										<span className="subString">
											{!!wallet.rsBalance
												? `${wallet.rsBalance} ETH`
												: ""}
										</span>
									</div>
								</div>
							</Grid>
						</Grid>
						<div>
							<p>
								To be able to trade with the generated Wallet
								please deposit ETH.
							</p>
						</div>
						<Grid container spacing={1} className="dialog_form">
							<Grid
								item
								xs={12}
								sm={6}
								className="contentCenter swapmain"
							>
								<div className="walletDW">
									<h3>Send ETH</h3>
									<p>Send ETH to the selected Wallet</p>
									<div>
										<CssTextField
											className="formInput"
											label="To Address"
											id="deposit"
											value={sendEthToAddress}
											onChange={(e) =>
												setSendEthToAddress(
													e.target.value
												)
											}
										/>
									</div>
									<div>
										<CssTextField
											className="formInput"
											label="ETH"
											id="deposit"
											value={sendEthValue}
											onChange={(e) =>
												setSendEthValue(e.target.value)
											}
											type="number"
										/>
									</div>
									<div>
										<Button
											variant="contained"
											className="btnNew"
											onClick={handleSendEth}
											disabled={figy}
										>
											Send
										</Button>
									</div>
								</div>
							</Grid>

							<Grid
								item
								xs={12}
								sm={6}
								className="contentCenter swapmain"
							>
								<div className="walletDW">
									<h3>Send Token</h3>
									<p>
										Send token the selected wallet. First
										paste the desired token address to be
										able to send the receiver wallet the the
										token amount selected.
									</p>
									<div>
										<CssTextField
											className="formInput"
											label="Token Address"
											id="deposit"
											value={sendTokenAddress}
											onChange={(e) =>
												setSendTokenAddress(
													e.target.value
												)
											}
										/>
									</div>
									<div>
										<CssTextField
											className="formInput"
											label="To Address"
											id="deposit"
											value={sendTokenToAddress}
											onChange={(e) =>
												setSendTokenToAddress(
													e.target.value
												)
											}
										/>
									</div>
									<div>
										<CssTextField
											className="formInput"
											label="Token Amount"
											id="deposit"
											value={sendToken}
											onChange={(e) =>
												setSendToken(e.target.value)
											}
											type="number"
										/>
									</div>
									<div>
										<Button
											variant="contained"
											className="btnNew"
											onClick={handleSendToken}
											disabled={figy}
										>
											Transfer
										</Button>
									</div>
								</div>
							</Grid>
						</Grid>
						<br />
						<hr />
						<h3>ReadySwap Bribe contract Wallet</h3>
						<div>
							<p>
								To be able to use the mutli tx bribe function
								please top up the Readyswap Bribe contract. The
								amount should be sufficient for the tip and the
								amount of the tokens you want to buy.
							</p>
							<p>
								Eg.: You want to buy 0.03eth amount of tokens
								and you want to tip 0.03 to the block builder
								you need to have at least 0.06eth or more in the
								contract.
							</p>
							<p>
								Reminder: If you want to buy supply example max
								wallet please make sure you have enough ETH to
								cover the transaction even if the TX will cost
								less.
							</p>
						</div>
						<Grid container spacing={1} className="dialog_form">
							<Grid
								item
								xs={12}
								sm={6}
								className="contentCenter swapmain"
							>
								<div className="walletDW">
									<h3>Deposit</h3>
									<p>
										Deposit ETH to the Readyswap contract.
									</p>
									<div>
										<CssTextField
											className="formInput"
											label="Deposit ETH"
											id="deposit"
											value={deposit}
											onChange={(e) =>
												setDeposit(e.target.value)
											}
											type="number"
										/>
									</div>
									<div>
										<Button
											variant="contained"
											className="btnNew"
											onClick={handleDeposit}
											disabled={figy}
										>
											Deposit
										</Button>
									</div>
								</div>
							</Grid>
							<Grid
								item
								xs={12}
								sm={6}
								className="contentCenter swapmain"
							>
								<div className="walletDW">
									<h3>Withdraw</h3>
									<p>
										Withdraw your ETH from your the
										Readyswap contract
									</p>
									<div>
										<CssTextField
											className="formInput"
											label="Withdraw ETH"
											id="withdraw"
											value={withdraw}
											onChange={(e) =>
												setWithdraw(e.target.value)
											}
											type="number"
										/>
									</div>
									<div>
										<Button
											variant="contained"
											className="btnNew"
											onClick={handleWithdraw}
											disabled={figy}
										>
											Withdraw
										</Button>
									</div>
								</div>
							</Grid>
						</Grid>
					</DialogContent>
					<DialogActions>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								Exit
							</Button>
						</div>
					</DialogActions>
				</Dialog>
			)}
		</div>
	);
};

export default DialogWallet;

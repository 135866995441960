import React from "react";

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

const DialogAlert = ({ open, setOpen, title, msg, handleMethod }) => {
	const handleClose = () => {
		setOpen(false);
	};

	return (
		<div>
			{open && (
				<Dialog open={open} onClose={handleClose} className="dialog_">
					<DialogContent>
						<div className="alertHeader">
							<h4 className="dialogHeader">{title}</h4>
						</div>

						<div className="boxB">{msg}</div>
						<div className="">
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleMethod()}
							>
								Yes
							</Button>
							<Button
								variant="outlined"
								className="btnCancel"
								onClick={() => handleClose()}
							>
								No
							</Button>
						</div>
					</DialogContent>
				</Dialog>
			)}
		</div>
	);
};

export default DialogAlert;
